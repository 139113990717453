import s from "./NewsPages.module.css"
import img from "../../assets/News/News200721.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";
import YouTube, {YouTubeProps} from "react-youtube";

const News200721 = () => {

    let opts;
    opts = {
        width: '100%',
        minHeight: '600px'
    };

    return (
        <div className={s.main}>
            <h1>
                Обучающий вебинар по вопросам рассмотрения споров о воспитании детей
            </h1>
            <div className={s.date}>
                20 июля 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    15 июля 2021 г. АНО «Центр развития социальных проектов» по заказу ФГБУ «Центр защиты прав и
                    интересов детей» в соответствии с государственным заданием Минпросвещения России проведен
                    дистанционный обучающий вебинар для специалистов органов опеки и попечительства по вопросам,
                    связанным с рассмотрением споров о воспитании детей.
                    <p></p>
                    В вебинаре приняли участие более 900 специалистов органов опеки и попечительства из всех субъектов
                    Российской Федерации.
                    <p></p>
                    В рамках вебинара были проведены презентация и экспертное обсуждение Методических рекомендаций по
                    сопровождению семейных споров о месте жительства ребенка и порядке общения с ребенком отдельно
                    проживающего родителя (близкого родственника), разработанных по результатам мониторинга проблем, с
                    которыми сталкиваются специалисты органов опеки и попечительства, судьи, адвокаты, судебные приставы
                    и уполномоченные по правам ребенка.
                    <p></p>
                    Кроме того, представлены алгоритмы деятельности органов опеки и попечительства по разрешению споров,
                    связанных с воспитанием детей, на досудебной стадии и в суде; алгоритмы взаимодействия со службой
                    медиации для внесудебного разрешения конфликта или урегулирования судебных споров, а также
                    компетенции специалиста органа опеки и попечительства, связанные с применением специальных
                    психологических знаний для проведения опроса ребенка, постановки вопросов перед экспертами в случае
                    назначения судебной психолого-психиатрической экспертизы при рассмотрении споров.
                    <p></p>
                    В ходе вебинара был представлен региональный опыт организации работы органов опеки и попечительства
                    Ханты-Мансийского автономного округа – Югры, Краснодарского края, Московской и Тамбовской областей.
                    <p></p>
                    В качестве эксперта в вебинаре выступила Алла Зауровна Дзугаева, кандидат юридических наук, директор
                    Центра по вопросам опеки и попечительства «Содействие» Департамента труда и социальной защиты
                    населения города Москвы, которая отметила востребованность среди специалистов органов опеки и
                    попечительства методических рекомендаций. На федеральном уровне рекомендации по вопросам участия
                    органов опеки и попечительства в рассмотрении споров о воспитании детей не издавались после 1988
                    года, когда для инспекторов школ по охране детства были даны рекомендации по проведению обследования
                    по делам, связанным с воспитанием детей, и перечню вопросов, необходимых для выяснения (приказ
                    Министерства просвещения СССР от 16 февраля 1988 г. № 21).
                    <p></p>
                    Участниками вебинара была активно использована возможность задать вопросы, касающиеся
                    правоприменительной деятельности по разрешению споров между родителями. При этом экспертами было
                    отмечено, что общие подходы для решения заданных вопросов отражены в методических рекомендациях.
                    <p></p>
                    По итогам экспертного обсуждения работа над методическими рекомендациями будет завершена.
                    <p></p>
                    Материалы вебинара доступны на сайте АНО «Центр развития социальных проектов», в разделе
                    мероприятия, по ссылке: https://www.centrrsp.ru/obuchayshiy-webinar.
                    <p></p>
                    <i>Справочно:</i>
                    <p></p>
                    С 2013 года наблюдается тенденция к увеличению количества судебных споров о воспитании. Если в 2013
                    году органами опеки и попечительства были представлены иски или заключения в суд по названным спорам
                    в отношении более 39 тыс. детей, то в 2020 году – уже в отношении практически 58 тыс. детей. Из них
                    о месте жительства детей – 28 987 (22% от общего числа детей, в защиту которых предъявлен иск в суд
                    или предоставлены в суд заключения); об участии в воспитании детей отдельно проживающих родителей –
                    25 734 (19%); об общении с детьми бабушек, дедушек и других родственников – 3 268 (2%).
                    <p></p>
                    Загруженность специалистов органов опеки и попечительства, участвующих в судебном разбирательстве по
                    спорам о воспитании, в среднем составляет 5 часов ежедневно с учетом 2 часов на дорогу до суда и
                    обратно. Как правило, одно дело рассматривается судом в трех и более заседаниях.
                    <p></p>
                    Большинство специалистов органов опеки и попечительства испытывают сложности в подготовке заключения
                    по существу спора о воспитании, так как не владеют знаниями основ возрастной и социальной
                    психологии, а также технологий разрешения конфликтов (восстановительной медиации).
                    <p></p>
                    <YouTube videoId="zkHv5ZxjfXA" opts={opts}></YouTube>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News200721
