import s from "../News/NewsPages.module.css"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs24 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Обучающий вебинар по вопросам рассмотрения споров о воспитании детей
            </h1>
            <div className={s.date}>
                16.07.2021
            </div>
            <div className={s.newsPage}>
                <YouTube videoId="zkHv5ZxjfXA" opts={opts}/>
                <div style={{paddingTop: "15px"}}>
                    15 июля 2021 г. АНО «Центр развития социальных проектов» по заказу ФГБУ «Центр защиты прав и
                    интересов детей» в соответствии с государственным заданием Минпросвещения России проведен
                    дистанционный обучающий вебинар для специалистов органов опеки и попечительства по вопросам,
                    связанным с рассмотрением споров о воспитании детей.
                </div>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs24
