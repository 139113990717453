import s from "./Main.module.css"

const AboutUs = () => {
    return (
        <div className={s.main}>
            <h1>О нас</h1>
            <div className={s.about}>
                <p>Федеральный ресурсный центр в сфере защиты прав детей-сирот и детей, оставшихся без попечения
                    родителей (ФРЦ) создан на базе ФГБУ «Центр защиты прав и интересов детей».</p>
                <p>Основные направления деятельности ФРЦ:</p>
                <ul>
                    <li>Консультирование по вопросам опеки и попечительства в отношении несовершеннолетних граждан;</li>
                    <li>Разработка методических рекомендаций, материалов по вопросам опеки и попечительства в отношении несовершеннолетних граждан;</li>
                    <li>Организация мероприятий по повышению уровня профессиональных компетенций специалистов органов опеки и попечительства, организаций для детей-сирот и детей, оставшихся без попечения родителей, служб по подготовке граждан и сопровождению семей, принявших на воспитание детей, оставшихся без попечения родителей, путем проведения конференций, семинаров, круглых столов, совещаний;</li>
                    <li>Сбор, обработка, обобщение и мониторинг информации по вопросам опеки и попечительства в отношении несовершеннолетних граждан;</li>
                    <li>Изучение, обобщение, распространение передового отечественного и зарубежного опыта организации работы в сфере опеки и попечительства, защиты прав и законных интересов несовершеннолетних;</li>
                    <li>Выявление и изучение проблем, возникающих у специалистов органов опеки и попечительства, организаций для детей-сирот и детей, оставшихся без попечения родителей, служб по подготовке граждан и сопровождению семей, принявших на воспитание детей, оставшихся без попечения родителей, в процессе их деятельности;</li>
                    <li>Организация работы по изданию методических рекомендаций и сборников нормативных правовых документов, буклетов по актуальным вопросам по опеке и попечительству в отношении несовершеннолетних граждан.</li>
                </ul>
            </div>
        </div>
    )
}
export default AboutUs