import s from "./News.module.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

const News = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/news").then((res) => {
      setData(res);
    });
  }, []);

  let newsArray = respData ? [].concat(respData.data).reverse() : [];

  return (
    <div className={s.newsPage}>
      <h1>Новости</h1>
      <div className={s.newsList}>
        {respData ? (
          newsArray.map((el) => (
            <NewsSquare
              img={el.img}
              title={el.Header}
              description={el.description}
              date={el.date}
              refs={el.ref}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default News;

export const NewsSquare = ({ img, title, description, date, refs }) => {
  return (
    <div className={s.newsSquare}>
      <div className={s.newsImg}>
        <a href={refs}>
          <img src={img} alt={title} />
        </a>
      </div>
      <div className={s.newsTitle}>
        <a href={refs}>{title}</a>
      </div>
      <div className={s.date}>{formatDate(date)}</div>
      <div className={s.description}>{description}</div>
      <div className={s.btn}>
        <a href={refs}>
          Читать далее
          <ArrowRightOutlined style={{ paddingLeft: "6px" }} />
        </a>
      </div>
    </div>
  );
};
export function formatDate(dateString) {
  const date = new Date(dateString);
  return format(date, "dd MMMM yyyy", { locale: ru });
}
