import s from "./Files.module.css";
import { FileRef, hrefArray, InfoPanel } from "./FZ";
import { useEffect, useState } from "react";
import axios from "axios";

const Documents1 = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/NPD").then((res) => {
      setData(res);
    });
  }, []);
  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <div className={s.leftSide}>
          <h2>Документы Высших Судов РФ</h2>

          <FileRef
            text={respData ? respData.data[20].description : ""}
            url={respData ? respData.data[20].file : ""}
          />
          <FileRef
            text={respData ? respData.data[21].description : ""}
            url={respData ? respData.data[21].file : ""}
          />
          <FileRef
            text={respData ? respData.data[22].description : ""}
            url={respData ? respData.data[22].file : ""}
          />
          <FileRef
            text={respData ? respData.data[23].description : ""}
            url={respData ? respData.data[23].file : ""}
          />
          <FileRef
            text={respData ? respData.data[24].description : ""}
            url={respData ? respData.data[24].file : ""}
          />
          <FileRef
            text={respData ? respData.data[25].description : ""}
            url={respData ? respData.data[25].file : ""}
          />
          <FileRef
            text={respData ? respData.data[26].description : ""}
            url={respData ? respData.data[26].file : ""}
          />
          <FileRef
            text={respData ? respData.data[27].description : ""}
            url={respData ? respData.data[27].file : ""}
          />
          <FileRef
            text={respData ? respData.data[28].description : ""}
            url={respData ? respData.data[28].file : ""}
          />
          <FileRef
            text={respData ? respData.data[29].description : ""}
            url={respData ? respData.data[29].file : ""}
          />
        </div>
        <div className={s.rightSide}>
          <InfoPanel hrefArray={hrefArray} />
        </div>
      </div>
    </div>
  );
};
export default Documents1;
