import s from "../Pages/Main.module.css"

const Partners = () => {
    return (
        <div className={s.main}>
            <h1>Партнеры</h1>
            <div className={s.partnersList}>
                {partnersList.map(el => <PartnerSquare text={el.text} img={el.img} url={el.url}/>
                )}
            </div>
        </div>
    )
}
export default Partners

const PartnerSquare = ({img, url, text}) => {
    return (
        <div className={s.partnersSquare}>
            <div className={s.partnerImg}>
                <img src={`${img}`} alt={url}/>
            </div>
            <div className={s.name}>
                <a href={url}>{text}</a>
            </div>

        </div>
    )
}

export const partnersList = [
    {
        img: require("../../assets/Partners/partner1.jpg"),
        url: "/partners",
        text: "Содружество выпускников детских домов «Дети всей страны»"
    },
    {
        img: require("../../assets/Partners/partner2.png"),
        url: "https://www.centrrsp.ru/",
        text: "Автономная некоммерческая организация «Центр развития социальных проектов»"
    },
    {
        img: require("../../assets/Partners/partner3.png"),
        url: "https://дети-ждут.рф/",
        text: "Благотворительный фонд «Дети ждут»"
    },
    {
        img: require("../../assets/Partners/partner4.png"),
        url: "https://changeonelife.ru/",
        text: "Благотворительный фонд «Измени одну жизнь»"
    },
    {
        img: require("../../assets/Partners/partner5.png"),
        url: "http://fondkluch.ru/",
        text: "Благотворительный фонд «Ключ»"
    },
    {
        img: require("../../assets/Partners/partner6.png"),
        url: "http://timchenkofoundation.org/",
        text: "Благотворительный фонд Геннадия и Елены Тимченко"
    }
]