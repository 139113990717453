import s from "./Files.module.css";
import { FileRef, hrefArray, InfoPanel } from "./FZ";
import { useEffect, useState } from "react";
import axios from "axios";

const MA = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/NPD").then((res) => {
      setData(res);
    });
  }, []);
  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <div className={s.leftSide}>
          <h2>Международные акты</h2>
          <FileRef
            text={respData ? respData.data[132].description : ""}
            url={respData ? respData.data[132].file : ""}
          />
          <FileRef
            text={respData ? respData.data[53].description : ""}
            url={respData ? respData.data[53].file : ""}
          />
          <FileRef
            text={respData ? respData.data[54].description : ""}
            url={respData ? respData.data[54].file : ""}
          />
          <FileRef
            text={respData ? respData.data[55].description : ""}
            url={respData ? respData.data[55].file : ""}
          />
          <FileRef
            text={respData ? respData.data[56].description : ""}
            url={respData ? respData.data[56].file : ""}
          />
        </div>
        <div className={s.rightSide}>
          <InfoPanel hrefArray={hrefArray} />
        </div>
      </div>
    </div>
  );
};
export default MA;
