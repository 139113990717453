import s from "./NewsPages.module.css"
import img from "../../assets/News/News271223.png"
import {AdviceBlock, ShareBlock} from "./News170621";

const News201123 = () => {

    return (
        <div className={s.main}>
            <h1>
                Обучение по разрешению споров родителей, связанных с воспитанием детей, с участием иностранного элемента
            </h1>
            <div className={s.date}>
                20 ноября 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Если вы заинтересованы в содействии разрешению конфликта между сторонами и их примирению на взаимоприемлемых
                    условиях в интересах ребенка, хотите работать в межведомственной команде с организациями, оказывающими
                    психологическую, юридическую и иную помощь семьям и детям, то этот курс для вас!
                    <p></p>
                    <a href="https://fcprc.ru/education/registrationOpeca">Записаться на курс повышения квалификации</a>
                    <p></p>
                    Мирное досудебное урегулирование спора в наибольшей степени соответствует интересам ребенка, поскольку оно
                    способствует установлению дружественных отношений между сторонами, позволяет исключить или минимизировать
                    возможный ущерб здоровью и развитию ребенка, вызванный конфликтом между его родителями, родственниками или
                    иными лицами.
                    <br/>
                    <p style={{height: "20px"}}></p>
                    Наш Центр приглашает на программу повышения квалификации «<strong>Споры родителей, связанные с воспитанием детей.
                    Особенности рассмотрения споров, связанных с воспитанием детей, с участием иностранного элемента</strong>»,
                    <p></p>
                    <strong>16 академических часов.</strong>
                    <p style={{height: "20px"}}></p>

                    <strong>Период обучения:</strong> 05 декабря - 18 декабря 2023 года
                    <p style={{height: "20px"}}></p>

                    <strong>Формат обучения:</strong>
                    <p></p>
                    Очные занятия в дистанционном формате.
                    <p></p>
                    Все занятия будут проводиться с личным участием преподавателя.
                    <p style={{height: "20px"}}></p>

                    После прохождения обучения, вы получите <strong>удостоверение о повышении квалификации</strong> установленного образца.
                    <p style={{height: "20px"}}></p>

                    Преподаватели:
                    <ul>
                        <li><strong>Галина Владимировна Семья</strong>, доктор психологических наук, профессор;</li>
                        <li><strong>Марина Валерьевна Лашкул</strong>, заместитель директора ФГБУ «Центр защиты прав и интересов детей»;</li>
                        <li><strong>Тарасова Анна Евгеньевна</strong>, кандидат юридических наук, юрист;</li>
                        <li><strong>Тюльканов Сергей Леонидович</strong>, аналитик ФГБУ «Центр защиты прав и интересов детей».</li>
                    </ul>
                    <strong>Эта программа для вас, если вы:</strong>
                    <ul>
                        <li>специалист органов опеки и попечительства;</li>
                        <li>осуществляете содействие обеспечению и защите прав и законных интересов детей их социальных и иных
                            государственных гарантий;
                        </li>
                        <li>рассматриваете споры:
                            <br/>
                            - о месте жительства ребенка и порядке общения с ребенком отдельно проживающего родителя;<br/>
                            - о незаконном похищении/удержании ребенка одним из родителей.
                        </li>
                    </ul>

                    <strong>После прохождения обучения вы сможете:</strong>
                    <ol>
                        <li>Применять правовые основания и направления деятельности органов опеки и попечительства при
                            рассмотрении
                            споров о воспитании детей, в том числе с участием иностранного элемента:
                            <ul>
                                <li>на досудебной стадии;</li>
                                <li>в судебном процессе;</li>
                                <li>в исполнительном производстве.</li>
                            </ul>

                        </li>
                        <li>Учитывать мнение ребенка при решении вопросов, затрагивающих его права и интересы.</li>
                        <li>Применять методы и способы выяснения мнения ребенка в случаях, предусмотренных законодательством.
                        </li>
                        <li>Понимать процесс медиации в трансграничных семейных спорах.</li>
                    </ol>
                    <strong>Стоимость обучения:</strong> 7 000 рублей
                    <p style={{height: "20px"}}></p>

                    Программа разработана в соответствии с
                    <p></p>
                    - примерной дополнительной профессиональной программой повышения квалификации для работников органов опеки и
                    попечительства, утвержденной приказом Минобрнауки России от 24.02.2015 № 121 (зарегистрирован Минюстом
                    России 21.05.2015, регистрационный № 37361);
                    <p></p>
                    - профессиональным стандартом «Специалист органа опеки и попечительства в отношении несовершеннолетних»,
                    утвержденным приказом Министерства труда и социальной защиты Российской Федерации от 18.11.2013 № 680н
                    (зарегистрирован Минюстом России 26.12.2013, регистрационный № 30850).

                    <p style={{height: "20px"}}></p>
                    <a href="https://dpo.fcprc.ru/course/споры-родителей-связанные-с-воспита-6/">Подробнее о курсе</a>

                    <p style={{height: "20px"}}></p>
                    ФГБУ «Центр защиты прав и интересов детей» Министерства просвещения Российской Федерации разрабатывает и
                    проводит курсы повышения квалификации для специалистов в сфере защиты прав и интересов детей по вопросам:
                    <ul>
                        <li>опеки и попечительства</li>
                        <li>медиации и восстановительных технологий</li>
                        <li>помощи обучающимся с ограниченными возможностями здоровья, с инвалидностью</li>
                        <li>профилактики девиантного поведения несовершеннолетних</li>
                    </ul>
                    <p></p>
                    ДПО ФГБУ ЦЗПиИД<br/>
                    <a href="https://fcprc.ru/">https://fcprc.ru/</a><br/>
                    <a href="https://dpo.fcprc.ru/">https://dpo.fcprc.ru/</a>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News201123
