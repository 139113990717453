import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs6.png"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs6 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: «Диалог подростка с родителями: простой разговор на сложные темы»
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    15 апреля 2020 г. прошел вебинар для подростков «Диалог подростка с родителями: простой разговор на
                    сложные темы».
                    <p></p>
                    Ведущая вебинара: аналитик Центра, психолог, кандидат педагогических наук Горнова Татьяна Сергеевна.
                    <p></p>
                    В мероприятии приняли участие 1039 человек из различных субъектов РФ.
                    <p></p>
                    В числе участников были как подростки, так и родители, и специалисты.
                    <p></p>
                    Вебинар прошел в формате интерактивной дискуссии подростков и родителей.
                    <p></p>
                    В ходе вебинара подростки узнали, что происходит с ними в период подросткового возраста. Дискуссия
                    позволила подросткам узнать о том, что же на самом деле хотят сказать им родители. А родители
                    получили мнение подростков об отношениях со взрослыми.
                </div>
                <YouTube videoId="VGUxn4Wn1FE" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs6
