import s from "./NewsPages.module.css"
import img from "../../assets/News/News201023.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News201023 = () => {

    return (
        <div className={s.main}>
            <h1>
                Открыт набор на курсы повышения квалификации
            </h1>
            <div className={s.date}>
                20 октября 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    <p style={{textAlign: "center"}}>
                        Уважаемые коллеги!
                    </p>
                    <p>
                        Федеральное государственное бюджетное учреждение «Центр защиты прав и интересов детей»
                        Министерства
                        просвещения Российской Федерации приступает к проведению курсов повышения квалификации для
                        специалистов в сфере защиты прав и интересов детей по вопросам опеки и попечительства, медиации,
                        помощи обучающимся с ограниченными возможностями здоровья и инвалидностью, профилактики
                        девиантного
                        поведения обучающихся.
                    </p>
                    <p>
                        Разработчики и преподаватели программ курсов повышения квалификации – ведущие специалисты, члены
                        экспертных групп по соответствующим направлениям Центра защиты прав и интересов детей и
                        Минпросвещения России.
                    </p>
                    <p>
                        Получить подробную информацию о проведении курсов и условиях обучения, записаться или направить
                        запрос на обучение можно на портале дополнительного профессионального образования Центра: <a
                        href="https://dpo.fcprc.ru/">https://dpo.fcprc.ru/</a>.
                    </p>
                    <p>
                        Ждем вас на наших обучающих курсах!
                    </p>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News201023
