import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs11.png"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs11 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Мастер-класс на тему: Конструктор эффективной комплексной помощи выпускнику организации для детей-сирот.
                Успешный жизненный сценарий
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    Мастер-класс для руководителей и специалистов органов опеки и попечительства и организаций для
                    детей-сирот, родителей, представителей общественных организаций, выпускников организаций для
                    детей-сирот и замещающих семей, замещающих родителей «Конструктор эффективной комплексной помощи
                    выпускнику организации для детей-сирот. Успешный жизненный сценарий» провели сотрудники Центра.
                    <p></p>
                    Мастер-класс был посвящен обсуждению условий для устойчивой самостоятельной жизни выпускников
                    организаций для детей-сирот и замещающих семей. Ключевые вопросы дискуссии: Почему выпускникам
                    сложно? Кто включен в систему помощи и поддержки выпускников? При каких условиях возможен успешный
                    жизненный сценарий выпускника организации для детей-сирот?
                    <p></p>
                    В мероприятии приняли участие 724 слушателя из 59 субъектов Российской Федерации.
                </div>
                <YouTube videoId="rE6OYOVxGRc" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs11