import s from "./NewsPages.module.css"
import img from "../../assets/News/News110723.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News110723 = () => {

    return (
        <div className={s.main}>
            <h1>
                6-7 июля 2023 г. в г. Уфе прошел XI Всероссийский съезд руководителей организаций для детей-сирот и
                детей, оставшихся без попечения родителей.
            </h1>
            <div className={s.date}>
                11 июля 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    В Год педагога и наставника ключевой темой съезда стала роль наставника, воспитателя, организации
                    для детей-сирот и детей, оставшихся без попечения родителей. принимающих непосредственное участие в
                    формировании личности ребенка, в его воспитании и образовании. На площадках съезда обсуждались
                    структура стандарта подготовки воспитанников организаций для детей сирот к самостоятельной жизни и
                    стандарта сопровождения выпускников, проект Концепции единой модели организаций для детей сирот.
                    Экспертные сессии были посвящены сложившейся практики в организациях для детей-сирот по подготовке к
                    самостоятельной жизни и организации разных видов наставничества.
                    <p></p>
                    Участниками съезда стали более 260 руководителей и специалистов организаций для детей-сирот и детей,
                    оставшихся без попечения родителей, из 76 субъектов Российской Федерации, представителей
                    Министерства просвещения Российской Федерации, ФГБУ «Центр защиты прав и интересов детей»,
                    Всероссийской общественной организации «Содружество выпускников детских домов «Дети всей страны»,
                    Фонда поддержки детей, находящихся в трудной жизненной ситуации, Всероссийского общественного
                    движения наставников детей и молодежи «Наставники России», Российского движения детей и молодежи
                    «Движение первых».
                    <p></p>
                    С видеоприветствием к участникам Съезда обратились Заместитель Председателя Правительства Российской
                    Федерации Татьяна Алексеевна Голикова, Министр просвещения Российской Федерации Сергей Сергеевич
                    Кравцов, Уполномоченный при Президенте Российской Федерации по правам ребенка Мария Алексеевна
                    Львова-Белова, вице-спикер Государственной Думы, Председатель Комиссии Генерального совета
                    Всероссийской политической партии «Единая Россия» по защите материнства, детства и поддержке семьи
                    Анна Юрьевна Кузнецова, председатель Комитета Государственной Думы по вопросам семьи, женщин и детей
                    Нина Александровна Останина, председателя правления Фонда поддержки детей, находящихся в трудной
                    жизненной ситуации, Марина Владимировна Гордеева, председатель Общероссийской
                    общественно-государственной организации «Фонд защиты детей» Дмитрий Альбертович Лиханов.
                    <p></p>
                    По видеоконференцсвязи выступили председатель Комитета Государственной Думы по молодежной политике
                    Артем Павлович Метелев, директор Департамента социальной защиты и социального обслуживания
                    Министерства труда и социальной защиты Российской Федерации Тарас Николаевич Васько, руководитель
                    секции «Семья, материнство и детство» Совета при Правительстве Российской Федерации по вопросам
                    попечительства в социальной сфере Наталья Николаевна Карпович и главный специалист управления
                    социальной, воспитательной и идеологической работы Главного управления воспитательной работы и
                    молодежной политики Министерства образования Республики Беларусь Елена Михайловна Войтехович.
                    <p></p>
                    В открытии Съезда с докладами выступили председатель Комитета Совета Федерации по науке, образованию
                    и культуре Лилия Салаватовна Гумерова, директор Департамента государственной политики в сфере защиты
                    прав детей Лариса Павловна Фальковская, заместитель Премьер-министра Правительства Республики
                    Башкортостан — министр финансов Республики Башкортостан Лира Закуановна Игтисамова и прокурор
                    Республики Башкортостан Игорь Станиславович Пантюшин.
                    <p></p>
                    В рамках Съезда проведена церемония награждения профессионалов в сфере воспитания, опеки и
                    попечительства в отношении несовершеннолетних граждан. Для награждаемых воспитанницы Ишимбайского
                    специального учебно-воспитательного учреждения, находящегося в Республики Башкортостан, приготовили
                    памятные сувениры — статуэтки «На стороне ребенка». На стороне ребенка – принцип деятельности
                    специалистов сферы защиты детей.
                    <p></p>
                    <a href="https://disk.yandex.ru/d/q72ku2Jg7tKkEA">Материалы XI Всероссийского Съезда руководителей
                        организаций для детей-сирот и детей, оставшихся без
                        попечения родителей</a>.


                </div>
                <iframe src="https://vk.com/video_ext.php?oid=-182917553&id=456239033&hd=3" width="1280"
                        height="720" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                        frameBorder="0" style={{maxWidth: "100%"}} allowFullScreen></iframe>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News110723
