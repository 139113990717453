import s from "./Main.module.css"

const TechSupport = () => {

    return (
        <div className={s.main}>
            <h1>Техническая поддержка</h1>
            <div className={s.support}>
                <form>
                    <label>
                        ФИО <span style={{color: "red"}}>*</span>
                        <input type="text" name="name" placeholder="Введите ФИО"/>
                    </label>
                    <label>
                        Телефон <span style={{color: "red"}}>*</span>
                        <input type="text" name="phone" placeholder="Введите телефон"/>
                    </label>
                    <label>
                        Введите название вашей организации (без сокращений) <span style={{color: "red"}}>*</span>
                        <input type="text" name="name" placeholder="Введите название"/>
                    </label>
                    <label>
                        Email адрес <span style={{color: "red"}}>*</span>
                        <input type="text" name="name" placeholder="Введите почту"/>
                    </label>
                    <label>
                        Браузер, который Вы используете
                        <select>
                            <option value="" disabled selected>Выберите</option>
                            <option value="internet_explorer">Internet Explorer</option>
                            <option value="chrome">Google Chrome</option>
                            <option value="mozilla">Mozilla Firefox</option>
                            <option value="opera">opera</option>
                            <option value="edge">Microsoft Edge</option>
                            <option value="yandex">Yandex browser</option>
                            <option value="safari">Safari</option>
                            <option value="noinfo">Не знаю</option>
                        </select>
                    </label>
                    <label>
                        Описание проблемы <span style={{color: "red"}}>*</span>
                        <textarea placeholder="Введите текст"
                                  style={{width: "100%", minHeight: "50px", borderRadius: "10px"}}
                                  name="problem"></textarea>
                    </label>
                    <label htmlFor="images" className="drop-container" id="dropcontainer">
                        <div>При необходимости, можете прикрепить скриншот</div>
                        <input type="file" accept="image/*"/>
                    </label>
                    <p></p>
                    <label>
                        <input style={{}} type="submit" value="Отправить"/>
                    </label>
                </form>
                <p style={{fontSize: "16px", color: "#484848"}}>
                    Ваша заявка будет рассмотрена в течении одного рабочего дня. Ответ будет направлен на указанный вами
                    почтовый адрес, для уточнения вопроса, специалист может связаться с вами по телефону. Если проблема
                    требует неотложного решения, либо, затрудняетесь сформулировать ее суть , Вы можете связаться с
                    техническим специалистом Центра: 8-903-823-73-80 Гуров Илья Юрьевич (10:00 до 17:00 Мск)
                </p>
            </div>
        </div>
    )
}
export default TechSupport