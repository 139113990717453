export const placeMarks = [
    {
        description: "ул. Ползунова, д. 36, г. Барнаул, Алтайский край, 656043",
        coordinates: [53.331521, 83.792083],
    },
    {
        description: "ул. Шимановского, д. 8, г. Благовещенск, Амурская область, 675000",
        coordinates: [50.258044, 127.539858]
    },
    {
        description: "пр. Новгородский, дом 160, г. Архангельск, Архангельская область, 163000",
        coordinates: [64.545528, 40.530117]
    },
    {
        description: "ул. Бакинская, д. 147, г. Астрахань, Астраханская область, 414000",
        coordinates: [46.341735, 48.044237]
    },
    {
        description: "пр-т Славы,д. 24, г. Белгород, 308026",
        coordinates: [50.596175, 36.600069]
    },
    {
        description: "проспект Станке Димитрова, д. 88, г. Брянск, Брянская область, 241033",
        coordinates: [53.228029, 34.319596]
    },
    {
        description: "ул. Комсомольская, д. 1, г. Владимир, 600000",
        coordinates: [56.130638, 40.405961]
    },
    {
        description: "ул. Новороссийская, д. 41, г. Волгоград, Волгоградская область, 400087",
        coordinates: [48.715194, 44.508010]
    },
    {
        description: "ул. Благовещенская, д. 9, г. Вологда, Вологодская область, 160001",
        coordinates: [59.220349, 39.881551]
    },
    {
        description: "Ворошилова,14,  г. Воронеж, Воронежская область, 394006",
        coordinates: [51.656082, 39.178135]
    },
    {
        description: "Донецкая Народная Республика, 83004, г. Донецк, ул. Маршака, 2",
        coordinates: [48.039758, 37.783563]
    },
    {
        description: "Проспект 60-летия СССР, д. 12 А г. Биробиджан, ЕАО, 679016",
        coordinates: [48.786934, 132.931375]
    },
    {
        description: "ул. Курнатовского, д.7, г. Чита, Забайкальский край, 672000",
        coordinates: [52.035070, 113.492362]
    },
    {
        description: "Запорожская область , г. Мелитополь, пр-т Б.Хмельницкого 68А",
        coordinates: [46.828452, 35.356594]
    },
    {
        description: "пер. Свободный, д. 4, г. Иваново, Ивановская область, 153012",
        coordinates: [56.994970, 41.009036]
    },
    {
        description: "664025 Россия, Иркутская обл., г. Иркутск, ул. Канадзавы, д.2",
        coordinates: [52.285197, 104.279304]
    },
    {
        description: "ул. Кешокова, д. 43, г. Нальчик, Кабардино-Балкарская Республика, 360000",
        coordinates: [43.478678, 43.605320]
    },
    {
        description: "ул. Клиническая, 63, г. Калининград, Калининградская область, 236016",
        coordinates: [54.715779, 20.521016]
    },
    {
        description: "ул. Пролетарская, д. 111, г. Калуга, Калужская область, 248016",
        coordinates: [54.519573, 36.262751]
    },
    {
        description: "ул. Советская, д. 35, г. Петропавловск-Камчатский, Камчатский край, 683000",
        coordinates: [53.018886, 158.648794]
    },
    {
        description: "369000, Карачаево-Черкесская Республика, г. Черкесск, ул. Комсомольская, 23",
        coordinates: [44.229065, 42.047668]
    },
    {
        description: "пр-т Советский, д. 58, г. Кемерово, Кемеровская область, 650064",
        coordinates: [55.355198, 86.089632]
    },
    {
        description: "ул.Карла Либкнехта, д.69, г.Киров, Кировская облласть, 610019",
        coordinates: [58.603487, 49.664591]
    },
    {
        description: "ул. Свердлова,д. 129, г. Кострома, Костромская область, 156029",
        coordinates: [57.767616, 40.965019]
    },
    {
        description: "ул. Чапаева, д. 58, г. Краснодар, Краснодарский край, 350000",
        coordinates: [45.030604, 38.966743]
    },
    {
        description: "ул. Карла Маркса, д 122, г. Красноярск, Красноярский край,660021",
        coordinates: [56.009497, 92.847281]
    },
    {
        description: "ул. Р. Зорге, д. 39, г. Курган, Курганская область, 640001",
        coordinates: [55.438941, 65.316145]
    },
    {
        description: "ул. Моковская, д. 2«Г» , г.Курск, 305007 ",
        coordinates: [51.707920, 36.161879]
    },
    {
        description: "191124, Санкт-Петербург, ул. Лафонская, д. 6 Литер А",
        coordinates: [59.947937, 30.389260]
    },
    {
        description: "ул. Циолковского, д. 18, г. Липецк, 398600",
        coordinates: [52.608618, 39.558697]
    },
    {
        description: "Луганская Народная Республика, г. Луганск, ул. Володарского, 59",
        coordinates: [48.574218, 39.296865]
    },
    {
        description: "ул. Транспортная, д. 5/23, г.Магадан, 685000",
        coordinates: [59.570516, 150.796701]
    },
    {
        description: "ул. Новая Басманная, д. 10, стр. 1, г. Москва, 107078",
        coordinates: [55.769119, 37.655877]
    },
    {
        description: "143407, Московская область, г. Красногорск, бульвар Строителей, д. 7 ",
        coordinates: [55.817062, 37.383687]
    },
    {
        description: "ул. Трудовых Резервов, д.4, г.Мурманск, 183025",
        coordinates: [68.966152, 33.096747]
    },
    {
        description: "ул. Смидовича, д. 25, г. Нарьян-Мар, Ненецкий автономный округ, 166000",
        coordinates: [67.639175, 53.007546]
    },
    {
        description: "603082, г. Нижний Новгород, Кремль, корп. 14",
        coordinates: [56.326797, 43.999689]
    },
    {
        description: "ул. Новолучанская, д. 27, г.Великий Новгород, 173001",
        coordinates: [58.528707, 31.264875]
    },
    {
        description: "ул. Серебренниковская, д. 6, г.Новосибирск, 630107",
        coordinates: [55.019005, 82.927904]
    },
    {
        description: "ул. Красный путь, д. 5, г.Омск, 644002",
        coordinates: [54.990803, 73.363244]
    },
    {
        description: "ул. Володарского, дом 11(I корпус); пр. Парковый, 7 (II корпус), г. Оренбург, Оренбургская область, 460000",
        coordinates: [51.766282, 55.096407]
    },
    {
        description: "ул. Лескова, д. 22, г. Орёл, Орловская область, 302040",
        coordinates: [52.980809, 36.060073]
    },
    {
        description: "440008, г. Пенза, ул. Некрасова, д. 24",
        coordinates: [53.203700, 44.983030]
    },
    {
        description: "Пермский край, г. Пермь, ул. Ленина, д. 51, 614015",
        coordinates: [58.010727, 56.232399]
    },
    {
        description: "ул. Пушкинская, 13, г. Владивосток, 690091 ",
        coordinates: [43.114937, 131.897100]
    },
    {
        description: "ул. Некрасова, д. 23, г. Псков, 180001",
        coordinates: [57.815813, 28.342135]
    },
    {
        description: "ул. Советская, д. 176, г. Майкоп, Республика Адыгея, 385000",
        coordinates: [44.606112, 40.107684]
    },
    {
        description: "649000, Республика Алтай, г.Горно-Алтайск, ул.Северная, д. 10",
        coordinates: [51.960313, 85.951121]
    },
    {
        description: "ул. Пушкина, 95 г. Уфа, Республика Башкортостан, 450008",
        coordinates: [54.721791, 55.949394]
    },
    {
        description: "ул. Гагарина, д. 10, г. Улан-Удэ, Республика Бурятия, 670034",
        coordinates: [51.835850, 107.599082]
    },
    {
        description: "ул. А.Абубакарова, дом 117, г.Махачкала, Республика Дагестан, 367015",
        coordinates: [42.967506, 47.488782]
    },
    {
        description: "ул. Московская, д. 37, г. Назрань, Республика Ингушетия, 386102",
        coordinates: [43.218580, 44.763500]
    },
    {
        description: "ул. А.С. Пушкина, д. 18, г. Элиста, Республика Калмыкия, 358000",
        coordinates: [46.307338, 44.269238]
    },
    {
        description: "пр. Ленина, д. 6, г. Петрозаводск, Республика Карелия, 185910",
        coordinates: [61.792898, 34.370908]
    },
    {
        description: "ул. К. Маркса, д. 210, г. Сыктывкар, Республика Коми, 167982",
        coordinates: [61.667760, 50.822969]
    },
    {
        description: "пер. Совнаркомовский, 3, г. Симферополь, Республика Крым, 295000",
        coordinates: [44.952581, 34.101198]
    },
    {
        description: "наб.Брюгге, д.3, г. Йошкар-Ола, Республика Марий Эл, 424033",
        coordinates: [56.634209, 47.910181]
    },
    {
        description: "ул. Титова, д. 133, г. Саранск, Республика Мордовия, 430027",
        coordinates: [54.172773, 45.159262]
    },
    {
        description: "677000 Республика Саха (Якутия), г. Якутск, ул. Петра Алексеева, д.6, к.1",
        coordinates: [62.037309, 129.738754]
    },
    {
        description: "ул. Бутырина, д. 29, г. Владикавказ, Республика Северная Осетия - Алания, 362025",
        coordinates: [43.027642, 44.689137]
    },
    {
        description: "ул. Кремлевская, д. 9, г. Казань, Республика Татарстан, 420111",
        coordinates: [55.795337, 49.112082]
    },
    {
        description: "667000, Республика Тыва г. Кызыл, ул. Ленина, д. 39",
        coordinates: [51.720230, 94.435915]
    },
    {
        description: "ул. Крылова, д. 72, г. Абакан, Республика Хакасия, 655017",
        coordinates: [53.736712, 91.444714]
    },
    {
        description: "переулок Доломановский, д. 31, г. Ростов-на-Дону, Ростовская область, 344082",
        coordinates: [47.221748, 39.696714]
    },
    {
        description: "ул. Каширина, д.1, г. Рязань, 390000",
        coordinates: [54.630295, 39.723699]
    },
    {
        description: "ул. Революционная, д. 44, г. Самара, 443086",
        coordinates: [53.213380, 50.169157]
    },
    {
        description: "191144, Санкт‑Петербург, Новгородская ул., 20, лит. А",
        coordinates: [59.938725, 30.386359]
    },
    {
        description: "ул. Соляная, д. 32, г.Саратов, 410002",
        coordinates: [51.530346, 46.048405]
    },
    {
        description: "693019, Сахалинская область, г. Южно-Сахалинск, ул. Ленина, д. 156",
        coordinates: [46.966258, 142.727620]
    },
    {
        description: "ул. Большакова, д. 105, г. Екатеринбург, Свердловская область, 620144",
        coordinates: [56.816602, 60.600978]
    },
    {
        description: "ул. Щербакова, д.10, г.Севастополь, 299011",
        coordinates: [44.616495, 33.525135]
    },
    {
        description: "ул. Николаева, д. 12 а, г. Смоленск, 214004",
        coordinates: [54.773974, 32.040867]
    },
    {
        description: "ул. Ломоносова, д. 3, г. Ставрополь, Ставропольский край, 355003",
        coordinates: [45.042387, 41.956175]
    },
    {
        description: "ул. Советская, д. 108/8, г. Тамбов, Тамбовская область, 392000",
        coordinates: [52.721197, 41.456280]
    },
    {
        description: "170100, г. Тверь, набережная р.Лазури, д.20",
        coordinates: [56.843585, 35.939448]
    },
    {
        description: "ул. Тверская, д. 74, г. Томск, Томская область, 634041",
        coordinates: [56.472018, 84.973754]
    },
    {
        description: "ул. Пушкинская, д. 29, г. Тула, 300041",
        coordinates: [54.188723, 37.607206]
    },
    {
        description: "ул. Республики, д. 83 а, г. Тюмень, 625048",
        coordinates: [57.146125, 65.554773]
    },
    {
        description: "улица Ломоносова, дом 5, г. Ижевск, Удмуртская Республика, 426004",
        coordinates: [56.847676, 53.223177]
    },
    {
        description: "Ульяновская область, г.Ульяновск, ул.Федерации, д.60, 432071",
        coordinates: [54.328649, 48.397032]
    },
    {
        description: "680000, г. Хабаровск, ул. Фрунзе, д. 67 ",
        coordinates: [48.476717, 135.061335]
    },
    {
        description: "ул. Мира, д. 5, г. Ханты-Мансийск, 628006",
        coordinates: [61.002473, 69.020851]
    },
    {
        description: "Юридический адрес: 273000, г. Херсон, ул. Потёмкинская, 13а. Фактический адрес: 275500, Херсонская обл., г. Геническ, ул. Мира, 35",
        coordinates: [46.166798, 34.811199]
    },
    {
        description: "ул. Воровского, д. 30, г. Челябинск, Челябинская область, 454048",
        coordinates: [55.150674, 61.386132]
    },
    {
        description: "ул. им. академика М.Д. Миллионщикова, 67 «а», г. Грозный, Чеченская Республика, 364021",
        coordinates: [43.304112, 45.703973]
    },
    {
        description: "428004, г. Чебоксары, Президентский бульвар, д. 17",
        coordinates: [56.140294, 47.245320]
    },
    {
        description: "ул. Беринга, д. 2, г. Анадырь, Чукотский автономный округ, 689000",
        coordinates: [64.737100, 177.503705]
    },
    {
        description: "629007,  Ямало-Ненецкий автономный округ, г. Салехард, ул.Совхозная, д.14, корп.1",
        coordinates: [66.533207, 66.602775]
    },
    {
        description: "ул. Советская, д. 7, г. Ярославль, Ярославская область, 150000",
        coordinates: [57.628520, 39.892062]
    }
]