import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs25.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs25 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Вебинар для специалистов органов опеки и попечительства по вопросам подбора, учета и
                подготовки граждан, выразивших желание стать усыновителями, опекунами (попечителями)
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>

                <YouTube videoId="lGSZy5Pa72k" opts={opts}/>
                <div style={{paddingTop: "15px"}}>
                    <a href="/">▶ Ответы на вопросы участников вебинара 23.12.21</a>
                </div>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs25
