import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs35.png"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs35 = () => {

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: В июле-августе 2024 г. проведены вебинары для специалистов организаций для детей-сирот
                и детей, оставшихся без попечения родителей</h1>
            <div className={s.date}>
                19.08.2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <p>
                    ФГБУ «Центр защиты прав и интересов детей» в период с 30 июля по 19 августа 2024 года проведены
                    вебинары по актуальным вопросам для специалистов организаций для детей-сирот и детей, оставшихся без
                    попечения родителей.
                </p>
                <p>
                    К участию в вебинарах также приглашались представители исполнительных органов субъектов Российской
                    Федерации, осуществляющих государственное управление в сфере опеки и попечительства в отношении
                    несовершеннолетних граждан, и специалисты органов опеки и попечительства.
                </p>
                <p>
                    В ходе вебинаров рассмотрены следующие темы:
                </p>
                <p>
                    <a href="https://disk.yandex.ru/d/1Loy_mvkjNvgtA/Составление%20индивидуального%20плана%20развития%20и%20жизнеустройства%20ребенка.mp4">
                        «Составление индивидуального плана развития и жизнеустройства ребенка» (30 июля 2024 г.);
                    </a>
                </p>
                <p>
                    <a href="https://disk.yandex.ru/d/1Loy_mvkjNvgtA/Инструменты%20медиатора%20для%20конструктивного%20разрешения%20конфликтов.mp4">
                        «Инструменты медиатора для конструктивного разрешения конфликтов» (2 августа 2024 г.);
                    </a>
                </p>
                <p>
                    <a href="https://disk.yandex.ru/d/1Loy_mvkjNvgtA/Профилактика%20употребления%20ПАВ%20среди%20обучающихся%20технологии%20практики%20ресурсы.mp4">
                        «Профилактика употребления психоактивных веществ среди обучающихся: технологии, практики,
                        ресурсы»
                        (5
                        августа 2024 г.);
                    </a>
                </p>
                <p>
                    <a href="https://disk.yandex.ru/d/1Loy_mvkjNvgtA/Запись%20вебинара%20Организация%20профессионального%20обучения%20детей-сирот%20и%20детей%20оставшихся%20без%20попечения%20родителей.mp4">
                        «Организация профессионального обучения детей-сирот и детей, оставшихся без попечения родителей»
                        (9
                        августа 2024 г.);
                    </a>
                </p>
                <p>
                    <a href="https://disk.yandex.ru/d/1Loy_mvkjNvgtA/Эмоциональное%20выгорание%20профессиональная%20деформация%20их%20профилактика%20и%20оказание%20помощи.mp4">
                        «Эмоциональное выгорание, профессиональная деформация, их профилактика и оказание помощи» (19
                        августа
                        2024 г.).
                    </a>
                </p>
                <p>
                    Участниками вебинаров проявлен интерес к заявленным темам, были получены отклики со словами
                    благодарности за содержательные выступления, их практическую значимость и знакомство с новыми
                    формами
                    работы с детьми.
                </p>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs35
