import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs33.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs34 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар по актуальным вопросам заполнения отчета опекуна или попечителя несовершеннолетнего подопечного
            </h1>
            <div className={s.date}>
                29.01.2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <iframe src="https://vk.com/video_ext.php?oid=-182917553&id=456239036&hd=1" width="100%" height="450"
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                        allowFullScreen></iframe>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs34
