import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from "../types";
import { useNavigate } from "react-router-dom";

export const login = (requestOptions) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      const response = await fetch(
        "https://frcopeca.ru/api/auth/jwt/create/",
        requestOptions
      );
      const data = await response.json();
      console.log(data);
      if (data.access) {
        localStorage.setItem("access", data.access);
        await dispatch({ type: LOGIN_SUCCESS });
      } else {
        throw new Error("Неправильные данные");
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE, payload: error.message });
    }
  };
};

export const logout = () => {
  localStorage.removeItem("access");
  return { type: LOGOUT };
};
