import s from "./Login.module.css"
import m from "../Pages/Main.module.css"
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../Redux/auth/authActions";
import {Button} from "antd";


const Login = ({setLogged}) => {
    const {error} = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    let formdata = new FormData();
    const infoSchema = Yup.object().shape({
        username: Yup.string()
            .required('Обязательное поле!'),
        password: Yup.string()
            .required('Обязательное поле!')
    });
    return (
        <div className={m.main}>
            <h1>Авторизация</h1>
            <Formik
                initialValues={{username: "", password: ""}}
                onSubmit={async (values) => {
                    formdata.append("username", values.username);
                    formdata.append("password", values.password);
                    let myHeaders = new Headers();
                    let requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow',
                        withCredentials: true
                    }

                    dispatch(login(requestOptions))


                }}
                validationSchema={infoSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className={s.loginForm}>
                            <h1>Вход</h1>
                            <div className={s.loginInfo}>
                                <label>
                                    Логин пользователя
                                </label>
                                <Field type="username"
                                       name="username"/>
                                {errors.username && touched.username ? (
                                    <div className={s.wrong}>{errors.username}</div>
                                ) : null}
                                <label>
                                    Пароль
                                </label>
                                <Field type="password"
                                       name="password"/>
                                <button type="submit">
                                    Отправить
                                </button>
                                {errors.password && touched.password ? (
                                    <div className={s.wrong}>{errors.password}</div>
                                ) : null}
                                <div style={{color: "red", textAlign: "center"}}>{error}</div>

                            </div>
                            <div className={s.center}>
                                <Button size="large" onClick={() => navigate("/support_form")}
                                        type="link">Проблемы со входом?</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}
export default Login
