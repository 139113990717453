import s from "./News.module.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate } from "./News";

const Webinars = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/webinars").then((res) => {
      setData(res);
      console.log(respData.data);
    });
  }, []);

  let websArray = respData ? [].concat(respData.data).reverse() : [];

  return (
    <div className={s.newsPage}>
      <h1>Вебинары, мастер-классы</h1>
      <div className={s.newsList}>
        {websArray.map((el) => (
          <WebinarSquare
            img={el.img}
            title={el.header}
            description={el.description}
            date={el.date}
            refs={el.ref}
            wtype={el.type}
          />
        ))}
      </div>
    </div>
  );
};
export default Webinars;

const WebinarSquare = ({ img, title, date, refs, wtype }) => {
  return (
    <div className={s.newsSquare}>
      <div className={s.newsImg}>
        <a href={refs}>
          <img src={img} alt={title} />
        </a>
      </div>
      <div className={s.newsTitle}>
        <a href={refs}>{title}</a>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span className={s.date}>{formatDate(date)}</span>
        <span className={s.type}>{wtype}</span>
      </div>
      <p></p>
      <div className={s.btn}>
        <a href={refs}>
          Читать далее
          <ArrowRightOutlined style={{ paddingLeft: "6px" }} />
        </a>
      </div>
    </div>
  );
};
