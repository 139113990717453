import s from "./Main.module.css"
import ImageSlider from "../Carousel/ImageSlider";
import c1 from "../../assets/Pages/C1.png"
import c2 from "../../assets/Pages/C2.png"
import c3 from "../../assets/Pages/C3.png"
import c4 from "../../assets/Pages/C4.png"
import c5 from "../../assets/Pages/C5.png"
import c6 from "../../assets/Pages/C6.png"
import c7 from "../../assets/Pages/C7.png"
import c8 from "../../assets/Pages/C8.png"
import c9 from "../../assets/Pages/C9.png"

import c11 from "../../assets/Pages/C11.png"
import c12 from "../../assets/Pages/C12.png"
import c13 from "../../assets/Pages/C13.png"
import c14 from "../../assets/Pages/C14.png"
import c15 from "../../assets/Pages/C15.png"
import c16 from "../../assets/Pages/C16.png"
import c17 from "../../assets/Pages/C17.png"
import c19 from "../../assets/Pages/C19.png"
import c20 from "../../assets/Pages/C20.png"
import c21 from "../../assets/Pages/C21.png"
import c22 from "../../assets/Pages/C22.png"
import c23 from "../../assets/Pages/C23.png"
import c24 from "../../assets/Pages/C24.png"
import c25 from "../../assets/Pages/C25.png"
import c26 from "../../assets/Pages/C26.png"
import c27 from "../../assets/Pages/C27.png"
import c28 from "../../assets/Pages/C28.png"
import c29 from "../../assets/Pages/C29.png"
import c30 from "../../assets/Pages/C30.png"
import c31 from "../../assets/Pages/C31.png"
import c32 from "../../assets/Pages/C32.png"
import c33 from "../../assets/Pages/C33.png"
import c34 from "../../assets/Pages/C34.png"
import c35 from "../../assets/Pages/C35.png"
import c36 from "../../assets/Pages/C36.png"
import c37 from "../../assets/Pages/C37.png"
import c38 from "../../assets/Pages/C38.png"
import c39 from "../../assets/Pages/C39.png"
import c40 from "../../assets/Pages/C40.png"
import c41 from "../../assets/Pages/C41.png"
import c42 from "../../assets/Pages/C42.png"
import c43 from "../../assets/Pages/C43.png"
import c44 from "../../assets/Pages/C44.png"
import c45 from "../../assets/Pages/C45.png"
import c46 from "../../assets/Pages/C46.png"
import c47 from "../../assets/Pages/C47.png"
import c48 from "../../assets/Pages/C48.png"
import c49 from "../../assets/Pages/C49.png"
import c50 from "../../assets/Pages/C50.png"
import c51 from "../../assets/Pages/C51.png"
import c52 from "../../assets/Pages/C52.png"

const Comics = () => {
    return (
        <div className={s.main}>
            <h1>Комикс по финансовой грамотности (возраст 12+)</h1>
            <div style={containerStyles}>
                <ImageSlider slides={slides}/>
            </div>

        </div>
    )
}

const containerStyles = {
    width: "90%",
    maxWidth: "1000px",
    margin: "0 auto",
};
export default Comics


const slides = [
    c1, c2, c3, c4, c5, c6, c7, c8, c9, c11, c12, c13, c14, c15, c16, c17, c19, c20, c21, c22, c23, c24, c25, c26, c27, c28, c29, c30, c31, c32, c33, c34, c35, c36, c37, c38, c39, c40, c41, c42, c43, c44, c45, c46, c47, c48, c49, c50, c51, c52
]