import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs5.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs5 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Как организовать совместную игровую деятельность родителей с детьми от 1 года до 13 лет
                в пределах своей квартиры
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    «Игра детей – зеркало общества» — точно подметил чешский писатель Ю. Фучик. А психологи могут
                    добавить, что игра ребенка является зеркалом семьи.
                    <p></p>
                    14 апреля 2020 г. прошел вебинар для родителей детей дошкольного, младшего школьного и младшего
                    подросткового возраста на тему: «Как организовать совместную игровую деятельность родителей с детьми
                    от 1 года до 13 лет в пределах своей квартиры».
                    <p></p>
                    В мероприятии приняли участие 1002 человека из 61 региона Российской Федерации, а также Украины и
                    США.
                    <p></p>
                    Ведущая вебинара М.А. Назарова рассказала об организации безопасного игрового пространства для
                    совместной игры родителей с детьми в условиях карантина, о психологических особенностях дошкольного,
                    младшего школьного и младшего подросткового возраста. Основываясь на возрастной периодизации, были
                    рассмотрены подходы к игре как приему развития у ребенка социально полезных знаний и навыков. Даны
                    рекомендации родителям, как относиться к игре серьёзно, с искренним интересом, понимать замыслы
                    своих детей, их переживания, уметь сделать игру увлекательнее.
                </div>
                <YouTube videoId="54uJrQbuE38" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs5
