import s from "../Pages/Main.module.css"
import {ArrowLeftOutlined} from "@ant-design/icons";

const MetodMaterialsPage = ({title, urls}) => {
    return (
        <div className={s.main}>
            <h1>{title}</h1>
            <div className={s.metodMat}>
                {
                    urls.map(file =>
                        <div>
                            <a href={file.url}>{file.fileTitle}</a><br/>
                        </div>
                    )
                }


                <div className={s.btn}>
                    <a href="/methodical-materials">
                        <ArrowLeftOutlined style={{paddingRight: "5px"}}/>Назад
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MetodMaterialsPage