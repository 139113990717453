import s from "./NewsPages.module.css"
import img from "../../assets/News/News200721.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News221121 = () => {

    return (
        <div className={s.main}>
            <h1>
                Минпросвещения России проведет Всероссийское совещание руководителей органов опеки и попечительства и
                Форум приемных семей
            </h1>
            <div className={s.date}>
                22 ноября 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Министерством просвещения Российской Федерации 2-3 декабря 2021 г. в режиме онлайн-трансляции будет
                    проведено Всероссийское совещание руководителей органов опеки и попечительства, 3-4 декабря 2021 г.
                    – Форум приемных семей. 3 декабря 2021 г. пройдут совместные мероприятия Всероссийского совещания и
                    Форума.
                    <br/>
                    В рамках обоих онлайн-мероприятий запланированы секции, круглые столы и мастер-классы, в том числе
                    по вопросам организации деятельности органов опеки и попечительства в отношении несовершеннолетних
                    граждан, совершенствования законодательства в указанной сфере, расчета норматива численности
                    специалистов органов опеки и попечительства, поддержки замещающих семей, взаимодействия органов
                    опеки и попечительства с ассоциациями приемных семей в субъектах Российской Федерации.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News221121
