import s from "./NewsPages.module.css"
import img from "../../assets/News/News080922.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News080922 = () => {

    return (
        <div className={s.main}>
            <h1>
                На стороне ребенка»: первый всероссийский конкурс специалистов органов опеки и попечительства
            </h1>
            <div className={s.date}>
                8 сентября 2022
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    В сентябре 2022 года стартует Всероссийский конкурс в сфере профессиональной деятельности
                    специалистов органов опеки и попечительства в отношении несовершеннолетних граждан «На стороне
                    ребенка».
                    <p></p>
                    Конкурс учрежден Министерством просвещения Российской Федерации в целях выявления органов опеки и
                    попечительства, а также специалистов органов опеки и попечительства, добившихся позитивных
                    результатов в своей профессиональной деятельности.
                    <p></p>
                    Деятельность специалистов органов опеки и попечительства сложна и сопряжена с огромной
                    ответственностью за судьбы детей. Выполнение своих обязанностей на высоком профессиональном уровне
                    требует от специалистов органов опеки и попечительства знаний и компетенций в области права,
                    психологии, социальной работы, конфликтологии, умения взаимодействовать с разными категориями
                    граждан, с детьми разных возрастных групп. Постоянный эмоциональный, а также информационный стресс
                    добавляет необходимость в самоконтроле, терпении и эмпатии.
                    <p></p>
                    Сегодня в системе органов опеки и попечительства работают профессиональные, заинтересованные и
                    неравнодушные люди, деятельность которых достойна быть отмечена на высоком уровне.
                    <p></p>
                    Проведение Всероссийского конкурса «На стороне ребенка» призвано сформировать положительный имидж
                    органов опеки и попечительства и повысить престиж деятельности специалистов.
                    <p></p>
                    Подать заявку для участия в конкурсе могут как орган опеки и попечительства, так и непосредственно
                    специалисты – для индивидуального участия.
                    <p></p>
                    По итогам Конкурса будут определены победители в пяти номинациях среди специалистов органов опеки и
                    попечительства: «Право ребенка на семью», «Защита прав детей в суде», «Помощник замещающей семьи»,
                    «Успешный старт»,
                    «На стороне ребенка». А также победители по трем номинациям среди органов опеки и попечительства:
                    «Эффективное взаимодействие с негосударственными некоммерческими организациями», «Лидер
                    информационных технологий», «Лидер открытости обществу».
                    <p></p>
                    Конкурс пройдет в четыре этапа: регистрация участников, отборочный этап, полуфинал и финал Конкурса.
                    Завершится конкурс торжественной церемонией награждения победителей, которая состоится в Москве в
                    декабре 2022 года.
                    <p></p>
                    Регистрация участников Конкурса будет осуществляться с 12 по 25 сентября 2022 года.
                    <p></p>
                    Информация о Конкурсе, регистрации участников, конкурсных испытаниях, размещена на сайте
                    Конкурса<br/><a href="https://на-стороне-ребенка.рф">https://на-стороне-ребенка.рф</a>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News080922
