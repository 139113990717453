import s from "./NewsPages.module.css"
import img from "../../assets/News/News240621.jpeg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News240621 = () => {

    return (
        <div className={s.main}>
            <h1>
                «Использование медиативных технологий в деятельности организаций для детей-сирот и детей, оставшихся без попечения родителей»
            </h1>
            <div className={s.date}>
                24 июня 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    22 июня 2021 г. прошел вебинар для специалистов организаций для детей-сирот
                    и детей, оставшихся без попечения родителей, по актуальной теме «Использование медиативных
                    технологий в деятельности организаций для детей-сирот и детей, оставшихся без попечения родителей».
                    <p></p>
                    В мероприятии приняли участие более 400 специалистов организаций для детей-сирот и детей, оставшихся
                    без попечения родителей, из 61 субъекта Российской Федерации. Модератор вебинара — аналитик II
                    категории Федерального ресурсного центра по вопросам опеки и попечительства Назарова Мария
                    Алексеевна познакомила участников с планом проведения мероприятия и основными организационными
                    моментами.
                    <p></p>
                    В качестве спикера была приглашена аналитик ФГБУ «Центр защиты прав и интересов детей»,
                    профессиональный тренер-медиатор, член Национальной организации медиаторов Быкова Марина Викторовна,
                    которая рассказала о понятие «медиации», конфликтах: причинах, последствиях и способах их
                    разрешения, условиях и принципах медиации. В процессе проведения вебинара спикер использовал
                    интерактивные упражнения для участников встречи.
                    <p></p>
                    Марина Викторовна познакомила участников вебинара с основными технологиями медиации: рефреймингом,
                    резюмированием, активным слушанием, уточнением, техникой задавания вопросов, работой с чувствами.
                    <p></p>
                    Особое внимание было уделено медиации в работе с детьми, в том числе и детьми-сиротами.
                    <p></p>
                    В ходе вебинара Марина Викторовна приводила примеры из личной практики.
                    <p></p>
                    В завершении мероприятия ведущая ответила на актуальные вопросы по тематике проведенного
                    мероприятия.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News240621
