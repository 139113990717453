import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs31.jpg";
import YouTube from "react-youtube";
import { WebShareBlock } from "./Webs1";

const Webs31 = () => {
  const opts = {
    width: "100%",
    minHeight: "400",
  };

  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Вебинар по вопросам, связаным с возможности медиации
        при урегулировании семейных конфликтов
      </h1>
      <div className={s.date}>26.12.2022</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <YouTube videoId="26Z1VK4JOHM" opts={opts} />
        <div style={{ paddingTop: "15px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web31.pdf">
            ▶ Смотреть презентацию
          </a>
        </div>
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs31;
