import s from "./Main.module.css"
import {Placemark, YMaps, Map} from "@pbe/react-yandex-maps";
import {NavigatorArray} from "../../assets/Navigator/navigator";
import {useState} from "react";
import {CaretRightOutlined} from "@ant-design/icons";
import {placeMarks} from "../../assets/Navigator/placeMarks";


const Navigator = () => {
    return (
        <div className={s.main}>
            <h1>
                Навигатор ООиП
            </h1>
            <div style={{margin: "0 auto", width: "90%"}}>
                <YMaps>
                    <Map modules={['geoObject.addon.balloon']} state={mapState} height="400px" width="100%">
                        {placeMarks.map((point, index) => (
                            <Placemark
                                key={index}
                                geometry={point.coordinates}
                                properties={{
                                    balloonContent: point.description
                                }}
                            />
                        ))}
                    </Map>
                </YMaps>
            </div>
            <h1 style={{fontSize: "30px", color: "#333333"}}>
                Перечень органов исполнительной власти субъектов РФ, осуществляющих управление в сфере опеки и попечительства в отношении несовершеннолетних граждан
            </h1>
            <div className={s.greyBlock}>
                {NavigatorArray.map(el => <div>
                        <Region reestrSlice={el}/>

                    </div>
                )}
            </div>
        </div>
    )
}
const mapState = {center: [55.751574, 37.573856], zoom: 4};

export default Navigator;

const Region = ({reestrSlice}) => {
    const [active, setActive] = useState(false)

    return <div style={{paddingTop: "20px"}}>
        <div className={s.item} onClick={() => {
            active ? setActive(false) : setActive(true)
        }}>
            <img src="/logo192.png" style={{
                paddingRight: "4px",
                width: "30px"
            }}/><strong>{reestrSlice[0][0].col1} </strong><CaretRightOutlined
            style={{
                rotate: `${active ? "0deg" : "90deg"}`,
                transition: "all 1s",
                marginLeft: "8px",
                fontSize: "20px"
            }}/>
        </div>
        {active ? <>{reestrSlice.map(((oblasti, id) => <ShowedLocation oblasti={oblasti} id={id}
                                                                       reestrSlice={reestrSlice}/>))}   </> :
            <div></div>}
    </div>
}

const ShowedLocation = ({oblasti, id, reestrSlice}) => {
    const [activeRegion, setActiveRegion] = useState(false)
    return <div>

        <div onClick={() => {
            activeRegion ? setActiveRegion(false) : setActiveRegion(true)
        }} style={{textAlign: "center"}}>
            <div style={{paddingBottom: "30px"}} className={s.region}><img src="/logo192.png" style={{
                paddingRight: "4px",
                height: "26px"
            }}/>{oblasti[0].col2}<CaretRightOutlined
                style={{
                    rotate: `${activeRegion ? "0deg" : "90deg"}`,
                    transition: "all 1s",
                    marginLeft: "8px",
                    fontSize: "20px"
                }}/></div>
            {activeRegion ? <div>
                {oblasti.map(((centers) => <Location col3={centers.col3} col4={centers.col4}
                                                     col5={centers.col5}
                                                     col6={centers.col6.split(',').map(line => <a
                                                         href={`mailto:${line}`}>{line}</a>)}
                                                     col7={centers.col7.split(',').map(line =>
                                                         <span>{line}<br/></span>)}
                                                     col8={centers.col8.split(',').map(line => <a
                                                         href={`${line}`}>{line}</a>)}
                                                     col9={centers.col9}/>))
                }
            </div> : <></>
            }

        </div>
    </div>
}


const Location = ({col3, col4, col5, col6, col7, col8, col9}) => {

    return <div className={s.lockationsContainer}>
        <div className={s.lockations}>
            <div className={s.lockationsCol}>
                {col3}
            </div>
            <div className={s.lockationsCol}>
                {col4}
            </div>
            <div className={s.lockationsCol}>
                {col5}
            </div>
            <div className={s.lockationsCol}>
                {col6}
            </div>
            <div className={s.lockationsCol} style={{wordBreak: "break-all"}}>
                {col7}
            </div>
            <div className={s.lockationsCol}>
                {col8}
            </div>
            <div className={s.lockationsCol} style={{wordBreak: "break-word"}}>
                {col9}
            </div>
        </div>
    </div>
}