import s from "./Main.module.css"
import ico1 from "../../assets/Pages/09c.svg"
import ico2 from "../../assets/Pages/10c.svg"
import ico3 from "../../assets/Pages/11c.svg"
import {Placemark, YMaps, Map} from "@pbe/react-yandex-maps";

const Contacts = () => {
    return (
        <div className={s.main}>
            <h1>Контакты</h1>
            <div className={s.contacts}>
                <li>
                    <a href="https://yandex.ru/maps/-/CDD2Z28z"><img
                        alt=""
                        src={ico1} style={{color: "black"}}/>Юридический адрес: 119435, г. Москва, ул. Погодинская, д.8/1</a>
                </li>
                <li>
                    <a href="https://yandex.ru/maps/-/CDD2Z28z"><img
                        alt=""
                        src={ico1}/>Фактический адрес: 119435, г. Москва, ул. Погодинская, д.8/1</a>
                </li>
                <li>
                    <a href="https://yandex.ru/maps/-/CDD2ZLZO"><img
                        alt=""
                        src={ico1}/>Почтовый адрес адрес: 115093, г. Москва, ул. Люсиновская, д. 51</a>
                </li>
                <li>
                    <a href="tel:+74994440806,%2010005"><img alt="" src={ico3}/><strong>+7 (499) 444 08 06 (доб. 10005)</strong></a>
                </li>
                <li>
                    <a href="tel:+74994440806,%2010003"><img alt="" src={ico3}/><strong>+7 (499) 444 08 06 (доб. 10102)</strong></a>
                </li>
                <li>
                    <a href="mailto:opeca@fcprc.ru"><img alt="" src={ico2}/>opeca@fcprc.ru</a>
                </li>
            </div>
            <div style={{margin: "0 auto", maxWidth: "1200px", width: "90%"}}>
                <YMaps>
                    <Map modules={['geoObject.addon.balloon']} state={mapState} width="100%" height={400}>
                        {placeMarks.map((point, index) => (
                            <Placemark
                                key={index}
                                geometry={point.coordinates}
                                properties={{
                                    balloonContent: point.description
                                }}
                            />
                        ))}
                    </Map>
                </YMaps>
            </div>
        </div>
    )
}
const mapState = {center: [55.751574, 37.573856], zoom: 11};
const placeMarks = [
    {
        coordinates: [55.733660, 37.568695],
        description: "119435, г. Москва, ул. Погодинская, д.8/1",
    },
    {
        coordinates: [55.719805, 37.623573],
        description: "115093, г. Москва, ул. Люсиновская, д. 51",
    }
]
export default Contacts;