import s from "./NewsPages.module.css"
import img from "../../assets/News/News051023.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News051023 = () => {

    return (
        <div className={s.main}>
            <h1>
                С днем учителя!
            </h1>
            <div className={s.date}>
                5 октября 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News051023
