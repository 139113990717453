import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import file from "../../assets/Reports/informacija-mvd.pdf";

const Report2024_4 = () => {
  const [respData, setData] = useState({
    o1: "",
    o1p1: "",
    o1p2: "",
    o1p3: "",
    o1p4: "",
    o1p5: "",
    o1p6: "",
    o1p7: "",
    o1p8: "",
    o1p9: "",
    o1p10: "",
    o1p11: "",
    o1p12: "",
    o1p13: "",
    o1p13p1: "",
    o2: "",
    o3: "",
    comm: "",
    регион: "",
  });
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id) {
        let config = {
          method: "GET",
          maxBodyLength: Infinity,
          url: `https://frcopeca.ru/api/admreg?id=${user.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        };
        try {
          const response = await axios.request(config);
          if (response.data[0]) {
            setData(response.data[0]);
          }
        } catch (error) {
          setData({
            o1: "",
            o1p1: "",
            o1p2: "",
            o1p3: "",
            o1p4: "",
            o1p5: "",
            o1p6: "",
            o1p7: "",
            o1p8: "",
            o1p9: "",
            o1p10: "",
            o1p11: "",
            o1p12: "",
            o1p13: "",
            o1p13p1: "",
            o2: "",
            o3: "",
            comm: "",
            регион: "",
          });
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className={s.main}>
      <h1>Отчет за 2024 год</h1>
      {respData.статус && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "24px",
            fontWeight: "normal",
            color: "#2468b2",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Статус: {respData.статус}
        </div>
      )}
      {respData.комментарии && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "24px",
            fontWeight: "normal",
            color: "red",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Комментарий от проверяющего:
          <br />
          {respData.комментарии}
        </div>
      )}
      <ReportForm respData={respData} />
      {/* <AddForm/> */}
    </div>
  );
};
export default Report2024_4;

const ReportForm = ({ respData }) => {
  return (
    <div>
      <FormDetails title="Четвертый отчет">
        <Report2 respData={respData} />
      </FormDetails>
    </div>
  );
};

const Report2 = ({ respData }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const readOnly =
    respData.статус === "На утверждении" || respData.статус === "Опубликовано";
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        o1: respData.o1,
        o1p1: respData.o1p1,
        o1p2: respData.o1p2,
        o1p3: respData.o1p3,
        o1p4: respData.o1p4,
        o2: respData.o2,
        o3: respData.o3,
        o4: respData.o4,
        o5: respData.o5,
        o6: respData.o6,
        o7: respData.o7,

        o8: respData.o8,
        o91: respData.o91,
        o92: respData.o92,
        o93: respData.o93,
        o94: respData.o94,
        o10: respData.o10,
        o11: respData.o11,

        comm: respData.комментарии,
        регион: respData.регион,
      }}
      onSubmit={(values) => {
        let data2 = new FormData();
        debugger;
        data2.append("o1", values.o1);
        data2.append("o1p1", values.o1p1);
        data2.append("o1p2", values.o1p2);
        data2.append("o1p3", values.o1p3);
        data2.append("o1p4", values.o1p4);
        data2.append("o2", values.o2);
        data2.append("o3", values.o3);
        data2.append("o4", values.o4);
        data2.append("o5", values.o5);
        data2.append("o6", values.o6);
        data2.append("o7", values.o7);
        data2.append("o8", values.o8);
        data2.append("o91", values.o91);
        data2.append("o92", values.o92);
        data2.append("o93", values.o93);
        data2.append("o94", values.o94);
        data2.append("o10", values.o10);
        data2.append("o11", values.o11);
        data2.append("регион", values.регион);
        data2.append("author", user.id);
        let config = {
          method: respData.регион ? "patch" : "POST",
          maxBodyLength: Infinity,

          url: `https://frcopeca.ru/api/admreg`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
          data: data2,
        };
        debugger;
        axios.request(config).finally(() => navigate("/profile"));
      }}
    >
      {(errors, touched) => (
        <Form className={s.reportForm}>
          <label htmlFor="регион">Регион</label>
          <Field
            readOnly={readOnly}
            id="регион"
            name="регион"
            type="text"
            required={true}
          />
          <label>
            Сокращенные понятия, применяемые в мониторинге: под лицами из числа
            детей-сирот понимаются: - лица из числа детей-сирот и детей,
            оставшихся без попечения родителей; - лица, которые ранее относились
            к категории детей-сирот и детей, оставшихся без попечения родителей,
            в возрасте 23 лет и старше;
          </label>
          <label htmlFor="o1">
            1. Численность лиц из числа детей-сирот, зарегистрированных по месту
            жительства по адресам местных администраций (по данным МВД России за
            2023 г. согласно данным приложения)
          </label>
          <Field
            readOnly={readOnly}
            id="o1"
            name="o1"
            type="number"
            required={true}
          />

          <label htmlFor="o1p1">
            1.1 из них (из строки 1): органами исполнительной власти субъекта
            РФ/органами местного самоуправления предоставлены жилые помещения во
            временное пользование, включая жилые помещения маневренного /иного
            жилищного фонда
          </label>
          <Field
            readOnly={readOnly}
            id="o1p1"
            name="o1p1"
            type="number"
            required={true}
          />

          <label htmlFor="o1p2">
            1.2 проживают в организациях социального обслуживания
            (дома-интернаты, дома ночного пребывания, центры социальной
            адаптации для лиц без определенного места жительства, социальные
            гостиницы и др.)*
          </label>
          <Field
            readOnly={readOnly}
            id="o1p2"
            name="o1p2"
            type="number"
            required={true}
          />

          <label htmlFor="o1p3">
            1.3 проживают в организациях системы здравоохранения
          </label>
          <Field
            readOnly={readOnly}
            id="o1p3"
            name="o1p3"
            type="number"
            required={true}
          />
          <label htmlFor="o1p4">
            1.4 проживают в общежитиях профессиональных образовательных
            организаций{" "}
          </label>
          <Field
            readOnly={readOnly}
            id="o1p4"
            name="o1p4"
            type="number"
            required={true}
          />

          <label htmlFor="o1p5">
            1.5 проживают в организациях для детей-сирот и детей, оставшихся без
            попечения родителей
          </label>
          <Field
            readOnly={readOnly}
            id="o1p5"
            name="o1p5"
            type="number"
            required={true}
          />
          <label htmlFor="o1p6">
            1.6 проживают временно на безвозмездной основе в жилых помещениях,
            принадлежащих НКО/частным лицам
          </label>
          <Field
            readOnly={readOnly}
            id="o1p6"
            name="o1p6"
            type="number"
            required={true}
          />
          <label htmlFor="o1p7">
            1.7 проживают в арендованных жилых помещениях
          </label>
          <Field
            readOnly={readOnly}
            id="o1p7"
            name="o1p7"
            type="number"
            required={true}
          />
          <label htmlFor="o1p8">1.8 проживают у родственников (знакомых)</label>
          <Field
            readOnly={readOnly}
            id="o1p8"
            name="o1p8"
            type="number"
            required={true}
          />
          <label htmlFor="o1p9">
            1.9 проживают у бывших опекунов (попечителей), приемных родителей
          </label>
          <Field
            readOnly={readOnly}
            id="o1p9"
            name="o1p9"
            type="number"
            required={true}
          />
          <label htmlFor="o1p10">1.10 находятся в местах лишения свободы</label>
          <Field
            readOnly={readOnly}
            id="o1p10"
            name="o1p10"
            type="number"
            required={true}
          />
          <label htmlFor="o1p11">
            1.11 проходят службу в Вооруженных Силах Российской Федерации
            (включая участие в СВО)
          </label>
          <Field
            readOnly={readOnly}
            id="o1p11"
            name="o1p11"
            type="number"
            required={true}
          />
          <label htmlFor="o1p12">
            1.12 фактическое место жительства/пребывания неизвестно
          </label>
          <Field
            readOnly={readOnly}
            id="o1p12"
            name="o1p12"
            type="number"
            required={true}
          />
          <label htmlFor="o1p13">
            1.13 проживают в других местах жительства/пребывания
          </label>
          <Field
            readOnly={readOnly}
            id="o1p13"
            name="o1p13"
            type="number"
            required={true}
          />
          <label htmlFor="o1p13p1">
            1.13.1 из них (из строки 1.13): при наличии других мест
            жительства/пребывания - укажите эти места
          </label>
          <Field
            readOnly={readOnly}
            id="o1p13p1"
            name="o1p13p1"
            type="number"
            required={true}
          />

          <label htmlFor="o2">
            2. Причины регистрации по адресам местных администраций
          </label>
          <Field
            readOnly={readOnly}
            id="o2"
            name="o2"
            type="number"
            required={true}
          />

          <label htmlFor="o3">
            3. Размер максимальной стоимости содержания одного ребенка в
            организациях для детей-сирот всех типов в год (в 2023 г.) руб.
            (заполняется, если в силу региональных особенностей стоимость
            содержания детей в разных муниципальных образованиях отличается. При
            отсутствии таких региональных особенностей указывается 0)
          </label>
          <Field
            readOnly={readOnly}
            id="o3"
            name="o3"
            type="number"
            required={true}
          />
          <label>
            <a href={file} target="_blank">
              *- СТРОКА 1 ЗАПОЛНЯЕТСЯ ПО ДАННЫМ МВД (скачать)
            </a>
          </label>
          <p></p>
          <button type="submit">Отправить</button>
        </Form>
      )}
    </Formik>
  );
};

const FormDetails = (props) => {
  const { children, title } = props;
  return (
    <div className={s.formDetails}>
      <details open={true}>
        <summary>
          {title}
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              float: "left",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            className={s.controlIconExpand}
          >
            <path
              d="M7 10L12 15L17 10"
              stroke="#2468b2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              float: "left",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            className={s.controlIconClose}
          >
            <path
              d="M17 15L12 10L7 15"
              stroke="#2468b2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </summary>
        {children}
      </details>
    </div>
  );
};

const AddForm = () => {
  return (
    <div className={s.addForm}>
      <NavLink to="/add-form">Добавить новый отчет</NavLink>
    </div>
  );
};
