import s from "./Files.module.css";
import { FileRef, hrefArray, InfoPanel } from "./FZ";
import { useEffect, useState } from "react";
import axios from "axios";

const Letters2 = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/NPD").then((res) => {
      setData(res);
    });
  }, []);
  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <div className={s.leftSide}>
          <h2>Письма Федеральной службы судебных приставов</h2>
          <FileRef
            text={respData ? respData.data[15].description : ""}
            url={respData ? respData.data[15].file : ""}
          />
          <FileRef
            text={respData ? respData.data[16].description : ""}
            url={respData ? respData.data[16].file : ""}
          />
          <FileRef
            text={respData ? respData.data[17].description : ""}
            url={respData ? respData.data[17].file : ""}
          />

          <FileRef
            text={respData ? respData.data[18].description : ""}
            url={respData ? respData.data[18].file : ""}
          />
        </div>
        <div className={s.rightSide}>
          <InfoPanel hrefArray={hrefArray} />
        </div>
      </div>
    </div>
  );
};
export default Letters2;
