import s from "./Footer.module.css"
import logo from "../../assets/Footer/logo.PNG"
const Footer = () => {
    return (
        <div className={s.footer}>
            <div className={s.info}>
                <div className={s.left}>
                    <div className={s.upper}>
                        <a href="https://edu.gov.ru/">МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</a>
                    </div>
                    <div className={s.bottom}>
                        <a href="/"><img src={logo} alt=""/></a>
                        <span>© ФРЦ в сфере защиты прав детей, оставшихся без попечения родителей, 2024</span>
                    </div>
                </div>
                <div className={s.right}>
                    <a href="/about">О центре</a>
                    <a href="/news">Новости</a>
                    <a href="/contacts">Контакты</a>
                </div>
            </div>
        </div>
    )
}
export default Footer