import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Report2024 = () => {
  const [respData, setData] = useState("");
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id) {
        let config = {
          method: "GET",
          maxBodyLength: Infinity,
          url: `https://frcopeca.ru/api/housefacts?id=${user.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        };
        try {
          const response = await axios.request(config);
          setData(response.data);
        } catch (error) {}
        setLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className={s.main}>
      <h1>Отчет за 2024 год</h1>
      {respData.статус && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "24px",
            fontWeight: "normal",
            color: "#2468b2",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Статус: {respData.статус}
        </div>
      )}
      {respData.комментарии && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "24px",
            fontWeight: "normal",
            color: "red",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Комментарий от проверяющего:
          <br />
          {respData.комментарии}
        </div>
      )}
      <ReportForm respData={respData} />
      <AddForm />
    </div>
  );
};
export default Report2024;

const ReportForm = ({ respData }) => {
  return (
    <div>
      <FormDetails title="Первый отчет">
        <Report1 respData={respData} />
      </FormDetails>
      {/*<FormDetails*/}
      {/*    title="Форма предоставления сведений об отмене решений передаче ребенка на воспитание в семью (ежеквартально)">*/}
      {/*    <Report2/>*/}
      {/*</FormDetails>*/}
      {/*<FormDetails*/}
      {/*    title="Форма предоставления сведений о выявлении и устройстве детей-сирот и детей, оставшихся без попечения родителей (ежеквартально)">*/}
      {/*    <Report3/>*/}
      {/*</FormDetails>*/}
    </div>
  );
};

const Report1 = ({ respData }) => {
  const user = useSelector((state) => state.user);
  const readOnly =
    respData.статус === "На утверждении" || respData.статус === "Опубликовано";
  const InfoSchema = Yup.object().shape({
    a1: Yup.number().required("Обязательное поле!"),
    a11: Yup.number().required("Обязательное поле!"),
    a12: Yup.number().required("Обязательное поле!"),
    a13: Yup.number().required("Обязательное поле!"),
    a14: Yup.number().required("Обязательное поле!"),
    a15: Yup.number().required("Обязательное поле!"),
    a16: Yup.number().required("Обязательное поле!"),
    a17: Yup.number().required("Обязательное поле!"),
    a18: Yup.number().required("Обязательное поле!"),
    a19: Yup.number().required("Обязательное поле!"),
    a110: Yup.number().required("Обязательное поле!"),
    a111: Yup.number().required("Обязательное поле!"),
    a112: Yup.number().required("Обязательное поле!"),
    a113: Yup.number().required("Обязательное поле!"),
    a114: Yup.number().required("Обязательное поле!"),
    a115: Yup.number().required("Обязательное поле!"),
    r16: Yup.string().required("Обязательное поле!"),
    r17: Yup.number().required("Обязательное поле!"),
    r18: Yup.number().required("Обязательное поле!"),
    r19: Yup.number().required("Обязательное поле!"),
    r20: Yup.number().required("Обязательное поле!"),
    r21: Yup.number().required("Обязательное поле!"),
    r22: Yup.number().required("Обязательное поле!"),
    r23: Yup.number().required("Обязательное поле!"),
    r24: Yup.number().required("Обязательное поле!"),
    r25: Yup.number().required("Обязательное поле!"),
    r26: Yup.number().required("Обязательное поле!"),
    r27: Yup.number().required("Обязательное поле!"),
    contact: Yup.string().required("Обязательное поле!"),
  });
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        o1: respData.количество,
        o11: respData.органами_исп_власти,
        o12: respData.проживают_в_организациях_социального_обслуживания,
        o13: respData.системы_здравоохранения,
        o14: respData.общежитиях_профессиональных_образовательных_организаций,
        o15: respData.организациях_для_детейсирот,
        o16: respData.в_жилых_помещениях_принадлежащих_НКОчастным_лицам,
        o17: respData.в_арендованных_жилых_помещениях,
        o18: respData.предоставлена_компенсация_расходов_на_оплату_арендынайма,
        o19: respData.проживают_у_родственников,
        o110: respData.проживают_у_бывших_опекунов,
        o171: respData.предоставлена_компенсация_расходов_на_оплату_арендынайма,
        o111: respData.находятся_в_местах_лишения_свободы,
        o112: respData.проходят_службу_в_вооруженных_силах_РФ,
        o113: respData.фактическое_место_жительствапребывания_неизвестно,
        o1131: respData.проживают_в_других_местах_жительствапребывания,
        o2: respData.численность_зарегистрированных_по_месту_жительства,
        comm: respData.комментарии || "",
        region: respData.регион,
      }}
      onSubmit={(values, { setSubmitting }) => {
        let data1 = new FormData();
        data1.append("количество", values.o1);
        data1.append("органами_исп_власти", values.o11);
        data1.append(
          "проживают_в_организациях_социального_обслуживания",
          values.o12
        );
        data1.append("системы_здравоохранения", values.o13);
        data1.append(
          "общежитиях_профессиональных_образовательных_организаций",
          values.o14
        );
        data1.append("организациях_для_детейсирот", values.o15);
        data1.append("в_арендованных_жилых_помещениях", values.o17);
        data1.append(
          "предоставлена_компенсация_расходов_на_оплату_арендынайма",
          values.o18
        );
        data1.append(
          "в_жилых_помещениях_принадлежащих_НКОчастным_лицам",
          values.o16
        );
        data1.append("проживают_у_родственников", values.o19);
        data1.append("проживают_у_бывших_опекунов", values.o110);
        data1.append("находятся_в_местах_лишения_свободы", values.o111);
        data1.append("проходят_службу_в_вооруженных_силах_РФ", values.o112);
        data1.append(
          "фактическое_место_жительствапребывания_неизвестно",
          values.o113
        );
        data1.append(
          "проживают_в_других_местах_жительствапребывания",
          values.o1131
        );
        data1.append(
          "численность_зарегистрированных_по_месту_жительства",
          values.o2
        );
        data1.append(
          "предоставлена_компенсация_расходов_на_оплату_арендынайма",
          values.o171
        );
        data1.append("регион", values.region);
        data1.append("комментарии", values.comm);
        data1.append("author", user.id);

        let config = {
          method: respData ? "patch" : "POST",
          maxBodyLength: Infinity,

          url: "https://frcopeca.ru/api/housepost",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
          data: data1,
        };
        axios.request(config).then((response) => {
          console.log(response);
        });
      }}
    >
      {({ errors, touched, values, isSubmitting }) => (
        <Form className={s.reportForm}>
          <label htmlFor="o1">
            1 Численность лиц из числа детей-сирот, у которых право на
            обеспечение жилыми помещениями возникло, но не реализовано по
            состоянию на 31 декабря 2023 г.
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o1"
            placeholder="Введите число"
          />
          {errors.o1 && touched.o1 ? (
            <div className={s.wrong}>{errors.o1}</div>
          ) : null}
          <label htmlFor="o11">
            1.1 из них (из строки 1):
            <br />
            органами исполнительной власти субъекта РФ / органами местного
            самоуправления лицам, указанным в строке 1, предоставлены жилые
            помещения во временное пользование, включая жилые помещения
            маневренного / иного жилищного фонда
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o11"
            placeholder="Введите число"
          />
          {errors.o11 && touched.o11 ? (
            <div className={s.wrong}>{errors.o11}</div>
          ) : null}
          <label htmlFor="o12">
            1.2 проживают в организациях социального обслуживания
            (дома-интернаты, дома ночного пребывания, центры социальной
            адаптации для лиц без определенного места жительства, социальные
            гостиницы и др.)
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o12"
            placeholder="Введите число"
          />
          {errors.o12 && touched.o12 ? (
            <div className={s.wrong}>{errors.o12}</div>
          ) : null}
          <label htmlFor="o13">
            1.3 проживают в организациях системы здравоохранения
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o13"
            placeholder="Введите число"
          />
          {errors.o13 && touched.o13 ? (
            <div className={s.wrong}>{errors.o13}</div>
          ) : null}
          <label htmlFor="o14">
            1.4 проживают в общежитиях профессиональных образовательных
            организаций
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o14"
            placeholder="Введите число"
          />
          {errors.o14 && touched.o14 ? (
            <div className={s.wrong}>{errors.o14}</div>
          ) : null}
          <label htmlFor="o15">
            1.5 проживают в организациях для детей-сирот и детей, оставшихся без
            попечения родителей
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o15"
            placeholder="Введите число"
          />
          {errors.o15 && touched.o15 ? (
            <div className={s.wrong}>{errors.o15}</div>
          ) : null}
          <label htmlFor="o16">
            1.6 проживают временно на безвозмездной основе в жилых помещениях,
            принадлежащих НКО/частным лицам
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o16"
            placeholder="Введите число"
          />
          {errors.o16 && touched.o16 ? (
            <div className={s.wrong}>{errors.o16}</div>
          ) : null}
          <label htmlFor="o17">
            1.7 проживают в арендованных жилых помещениях
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o17"
            placeholder="Введите число"
          />
          {errors.o17 && touched.o17 ? (
            <div className={s.wrong}>{errors.o17}</div>
          ) : null}
          <label htmlFor="o171">
            1.7.1 из них (из строки 1.7)
            <br />
            предоставлена компенсация расходов на оплату аренды/найма жилого
            помещения, в котором они временно проживают, из средств бюджета
            субъекта РФ/местного бюджета
          </label>
          <Field
            readOnly={readOnly}
            required={false}
            type="number"
            name="o171"
            placeholder="Введите число"
          />
          {errors.o171 && touched.o171 ? (
            <div className={s.wrong}>{errors.o171}</div>
          ) : null}
          <label htmlFor="o18">1.8 проживают у родственников (знакомых)</label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o18"
            placeholder="Введите число"
          />
          {errors.o18 && touched.o18 ? (
            <div className={s.wrong}>{errors.o18}</div>
          ) : null}
          <label htmlFor="o19">
            1.9 проживают у бывших опекунов (попечителей), приемных родителей
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o19"
            placeholder="Введите число"
          />
          {errors.o19 && touched.o19 ? (
            <div className={s.wrong}>{errors.o19}</div>
          ) : null}
          <label htmlFor="o110">1.10 находятся в местах лишения свободы</label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o110"
            placeholder="Введите число"
          />
          {errors.o110 && touched.o110 ? (
            <div className={s.wrong}>{errors.o110}</div>
          ) : null}
          <label htmlFor="o111">
            1.11 проходят службу в вооруженных силах Российской Федерации
            (включая участие в СВО)
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o111"
            placeholder="Введите число"
          />
          {errors.o111 && touched.o111 ? (
            <div className={s.wrong}>{errors.o111}</div>
          ) : null}
          <label htmlFor="o112">
            1.12 фактическое место жительства/пребывания неизвестно
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o112"
            placeholder="Введите число"
          />
          {errors.o112 && touched.o112 ? (
            <div className={s.wrong}>{errors.o112}</div>
          ) : null}
          <label htmlFor="o113">
            1.13 проживают в других местах жительства/пребывания
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o113"
            placeholder="Введите число"
          />
          {errors.o113 && touched.o113 ? (
            <div className={s.wrong}>{errors.o113}</div>
          ) : null}
          <label htmlFor="o1131">
            1.13.1 из них (из строки 1.13):
            <br />
            при наличии других мест жительства/пребывания - укажите эти места и
            численность лиц там проживающих
          </label>
          <Field
            readOnly={readOnly}
            type="text"
            name="o1131"
            placeholder="Введите текст"
          />
          {errors.o1131 && touched.o1131 ? (
            <div className={s.wrong}>{errors.o1131}</div>
          ) : null}
          <label htmlFor="o2">
            2 Численность лиц из числа детей-сирот зарегистрированных по месту
            жительства в местных администрациях (из строки 1)
          </label>
          <Field
            readOnly={readOnly}
            required={true}
            type="number"
            name="o2"
            placeholder="Введите число"
          />
          {errors.o2 && touched.o2 ? (
            <div className={s.wrong}>{errors.o2}</div>
          ) : null}

          <button type="submit">Отправить</button>
        </Form>
      )}
    </Formik>
  );
};

const FormDetails = (props) => {
  const { children, title } = props;
  return (
    <div className={s.formDetails}>
      <details open={true}>
        <summary>
          {title}
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              float: "left",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            className={s.controlIconExpand}
          >
            <path
              d="M7 10L12 15L17 10"
              stroke="#2468b2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              float: "left",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            className={s.controlIconClose}
          >
            <path
              d="M17 15L12 10L7 15"
              stroke="#2468b2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </summary>
        {children}
      </details>
    </div>
  );
};

const Report2 = () => {
  const [respData, setData] = useState("");
  const [err, setErr] = useState("");

  // useEffect(() => {
  //     let config = {
  //         method: 'GET',
  //         maxBodyLength: Infinity,
  //         url: 'https://dpo.fcprc.ru/api/usersdata',
  //         headers: {
  //             "Content-Type": "multipart/form-data",
  //             'Authorization': `Bearer ${localStorage.getItem("access")}`,
  //         }
  //     };
  //     axios.request(config)
  //         .then((response) => {
  //             setData(response.data)
  //         })
  //
  // }, []);

  const InfoSchema = Yup.object().shape({
    r1: Yup.number().required("Обязательное поле!"),
    r2: Yup.number().required("Обязательное поле!"),
    r3: Yup.number().required("Обязательное поле!"),
    r4: Yup.number().required("Обязательное поле!"),
    r5: Yup.number().required("Обязательное поле!"),
    r6: Yup.number().required("Обязательное поле!"),
    r7: Yup.number().required("Обязательное поле!"),
    r8: Yup.number().required("Обязательное поле!"),
    r9: Yup.number().required("Обязательное поле!"),
    r10: Yup.number().required("Обязательное поле!"),
    r11: Yup.number().required("Обязательное поле!"),
    r12: Yup.number().required("Обязательное поле!"),
    r13: Yup.number().required("Обязательное поле!"),
    r14: Yup.number().required("Обязательное поле!"),
    r15: Yup.number().required("Обязательное поле!"),
    r16: Yup.string().required("Обязательное поле!"),
    r17: Yup.number().required("Обязательное поле!"),
    r18: Yup.number().required("Обязательное поле!"),
    r19: Yup.number().required("Обязательное поле!"),
    r20: Yup.number().required("Обязательное поле!"),
    r21: Yup.number().required("Обязательное поле!"),
    r22: Yup.number().required("Обязательное поле!"),
    r23: Yup.number().required("Обязательное поле!"),
    r24: Yup.number().required("Обязательное поле!"),
    r25: Yup.number().required("Обязательное поле!"),
    r26: Yup.number().required("Обязательное поле!"),
    r27: Yup.number().required("Обязательное поле!"),
    contact: Yup.string().required("Обязательное поле!"),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        r1: "",
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
        r7: "",
        r8: "",
        r9: "",
        r10: "",
        r11: "",
        r12: "",
        r13: "",
        r14: "",
        r15: "",
        r16: "",
        r17: "",
        r18: "",
        r19: "",
        r20: "",
        r21: "",
        r22: "",
        r23: "",
        r24: "",
        r25: "",
        r26: "",
        r27: "",
        contact: "",
      }}
      validationSchema={InfoSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (
          Number(values.r1) !==
          Number(values.r2) + Number(values.r6) + Number(values.r7)
        ) {
          setErr(
            "Указанное в полях количество не соответствует требованиям в строке 1"
          );
        } else if (
          Number(values.r22) !==
          Number(values.r23) + Number(values.r24) + Number(values.r25)
        ) {
          setErr(
            "Указанное в полях количество не соответствует количеству в строке 22"
          );
        } else {
          setErr("");
        }
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className={s.reportForm}>
          <label htmlFor="r1">
            1. Численность детей, в отношении которых отменено решение о
            передаче ребенка на воспитание в семью за отчетный период (сумма
            строк 2 + 6 + 7)
          </label>
          <Field
            required={true}
            type="number"
            name="r1"
            placeholder="Введите число"
          />
          {errors.r1 && touched.r1 ? (
            <div className={s.wrong}>{errors.r1}</div>
          ) : null}

          <label htmlFor="r2">
            2. из них (из стр. 1):
            <br />
            по инициативе органа опеки и попечительства
          </label>
          <Field
            required={true}
            type="number"
            name="r2"
            placeholder="Введите число"
          />
          {errors.r2 && touched.r2 ? (
            <div className={s.wrong}>{errors.r2}</div>
          ) : null}

          <label htmlFor="r3">
            3. в связи с ненадлежащим выполнением усыновителями, опекунами,
            попечителями, приемными или патронатными родителями обязанностей по
            воспитанию детей
          </label>
          <Field
            required={true}
            type="number"
            name="r3"
            placeholder="Введите число"
          />
          {errors.r3 && touched.r3 ? (
            <div className={s.wrong}>{errors.r3}</div>
          ) : null}
          <label htmlFor="r13">
            4. в связи с ненадлежащим выполнением усыновителями, опекунами,
            попечителями, приемными или патронатными родителями обязанностей по
            воспитанию детей
          </label>
          <Field
            required={true}
            type="number"
            name="r4"
            placeholder="Введите число"
          />
          {errors.r4 && touched.r4 ? (
            <div className={s.wrong}>{errors.r4}</div>
          ) : null}
          <label htmlFor="r14">
            5. в связи с ненадлежащим выполнением усыновителями, опекунами,
            попечителями, приемными или патронатными родителями обязанностей по
            воспитанию детей
          </label>
          <Field
            required={true}
            type="number"
            name="r5"
            placeholder="Введите число"
          />
          {errors.r5 && touched.r5 ? (
            <div className={s.wrong}>{errors.r5}</div>
          ) : null}
          <label htmlFor="r6">
            6. по инициативе усыновителей, опекунов, попечителей, приемных или
            патронатных родителей
          </label>
          <Field
            required={true}
            type="number"
            name="r6"
            placeholder="Введите число"
          />
          {errors.r6 && touched.r6 ? (
            <div className={s.wrong}>{errors.r6}</div>
          ) : null}
          <label htmlFor="r7">7. по инициативе ребенка</label>
          <Field
            required={true}
            type="number"
            name="r7"
            placeholder="Введите число"
          />
          {errors.r7 && touched.r7 ? (
            <div className={s.wrong}>{errors.r7}</div>
          ) : null}
          <label htmlFor="r8">8. в связи с заболеванием ребенка</label>
          <Field
            required={true}
            type="number"
            name="r8"
            placeholder="Введите число"
          />
          {errors.r8 && touched.r8 ? (
            <div className={s.wrong}>{errors.r8}</div>
          ) : null}
          <label htmlFor="r9">
            9. временно в случае возникновения противоречий между интересами
            подопечного и интересами опекуна или попечителя
          </label>
          <Field
            required={true}
            type="number"
            name="r9"
            placeholder="Введите число"
          />
          {errors.r9 && touched.r9 ? (
            <div className={s.wrong}>{errors.r9}</div>
          ) : null}
          <label htmlFor="r10">10. по иным основаниям</label>
          <Field
            required={true}
            type="number"
            name="r10"
            placeholder="Введите число"
          />
          {errors.r10 && touched.r10 ? (
            <div className={s.wrong}>{errors.r10}</div>
          ) : null}
          <label htmlFor="r11">
            11. усыновители, опекуны (попечители) которых прошли подготовку
          </label>
          <Field
            required={true}
            type="number"
            name="r11"
            placeholder="Введите число"
          />
          {errors.r11 && touched.r11 ? (
            <div className={s.wrong}>{errors.r11}</div>
          ) : null}
          <label htmlFor="r12">
            12. усыновители, опекуны (попечители) которых прошли психологическое
            обследование
          </label>
          <Field
            required={true}
            type="number"
            name="r12"
            placeholder="Введите число"
          />
          {errors.r12 && touched.r12 ? (
            <div className={s.wrong}>{errors.r12}</div>
          ) : null}
          <label htmlFor="r111">
            13. в течение одного года с момента передачи ребенка на воспитание в
            семью
          </label>
          <Field
            required={true}
            type="number"
            name="r13"
            placeholder="Введите число"
          />
          {errors.r13 && touched.r13 ? (
            <div className={s.wrong}>{errors.r13}</div>
          ) : null}
          <label htmlFor="r14">
            14. по истечении пяти лет и более с момента передачи ребенка на
            воспитание в семью
          </label>
          <Field
            required={true}
            type="number"
            name="r14"
            placeholder="Введите число"
          />
          {errors.r14 && touched.r14 ? (
            <div className={s.wrong}>{errors.r14}</div>
          ) : null}
          <label htmlFor="r15">
            15. в отношении детей,переданных на безвозмездную форму опеки
            (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="r15"
            placeholder="Введите число"
          />
          {errors.r15 && touched.r15 ? (
            <div className={s.wrong}>{errors.r15}</div>
          ) : null}
          <label htmlFor="r16">
            16. переданных на возмездную форму опеки (попечительства)
          </label>
          <Field type="text" name="r16" placeholder="Введите текст" />
          {errors.r16 && touched.r16 ? (
            <div className={s.wrong}>{errors.r16}</div>
          ) : null}
          <label htmlFor="r17">17. усыновленных (удочеренных)</label>
          <Field
            required={true}
            type="number"
            name="r17"
            placeholder="Введите число"
          />
          {errors.r17 && touched.r17 ? (
            <div className={s.wrong}>{errors.r17}</div>
          ) : null}
          <label htmlFor="r17">
            18. переданных под предварительную опеку (попечительство)
          </label>
          <Field
            required={true}
            type="number"
            name="r18"
            placeholder="Введите число"
          />
          {errors.r18 && touched.r18 ? (
            <div className={s.wrong}>{errors.r18}</div>
          ) : null}
          <label htmlFor="r19">
            19. переданных в семьи близких родственников
          </label>
          <Field
            required={true}
            type="number"
            name="r19"
            placeholder="Введите число"
          />
          {errors.r19 && touched.r19 ? (
            <div className={s.wrong}>{errors.r19}</div>
          ) : null}
          <label htmlFor="r20">
            20. помещены под надзор в организацию для детей-сирот и детей,
            оставшихся без попечения родителей
          </label>
          <Field
            required={true}
            type="number"
            name="r20"
            placeholder="Введите число"
          />
          {errors.r20 && touched.r20 ? (
            <div className={s.wrong}>{errors.r20}</div>
          ) : null}
          <label htmlFor="r21">
            21. приняты на ту или иную форму семейного устройства
          </label>
          <Field
            required={true}
            type="number"
            name="r21"
            placeholder="Введите число"
          />
          {errors.r21 && touched.r21 ? (
            <div className={s.wrong}>{errors.r21}</div>
          ) : null}
          <label htmlFor="r22">
            22.Возраст детей, в отношении которых отменено решение о передаче
            ребенка на воспитание в семью за отчетный период (из строки 1):
          </label>
          <Field
            required={true}
            type="number"
            name="r22"
            placeholder="Введите число"
          />
          {errors.r22 && touched.r22 ? (
            <div className={s.wrong}>{errors.r22}</div>
          ) : null}
          <label htmlFor="r23">23. до 7 лет</label>
          <Field
            required={true}
            type="number"
            name="r23"
            placeholder="Введите число"
          />
          {errors.r23 && touched.r23 ? (
            <div className={s.wrong}>{errors.r23}</div>
          ) : null}
          <label htmlFor="r24">24. 7-10 лет</label>
          <Field
            required={true}
            type="number"
            name="r24"
            placeholder="Введите число"
          />
          {errors.r24 && touched.r24 ? (
            <div className={s.wrong}>{errors.r24}</div>
          ) : null}
          <label htmlFor="r25">25. 10-18 лет</label>
          <Field
            required={true}
            type="number"
            name="r25"
            placeholder="Введите число"
          />
          {errors.r25 && touched.r25 ? (
            <div className={s.wrong}>{errors.r25}</div>
          ) : null}
          <label htmlFor="r26">
            26. Численность усыновителей, опекунов (попечителей), с которыми
            были заключены договоры о сопровождении (из строки 1)
          </label>
          <Field
            required={true}
            type="number"
            name="r26"
            placeholder="Введите число"
          />
          {errors.r26 && touched.r26 ? (
            <div className={s.wrong}>{errors.r26}</div>
          ) : null}
          <label htmlFor="r27">
            27. Численность усыновителей, опекунов (попечителей) детей, с
            которыми расторгнуты договоры о сопровождении по их инициативе (из
            строки 1)
          </label>
          <Field
            required={true}
            type="number"
            name="r27"
            placeholder="Введите число"
          />
          {errors.r27 && touched.r27 ? (
            <div className={s.wrong}>{errors.r27}</div>
          ) : null}
          <label htmlFor="contact">
            Контактные данные ответственного за заполнение формы мониторинга
            (наименование ОИВ, Ф.И.О., должность, телефон (с указанием кода
            региона), адрес электронной почты)
          </label>
          <Field
            required={true}
            type="text"
            name="contact"
            placeholder="Введите..."
          />
          {errors.contact && touched.contact ? (
            <div className={s.wrong}>{errors.contact}</div>
          ) : null}
          <button type="button">Скачать файл</button>
          <button type="submit">Отправить</button>
          {err ? <div className={s.wrong}>{err}</div> : null}
        </Form>
      )}
    </Formik>
  );
};
const Report3 = () => {
  const [respData, setData] = useState("");
  const [err, setErr] = useState("");

  // useEffect(() => {
  //     let config = {
  //         method: 'GET',
  //         maxBodyLength: Infinity,
  //         url: 'https://dpo.fcprc.ru/api/usersdata',
  //         headers: {
  //             "Content-Type": "multipart/form-data",
  //             'Authorization': `Bearer ${localStorage.getItem("access")}`,
  //         }
  //     };
  //     axios.request(config)
  //         .then((response) => {
  //             setData(response.data)
  //         })
  //
  // }, []);

  const InfoSchema = Yup.object().shape({
    a1: Yup.number().required("Обязательное поле!"),
    a2: Yup.number().required("Обязательное поле!"),
    a3: Yup.number().required("Обязательное поле!"),
    a4: Yup.number().required("Обязательное поле!"),
    a5: Yup.number().required("Обязательное поле!"),
    a6: Yup.number().required("Обязательное поле!"),
    a7: Yup.number().required("Обязательное поле!"),
    a8: Yup.number().required("Обязательное поле!"),
    a9: Yup.number().required("Обязательное поле!"),
    a10: Yup.number().required("Обязательное поле!"),
    a11: Yup.number().required("Обязательное поле!"),
    a12: Yup.number().required("Обязательное поле!"),
    a13: Yup.number().required("Обязательное поле!"),
    a14: Yup.number().required("Обязательное поле!"),
    a15: Yup.number().required("Обязательное поле!"),
    a16: Yup.string().required("Обязательное поле!"),
    a17: Yup.number().required("Обязательное поле!"),
    a18: Yup.number().required("Обязательное поле!"),
    a19: Yup.number().required("Обязательное поле!"),
    a20: Yup.number().required("Обязательное поле!"),
    a21: Yup.number().required("Обязательное поле!"),
    a22: Yup.number().required("Обязательное поле!"),
    a23: Yup.number().required("Обязательное поле!"),
    a24: Yup.number().required("Обязательное поле!"),
    a25: Yup.number().required("Обязательное поле!"),
    a26: Yup.number().required("Обязательное поле!"),
    a27: Yup.number().required("Обязательное поле!"),
    a28: Yup.number().required("Обязательное поле!"),
    a29: Yup.number().required("Обязательное поле!"),
    a30: Yup.number().required("Обязательное поле!"),
    a31: Yup.number().required("Обязательное поле!"),
    a32: Yup.number().required("Обязательное поле!"),
    a33: Yup.number().required("Обязательное поле!"),
    a34: Yup.number().required("Обязательное поле!"),
    a35: Yup.number().required("Обязательное поле!"),
    a36: Yup.number().required("Обязательное поле!"),
    a37: Yup.number().required("Обязательное поле!"),
    a38: Yup.number().required("Обязательное поле!"),
    a39: Yup.number().required("Обязательное поле!"),
    a40: Yup.number().required("Обязательное поле!"),
    a41: Yup.number().required("Обязательное поле!"),
    a42: Yup.number().required("Обязательное поле!"),
    a43: Yup.number().required("Обязательное поле!"),
    a44: Yup.number().required("Обязательное поле!"),
    a45: Yup.number().required("Обязательное поле!"),
    a46: Yup.number().required("Обязательное поле!"),
    a47: Yup.number().required("Обязательное поле!"),
    a48: Yup.number().required("Обязательное поле!"),
    a49: Yup.number().required("Обязательное поле!"),
    a50: Yup.number().required("Обязательное поле!"),
    contact: Yup.string().required("Обязательное поле!"),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        a8: "",
        a9: "",
        a10: "",
        a11: "",
        a12: "",
        a13: "",
        a14: "",
        a15: "",
        a16: "",
        a17: "",
        a18: "",
        a19: "",
        a20: "",
        a21: "",
        a22: "",
        a23: "",
        a24: "",
        a25: "",
        a26: "",
        a27: "",
        a28: "",
        a29: "",
        a30: "",
        a31: "",
        a32: "",
        a33: "",
        a34: "",
        a35: "",
        a36: "",
        a37: "",
        a38: "",
        a39: "",
        a40: "",
        a41: "",
        a42: "",
        a43: "",
        a44: "",
        a45: "",
        a46: "",
        a47: "",
        a48: "",
        a49: "",
        a50: "",

        contact: "",
      }}
      validationSchema={InfoSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (
          Number(values.r1) !==
          Number(values.r2) +
            Number(values.r3) +
            Number(values.r4) +
            Number(values.r5) +
            Number(values.r6) +
            Number(values.r7) +
            Number(values.r8) +
            Number(values.r9) +
            Number(values.r10) +
            Number(values.r11) +
            Number(values.r12) +
            Number(values.r13)
        ) {
          setErr(
            "Указанное в полях количество не соответствует требованиям в строке 1"
          );
        } else if (
          Number(values.r14) !==
          Number(values.r15) +
            Number(values.r16) +
            Number(values.r17) +
            Number(values.r18) +
            Number(values.r24) +
            Number(values.r25) +
            Number(values.r26)
        ) {
          setErr(
            "Указанное в полях количество не соответствует требованиям в строке 14"
          );
        } else if (
          Number(values.r28) !==
          Number(values.r29) +
            Number(values.r30) +
            Number(values.r37) +
            Number(values.r40) +
            Number(values.r41)
        ) {
          setErr(
            "Указанное в полях количество не соответствует требованиям в строке 28"
          );
        } else if (
          Number(values.r43) !==
          Number(values.r44) + Number(values.r45) + Number(values.r46)
        ) {
          setErr(
            "Указанное в полях количество не соответствует требованиям в строке 43"
          );
        } else {
          setErr("");
        }
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className={s.reportForm}>
          <label htmlFor="a1">
            <strong>
              1. Всего детей выявлено и учтено на конец отчетного периода (сумма
              строк 2 - 13 )
            </strong>
          </label>
          <Field
            required={true}
            type="number"
            name="a1"
            placeholder="Введите число"
          />
          {errors.a1 && touched.a1 ? (
            <div className={s.wrong}>{errors.a1}</div>
          ) : null}

          <label htmlFor="a2">
            2. <strong>из них</strong> (из строки 1):
            <br />
            детей-сирот
          </label>
          <Field
            required={true}
            type="number"
            name="a2"
            placeholder="Введите число"
          />
          {errors.a2 && touched.a2 ? (
            <div className={s.wrong}>{errors.a2}</div>
          ) : null}

          <label htmlFor="a3">
            3. численность детей, у которых лишены родительских прав оба
            родителя или единственный родитель
          </label>
          <Field
            required={true}
            type="number"
            name="a3"
            placeholder="Введите число"
          />
          {errors.a3 && touched.a3 ? (
            <div className={s.wrong}>{errors.a3}</div>
          ) : null}
          <label htmlFor="a13">
            4. численность детей, у которых ограничены в родительских правах оба
            родителя или единственный родитель
          </label>
          <Field
            required={true}
            type="number"
            name="a4"
            placeholder="Введите число"
          />
          {errors.a4 && touched.a4 ? (
            <div className={s.wrong}>{errors.a4}</div>
          ) : null}
          <label htmlFor="a14">
            5. численность детей, у которых оба родителя или единственный
            родитель признаны недееспособными (ограниченно дееспособными)
          </label>
          <Field
            required={true}
            type="number"
            name="a5"
            placeholder="Введите число"
          />
          {errors.a5 && touched.a5 ? (
            <div className={s.wrong}>{errors.a5}</div>
          ) : null}
          <label htmlFor="a6">
            6. численность детей, родители которых находятся под стражей или
            отбывают наказания в виде лишения свободы
          </label>
          <Field
            required={true}
            type="number"
            name="a6"
            placeholder="Введите число"
          />
          {errors.a6 && touched.a6 ? (
            <div className={s.wrong}>{errors.a6}</div>
          ) : null}
          <label htmlFor="a7">
            7. численность детей, у которых оба родителя или единственный
            родитель объявлены в розыск
          </label>
          <Field
            required={true}
            type="number"
            name="a7"
            placeholder="Введите число"
          />
          {errors.a7 && touched.a7 ? (
            <div className={s.wrong}>{errors.a7}</div>
          ) : null}
          <label htmlFor="a8">
            8. численность детей, оставленных родителями в организациях по
            окончанию срока пребывания
          </label>
          <Field
            required={true}
            type="number"
            name="a8"
            placeholder="Введите число"
          />
          {errors.a8 && touched.a8 ? (
            <div className={s.wrong}>{errors.a8}</div>
          ) : null}
          <label htmlFor="a9">
            9. численность детей, отобранных у родителей (единственного
            родителя)
          </label>
          <Field
            required={true}
            type="number"
            name="a9"
            placeholder="Введите число"
          />
          {errors.a9 && touched.a9 ? (
            <div className={s.wrong}>{errors.a9}</div>
          ) : null}
          <label htmlFor="a10">
            10. численность детей, оставленных матерями (родителями) при
            рождении
          </label>
          <Field
            required={true}
            type="number"
            name="a10"
            placeholder="Введите число"
          />
          {errors.a10 && touched.a10 ? (
            <div className={s.wrong}>{errors.a10}</div>
          ) : null}
          <label htmlFor="a11">
            11. численность детей, у которых оба родителя или единственный
            родитель неизвестны
          </label>
          <Field
            required={true}
            type="number"
            name="a11"
            placeholder="Введите число"
          />
          {errors.a11 && touched.a11 ? (
            <div className={s.wrong}>{errors.a11}</div>
          ) : null}
          <label htmlFor="a12">
            12. численность детей, в отношении которых факт отсутствия
            родительского попечения установлен решением суда
          </label>
          <Field
            required={true}
            type="number"
            name="a12"
            placeholder="Введите число"
          />
          {errors.a12 && touched.a12 ? (
            <div className={s.wrong}>{errors.a12}</div>
          ) : null}
          <label htmlFor="a111">13. иное</label>
          <Field
            required={true}
            type="number"
            name="a13"
            placeholder="Введите число"
          />
          {errors.a13 && touched.a13 ? (
            <div className={s.wrong}>{errors.a13}</div>
          ) : null}
          <label htmlFor="a14">
            14. Всего детей устроены (из стр. 1) (сумма строк 15-18, 24-26)
          </label>
          <Field
            required={true}
            type="number"
            name="a14"
            placeholder="Введите число"
          />
          {errors.a14 && touched.a14 ? (
            <div className={s.wrong}>{errors.a14}</div>
          ) : null}
          <label htmlFor="a15">
            15. из них (из стр. 14) устроены: под надзор в организации для
            детей-сирот и детей, оставшихся без попечения родителей
            (образовательные, медицинские организации, в организации,
            оказывающие социальные услуги, в негосударственные организации, в
            детские дома семейного типа и другие)
          </label>
          <Field
            required={true}
            type="number"
            name="a15"
            placeholder="Введите число"
          />
          {errors.a15 && touched.a15 ? (
            <div className={s.wrong}>{errors.a15}</div>
          ) : null}
          <label htmlFor="a16">
            16. обучаются в профессиональных образовательных организациях и
            образовательных организациях высшего образования
          </label>
          <Field type="text" name="a16" placeholder="Введите текст" />
          {errors.a16 && touched.a16 ? (
            <div className={s.wrong}>{errors.a16}</div>
          ) : null}
          <label htmlFor="a17">
            17. находятся под предварительной опекой на конец отчетного периода
          </label>
          <Field
            required={true}
            type="number"
            name="a17"
            placeholder="Введите число"
          />
          {errors.a17 && touched.a17 ? (
            <div className={s.wrong}>{errors.a17}</div>
          ) : null}
          <label htmlFor="a17">18. под опеку (попечительство)</label>
          <Field
            required={true}
            type="number"
            name="a18"
            placeholder="Введите число"
          />
          {errors.a18 && touched.a18 ? (
            <div className={s.wrong}>{errors.a18}</div>
          ) : null}
          <label htmlFor="a19">
            19. из них (из строки 18):
            <br />
            переданные на безвозмездную форму опеки (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="a19"
            placeholder="Введите число"
          />
          {errors.a19 && touched.a19 ? (
            <div className={s.wrong}>{errors.a19}</div>
          ) : null}
          <label htmlFor="a20">
            20. переданные на возмездную форму опеки (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="a20"
            placeholder="Введите число"
          />
          {errors.a20 && touched.a20 ? (
            <div className={s.wrong}>{errors.a20}</div>
          ) : null}
          <label htmlFor="a21">
            21. в том числе (из строки 20):
            <br />
            под опеку (попечительство) по договору о приемной семье
          </label>
          <Field
            required={true}
            type="number"
            name="a21"
            placeholder="Введите число"
          />
          {errors.a21 && touched.a21 ? (
            <div className={s.wrong}>{errors.a21}</div>
          ) : null}
          <label htmlFor="a22">
            22. под опеку (попечительство) по договору о патронатной семье
            (патронате, патронатном воспитании) в случаях, предусмотренных
            законами субъектов Российской Федерации
          </label>
          <Field
            required={true}
            type="number"
            name="a22"
            placeholder="Введите число"
          />
          {errors.a22 && touched.a22 ? (
            <div className={s.wrong}>{errors.a22}</div>
          ) : null}
          <label htmlFor="a23">
            23. на иные виды возмездной опеки (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="a23"
            placeholder="Введите число"
          />
          {errors.a23 && touched.a23 ? (
            <div className={s.wrong}>{errors.a23}</div>
          ) : null}
          <label htmlFor="a24">24. на усыновление (удочерение)</label>
          <Field
            required={true}
            type="number"
            name="a24"
            placeholder="Введите число"
          />
          {errors.a24 && touched.a24 ? (
            <div className={s.wrong}>{errors.a24}</div>
          ) : null}
          <label htmlFor="a25">25. возвращены родителям</label>
          <Field
            required={true}
            type="number"
            name="a25"
            placeholder="Введите число"
          />
          {errors.a25 && touched.a25 ? (
            <div className={s.wrong}>{errors.a25}</div>
          ) : null}
          <label htmlFor="a26">26. иное</label>
          <Field
            required={true}
            type="number"
            name="a26"
            placeholder="Введите число"
          />
          {errors.a26 && touched.a26 ? (
            <div className={s.wrong}>{errors.a26}</div>
          ) : null}
          <label htmlFor="a27">
            27.{" "}
            <strong>
              Численность детей, оставшихся неустроенными на конец отчетного
              периода
            </strong>{" "}
            (из строки 1)
          </label>
          <Field
            required={true}
            type="number"
            name="a27"
            placeholder="Введите число"
          />
          {errors.a27 && touched.a27 ? (
            <div className={s.wrong}>{errors.a27}</div>
          ) : null}

          <label htmlFor="a28">
            28.{" "}
            <strong>
              Численность детей, оставшихся без попечения родителей, переданных
              в семью на воспитание, на конец отчетного периода
            </strong>{" "}
            (сумма стр. 29 + стр. 30 + стр. 37 + стр. 40 + стр. 41)
          </label>
          <Field
            required={true}
            type="number"
            name="a27"
            placeholder="Введите число"
          />
          {errors.a28 && touched.a28 ? (
            <div className={s.wrong}>{errors.a28}</div>
          ) : null}

          <label htmlFor="a29">
            29. из них (из строки 28): на безвозмездную форму опеки
            (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="a29"
            placeholder="Введите число"
          />
          {errors.a29 && touched.a29 ? (
            <div className={s.wrong}>{errors.a29}</div>
          ) : null}

          <label htmlFor="a30">
            30. на возмездную форму опеки (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="a30"
            placeholder="Введите число"
          />
          {errors.a30 && touched.a30 ? (
            <div className={s.wrong}>{errors.a30}</div>
          ) : null}
          <label htmlFor="a31">
            31. из них (из стр. 30):
            <br />
            по договору о приемной семье
          </label>
          <Field
            required={true}
            type="number"
            name="a31"
            placeholder="Введите число"
          />
          {errors.a31 && touched.a31 ? (
            <div className={s.wrong}>{errors.a31}</div>
          ) : null}
          <label htmlFor="a32">32. по договору о патронатной семье</label>
          <Field
            required={true}
            type="number"
            name="a32"
            placeholder="Введите число"
          />
          {errors.a32 && touched.a32 ? (
            <div className={s.wrong}>{errors.a32}</div>
          ) : null}
          <label htmlFor="a33">
            33. на иные виды возмездной опеки (попечительства)
          </label>
          <Field
            required={true}
            type="number"
            name="a33"
            placeholder="Введите число"
          />
          {errors.a33 && touched.a33 ? (
            <div className={s.wrong}>{errors.a33}</div>
          ) : null}
          <label htmlFor="a34">
            34. в том числе (из стр. 33):
            <br />
            за счет средств субъекта Российской Федерации
          </label>
          <Field
            required={true}
            type="number"
            name="a34"
            placeholder="Введите число"
          />
          {errors.a34 && touched.a34 ? (
            <div className={s.wrong}>{errors.a34}</div>
          ) : null}
          <label htmlFor="a35">
            35. за счет доходов от имущества подопечного
          </label>
          <Field
            required={true}
            type="number"
            name="a35"
            placeholder="Введите число"
          />
          {errors.a35 && touched.a35 ? (
            <div className={s.wrong}>{errors.a35}</div>
          ) : null}
          <label htmlFor="a36">36. за счет средств третьих лиц</label>
          <Field
            required={true}
            type="number"
            name="a36"
            placeholder="Введите число"
          />
          {errors.a36 && touched.a36 ? (
            <div className={s.wrong}>{errors.a36}</div>
          ) : null}
          <label htmlFor="a37">37. на усыновление (удочерение)</label>
          <Field
            required={true}
            type="number"
            name="a37"
            placeholder="Введите число"
          />
          {errors.a37 && touched.a37 ? (
            <div className={s.wrong}>{errors.a37}</div>
          ) : null}
          <label htmlFor="a38">
            38. из них (из стр. 37):
            <br />
            гражданами России
          </label>
          <Field
            required={true}
            type="number"
            name="a38"
            placeholder="Введите число"
          />
          {errors.a38 && touched.a38 ? (
            <div className={s.wrong}>{errors.a38}</div>
          ) : null}
          <label htmlFor="a39">39. иностранными гражданами</label>
          <Field
            required={true}
            type="number"
            name="a39"
            placeholder="Введите число"
          />
          {errors.a39 && touched.a39 ? (
            <div className={s.wrong}>{errors.a39}</div>
          ) : null}

          <label htmlFor="a40">
            40. находящихся под предварительной опекой (попечительством), на
            конец отчетного периода
          </label>
          <Field
            required={true}
            type="number"
            name="a40"
            placeholder="Введите число"
          />
          {errors.a40 && touched.a40 ? (
            <div className={s.wrong}>{errors.a40}</div>
          ) : null}
          <label htmlFor="a41">
            41. выбывших к родителям из организаций для детей-сирот и детей,
            оставшихся без попечения родителей, в течение отчетного периода
          </label>
          <Field
            required={true}
            type="number"
            name="a41"
            placeholder="Введите число"
          />
          {errors.a41 && touched.a41 ? (
            <div className={s.wrong}>{errors.a41}</div>
          ) : null}
          <label htmlFor="a42">
            42.{" "}
            <strong>
              Численность детей, оставшихся без попечения родителей, находящихся
              на воспитании в семьях
            </strong>
          </label>
          <Field
            required={true}
            type="number"
            name="a42"
            placeholder="Введите число"
          />
          {errors.a42 && touched.a42 ? (
            <div className={s.wrong}>{errors.a42}</div>
          ) : null}
          <label htmlFor="a43">
            43.{" "}
            <strong>
              Численность детей, находящихся в организациях для детей-сирот и
              детей, оставшихся без попечения родителей, на конец отчетного
              периода
            </strong>{" "}
            (сумма стр. 44 + стр. 45 + стр. 46)
          </label>
          <Field
            required={true}
            type="number"
            name="a43"
            placeholder="Введите число"
          />
          {errors.a43 && touched.a43 ? (
            <div className={s.wrong}>{errors.a43}</div>
          ) : null}
          <label htmlFor="a44">
            44. из них (из строки 43): находящихся под надзором
          </label>
          <Field
            required={true}
            type="number"
            name="a44"
            placeholder="Введите число"
          />
          {errors.a44 && touched.a44 ? (
            <div className={s.wrong}>{errors.a44}</div>
          ) : null}
          <label htmlFor="a45">
            45. в целях обеспечения временного проживания в течение периода,
            когда законные представители по уважительным причинам не могут
            исполнять свои обязанности в отношении ребенка
          </label>
          <Field
            required={true}
            type="number"
            name="a45"
            placeholder="Введите число"
          />
          {errors.a45 && touched.a45 ? (
            <div className={s.wrong}>{errors.a45}</div>
          ) : null}
          <label htmlFor="a46">
            46. в целях получения ими медицинских, социальных, образовательных
            или иных услуг
          </label>
          <Field
            required={true}
            type="number"
            name="a46"
            placeholder="Введите число"
          />
          {errors.a46 && touched.a46 ? (
            <div className={s.wrong}>{errors.a46}</div>
          ) : null}
          <label htmlFor="a47">
            47. с ограниченными возможностями здоровья (в том числе с
            инвалидностью)
          </label>
          <Field
            required={true}
            type="number"
            name="a47"
            placeholder="Введите число"
          />
          {errors.a47 && touched.a47 ? (
            <div className={s.wrong}>{errors.a47}</div>
          ) : null}
          <label htmlFor="a48">48. в возрасте от 10 до 18 лет</label>
          <Field
            required={true}
            type="number"
            name="a48"
            placeholder="Введите число"
          />
          {errors.a48 && touched.a48 ? (
            <div className={s.wrong}>{errors.a48}</div>
          ) : null}
          <label htmlFor="a49">
            49. полнородных и неполнородных братьев и сестер, совместно
            находящихся в одной организации для детей-сирот и детей, оставшихся
            без попечения родителей
          </label>
          <Field
            required={true}
            type="number"
            name="a49"
            placeholder="Введите число"
          />
          {errors.a49 && touched.a49 ? (
            <div className={s.wrong}>{errors.a49}</div>
          ) : null}
          <label htmlFor="a50">
            50.{" "}
            <strong>
              Численность лиц из числа детей, завершивших пребывание в
              организации для детей-сирот, но не старше 23 лет, временно
              бесплатно проживающих в организациях для детей-сирот и детей,
              оставшихся без попечения родителей, на конец отчетного периода
            </strong>
          </label>
          <Field
            required={true}
            type="number"
            name="a50"
            placeholder="Введите число"
          />
          {errors.a50 && touched.a50 ? (
            <div className={s.wrong}>{errors.a50}</div>
          ) : null}

          <label htmlFor="contact">
            Контактные данные ответственного за заполнение формы мониторинга
            (наименование ОИВ, Ф.И.О., должность, телефон (с указанием кода
            региона), адрес электронной почты)
          </label>
          <Field
            required={true}
            type="text"
            name="contact"
            placeholder="Введите..."
          />
          {errors.contact && touched.contact ? (
            <div className={s.wrong}>{errors.contact}</div>
          ) : null}
          <button type="button">Скачать файл</button>
          <button type="submit">Отправить</button>
          {err ? <div className={s.wrong}>{err}</div> : null}
        </Form>
      )}
    </Formik>
  );
};

const AddForm = () => {
  return (
    <div className={s.addForm}>
      <NavLink to="/add-form">Добавить новый отчет</NavLink>
    </div>
  );
};
