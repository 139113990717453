import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs29.jpg";
import { WebShareBlock } from "./Webs1";
import { FileUrl } from "./Webs26";

const Webs29 = () => {
  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Вебинар по вопросам, связанным с устройством на
        воспитание в семью ВИЧ-инфицированных детей
      </h1>
      <div className={s.date}>20.10.2022</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div style={{ paddingTop: "15px" }}>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "20px",
            }}
          >
            Видео
          </div>
          <FileUrl
            url="https://youtu.be/yjLd6DZ9Cxg"
            title="Видео материал вебинара"
          />
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "20px",
            }}
          >
            Презентации участников
          </div>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/web29_1.pdf"
            title="Книга семейное устройство"
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/web29_2.pdf"
            title="Кубышкина О.А."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/web29_3.pdf"
            title="Гальцова П.С."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/web29_4.pdf"
            title="Патран А.В."
          />
        </div>
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs29;
