import s from "./Main.module.css";
import img from "../../assets/Pages/Goryachaya_liniya_2-1-1.jpg"

const Hotline = () => {
    return (
        <div className={s.main}>
            <h1>Горячая линия по вопросам реализации жилищных прав детей-сирот, детей, оставшихся без попечения
                родителей, и лиц из их числа</h1>
            <div className={s.navigatorImg}>
                <a href="tel:88004441806"><img src={img} alt="88004441806"/></a>
            </div>
        </div>
    )
}
export default Hotline