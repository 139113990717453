import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs3.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs3 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Эффективная организация учебной деятельности при подготовке к экзаменам в домашних
                условиях
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    8 апреля 2020 г. прошел вебинар на актуальную для родителей выпускников тему: «Эффективная
                    организация учебной деятельности при подготовке к экзаменам в домашних условиях».
                    <p></p>
                    В мероприятии приняли участие 697 человек из 50 регионов Российской Федерации, а также Чехии,
                    Украины, Румынии и США. Ведущая вебинара, М.А. Назарова рассказала об использовании технологии
                    тайм-менеджмента для выпускников школ, о важности целеполагания в жизни человека и на примерах были
                    разобраны этапы постановки цели. К завершению вебинара родители смогли сформулировать свои
                    собственные желания так, чтобы они стали четкими и достижимыми.
                    <p></p>
                    В ходе выступления ведущая отвечала на вопросы, волнующие родителей и специалистов.
                </div>
                <YouTube videoId="kWWETMscic0" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs3
