import s from "./NewsPages.module.css"
import img from "../../assets/News/News101221.png"
import {AdviceBlock, ShareBlock} from "./News170621";

const News101221 = () => {

    return (
        <div className={s.main}>
            <h1>
                Всероссийское совещание руководителей органов опеки и попечительства и Форум приемных семей
            </h1>
            <div className={s.date}>
                10 декабря 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    2–4 декабря 2021 г. в режиме онлайн-трансляции состоялись Всероссийское совещание руководителей
                    органов опеки и попечительства и Форум приемных семей, посвященные совершенствованию деятельности
                    органов опеки и попечительства в отношении несовершеннолетних граждан и поддержке и развитию
                    института замещающих семей.
                    <p></p>
                    Совместный формат проведения двух традиционных мероприятий позволил организовать взаимодействие
                    профильных специалистов, негосударственных некоммерческих организаций, замещающих родителей и
                    получить оперативную обратную связь от руководителей ассоциаций приемных семей в субъектах
                    Российской Федерации по всем актуальным вопросам.
                    <p></p>
                    На дискуссионных площадках Всероссийского совещания и Форума обсуждались вопросы, касающиеся
                    совершенствования законодательства по опеке и попечительству в отношении несовершеннолетних граждан,
                    профессионального развития специалистов органов опеки и попечительства, типичных нарушений,
                    выявляемых при осуществлении надзора за исполнением органами опеки и попечительства требований
                    законодательства, подготовки граждан, желающих принять на воспитание в семью детей, оставшихся без
                    попечения родителей, сопровождения замещающих семей, дополнительных мер поддержки замещающих семей,
                    дальнейшего развития семейных форм устройства детей, оставшихся без попечения родителей.
                    <p></p>
                    На Всероссийском совещании наградами Минпросвещения России и Общероссийской
                    общественно-государственной организации «Фонд защиты детей» были отмечены руководители и специалисты
                    в сфере опеки и попечительства, органы исполнительной власти субъектов Российской Федерации,
                    осуществляющие государственное управление в сфере опеки и попечительства в отношении
                    несовершеннолетних граждан.
                    <p></p>
                    Видеозапись Всероссийского совещания и Форума приемных семей доступны по ссылке: <a href="/file">Всероссийское
                    совещание руководителей органов опеки и попечительства и Форум приемных семей</a>
                    <p></p>
                    <a href="/file">Резолюция Форума приемных семей</a>
                    <p></p>
                    <a href="/file">Резолюция Всероссийского совещания</a>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News101221
