import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs1.jpg"
import vk from "../../assets/News/vk_icon.PNG"
import ok from "../../assets/News/ok_icon.PNG"
import mail from "../../assets/News/mail_icon.PNG"
import {ArrowLeftOutlined} from "@ant-design/icons";
import YouTube from "react-youtube";


const Webs1 = () => {
    const opts = {
        width: "100%",
        minHeight: "400"
    }
    return (
        <div className={s.main}>
            <h1>
                Мастер-класс на тему: Развитие самостоятельности детей
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    О группах сопровождаемого взросления, тренировочных квартирах для воспитанников с ментальными
                    нарушениями рассказала методист ГБУ ЦССВ «Вера. Надежда. Любовь», директор Благотворительного Фонда
                    «Расправь крылья!» Романова Елизавета Александровна во время мастер-класса «Развитие
                    самостоятельности детей». В мастер-классе приняли участие 822 участника из 41 субъекта Российской
                    Федерации, а также участники из Республики Казахстан.
                </div>
                <YouTube videoId="ASc316eqUng" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs1

export const WebShareBlock = () => {
    const currentLocation = window.location;
    return <div className={s.shareBlock}>
                    <span className={s.btn}>
                        <a href="/webinars">
                            <ArrowLeftOutlined className={s.arrow}/>
                            Назад
                        </a>
                    </span>
        <span className={s.share}>
                        <span>
                            Поделитесь с коллегами и друзьями:
                        </span>
                        <span className={s.icons}>
                            <span className={s.item} style={{backgroundColor: "#5d90ca"}}>
                                <a href={"https://vk.com/share.php?url=" + currentLocation} target="_blank"
                                   rel="noreferrer">
                                    <img src={vk} alt="ВК"/>
                                </a>
                            </span>
                            <span className={s.item} style={{backgroundColor: "#eb722e"}}>
                                <a href={"https://connect.ok.ru/offer?url=" + currentLocation} target="_blank"
                                   rel="noreferrer">
                                    <img src={ok} alt="OK"/>
                                </a>
                            </span>
                            <span className={s.item} style={{backgroundColor: "#168de2"}}>
                                <a href={"https://connect.mail.ru/share?url=" + currentLocation} target="_blank"
                                   rel="noreferrer">
                                    <img src={mail} alt="Мой мир"/>
                                </a>
                            </span>
                        </span>
                    </span>
    </div>
}
