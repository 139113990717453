import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Report2024_5 = () => {
  const [respData, setData] = useState({
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    p10: "",
    p11: "",
    p12: "",
    p13: "",
    p14: "",
    p15: "",
    p16: "",
    p17: "",
    p18: "",
    p19: "",
    p20: "",
    p21: "",
    p22: "",
    p23: "",
    p24: "",
    p25: "",
    p26: "",
    p27: "",
      контакт_данные: "",
    comm: "",
    регион: "",
    примечание: "",
  });
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id) {
        let config = {
          method: "GET",
          maxBodyLength: Infinity,
          url: `https://frcopeca.ru/api/family_upbringing?id=${user.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        try {
          const response = await axios.request(config);
          if (response.data[0]) {
            setData(response.data[0]);
          }
        } catch (error) {
          setData({
            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p5: "",
            p6: "",
            p7: "",
            p8: "",
            p9: "",
            p10: "",
            p11: "",
            p12: "",
            p13: "",
            p14: "",
            p15: "",
            p16: "",
            p17: "",
            p18: "",
            p19: "",
            p20: "",
            p21: "",
            p22: "",
            p23: "",
            p24: "",
            p25: "",
            p26: "",
            p27: "",
              контакт_данные: "",
            comm: "",
            регион: "",
            примечание: "",
          });
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  if (loading) {
    return <div className={s.main}>Загрузка...</div>;
  }

  return (
    <div className={s.main}>
      <h1>Форма предоставления сведений об отмене решений о передаче ребенка на воспитание в семью за 3 квартала 2024 г</h1>
        {respData.статус && (
            <div
                style={{
                    paddingBottom: "15px",
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#000000",
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                Статус: {respData.статус}
            </div>
        )}
      {respData.комментарии && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "24px",
            fontWeight: "normal",
            color: "red",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Комментарий от проверяющего:
          <br />
          {respData.комментарии}
        </div>
      )}
      <ReportForm respData={respData} />
      {/* <AddForm/> */}
    </div>
  );
};
export default Report2024_5;

const ReportForm = ({ respData }) => {
  return (
    <div>
        <Report2 respData={respData} />
    </div>
  );
};

const Report2 = ({ respData }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");
  const readOnly = respData.статус === "Опубликовано";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        p1: respData.p1,
        p2: respData.p2,
        p3: respData.p3,
        p4: respData.p4,
        p5: respData.p5,
        p6: respData.p6,
        p7: respData.p7,
        p8: respData.p8,
        p9: respData.p9,
        p10: respData.p10,
        p11: respData.p11,
        p12: respData.p12,
        p13: respData.p13,
        p14: respData.p14,
        p15: respData.p15,
        p16: respData.p16,
        p17: respData.p17,
        p18: respData.p18,
        p19: respData.p19,
        p20: respData.p20,
        p21: respData.p21,
        p22: respData.p22,
        p23: respData.p23,
        p24: respData.p24,
        p25: respData.p25,
        p26: respData.p26,
        p27: respData.p27,
          контакт_данные: respData.контакт_данные,
        примечание: respData.примечание,

        comm: respData.комментарии,
        регион: respData.регион,
      }}
      onSubmit={(values) => {
        let data2 = new FormData();
        debugger;
        data2.append("p1", values.p1);
        data2.append("p2", values.p2);
        data2.append("p3", values.p3);
        data2.append("p4", values.p4);
        data2.append("p5", values.p5);
        data2.append("p6", values.p6);
        data2.append("p7", values.p7);
        data2.append("p8", values.p8);
        data2.append("p9", values.p9);
        data2.append("p10", values.p10);
        data2.append("p11", values.p11);
        data2.append("p12", values.p12);
        data2.append("p13", values.p13);
        data2.append("p14", values.p14);
        data2.append("p15", values.p15);
        data2.append("p16", values.p16);
        data2.append("p17", values.p17);
        data2.append("p18", values.p18);
        data2.append("p19", values.p19);
        data2.append("p20", values.p20);
        data2.append("p21", values.p21);
        data2.append("p22", values.p22);
        data2.append("p23", values.p23);
        data2.append("p24", values.p24);
        data2.append("p25", values.p25);
        data2.append("p26", values.p26);
        data2.append("p27", values.p27);
        data2.append("контакт_данные", values.контакт_данные);
        data2.append("регион", values.регион);
        data2.append("статус", "На утверждении")
        data2.append("примечание", values.примечание);
        data2.append("author", user.id);
            let config = {
                method: respData.регион ? "patch" : "POST",
                maxBodyLength: Infinity,
        
                url: `https://frcopeca.ru/api/family_upbringing?author=${user.id}`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
                data: data2,
            };
          axios.request(config)
              .then(() => {
                  setErr("");
                  navigate("/profile");
              })
              .catch((e) => {
                  const errorMessage = e.response
                      ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                      : e.message || JSON.stringify(e);

                  setErr(errorMessage);


                  console.error("Ошибка:", e);
              });
      }}
    >
      {(errors, touched) => (
          <Form className={s.reportForm}>
              <label htmlFor="регион">Субъект Российской Федерации</label>
              <Field
                  readOnly={readOnlyRegion}
                  id="регион"
                  name="регион"
                  type="text"
                  required={true}
              />

              <label htmlFor="p1">
                  1. Численность детей, в отношении которых отменено решение о
                  передаче ребенка на воспитание в семью за отчетный период (сумма
                  строк 2, 6, 7)
              </label>
              <Field readOnly={readOnly} id="p1" name="p1" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p2">
                  2. из них (из стр. 1): по инициативе органа опеки и попечительства
                  (сумма строк 3,4,5)*
              </label>
              <Field readOnly={readOnly} id="p2" name="p2" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p3">
                  3. в связи с ненадлежащим выполнением усыновителями, опекунами,
                  попечителями, приемными или патронатными родителями обязанностей по
                  воспитанию детей
              </label>
              <Field readOnly={readOnly} id="p3" name="p3" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p4">4. по причине жестокого обращения с детьми</label>
              <Field readOnly={readOnly} id="p4" name="p4" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p5">
                  5. в связи с нарушением усыновителями, опекунами, попечителями,
                  приемными или патронатными родителями правил охраны имущества
                  подопечного и (или) распоряжения его имуществом
              </label>
              <Field readOnly={readOnly} id="p5" name="p5" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p6">
                  6. по инициативе усыновителей, опекунов, попечителей, приемных или
                  патронатных родителей
              </label>
              <Field readOnly={readOnly} id="p6" name="p6" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p7">7. по инициативе ребенка</label>
              <Field readOnly={readOnly} id="p7" name="p7" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p8">8. в связи с заболеванием ребенка</label>
              <Field readOnly={readOnly} id="p8" name="p8" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p9">
                  9. временно в случае возникновения противоречий между интересами
                  подопечного и интересами опекуна или попечителя
              </label>
              <Field readOnly={readOnly} id="p9" name="p9" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p10">10. по иным основаниям</label>
              <Field readOnly={readOnly} id="p10" name="p10" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p">
                  11. усыновители, опекуны (попечители) которых прошли подготовку (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p11" name="p11" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p12">
                  12. усыновители, опекуны (попечители) которых прошли психологическое
                  обследование (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p12" name="p12" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p13">
                  13. в течение одного года с момента передачи ребенка на воспитание в
                  семью (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p13" name="p13" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p14">
                  14. по истечении пяти лет и более с момента передачи ребенка на
                  воспитание в семью (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p14" name="p14" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p15">
                  15. в отношении детей,переданных на безвозмездную форму опеки
                  (попечительства) (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p15" name="p15" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p16">
                  16. переданных на возмездную форму опеки (попечительства) (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p16" name="p16" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p17">17. усыновленных (удочеренных) (из показателя строки 1)</label>
              <Field readOnly={readOnly} id="p17" name="p17" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p18">
                  18. переданных под предварительную опеку (попечительство) (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p18" name="p18" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p19">
                  19. переданных в семьи близких родственников (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p19" name="p19" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p20">
                  20. помещены под надзор в организацию для детей-сирот и детей,
                  оставшихся без попечения родителей (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p20" name="p20" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p21">
                  21. приняты на ту или иную форму семейного устройства (из показателя строки 1)
              </label>
              <Field readOnly={readOnly} id="p21" name="p21" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p22">
                  22. Возраст детей, в отношении которых отменено решение о передаче
                  ребенка на воспитание в семью за отчетный период (из строки 1):
                  (сумма строк 23,24,25)
              </label>
              <Field readOnly={readOnly} id="p22" name="p22" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p23">23. до 7 лет</label>
              <Field readOnly={readOnly} id="p23" name="p23" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p24">24. 7-10 лет </label>
              <Field readOnly={readOnly} id="p24" name="p24" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p25">25. 10-18 лет </label>
              <Field readOnly={readOnly} id="p25" name="p25" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p26">
                  26. Численность усыновителей, опекунов (попечителей), с которыми
                  были заключены договоры о сопровождении (из строки 1)
              </label>
              <Field readOnly={readOnly} id="p26" name="p26" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="p27">
                  27. Численность усыновителей, опекунов (попечителей) детей, с
                  которыми расторгнуты договоры о сопровождении по их инициативе (из
                  строки 1)
              </label>
              <Field readOnly={readOnly} id="p27" name="p27" type="number" onWheel={(e) => e.target.blur()}
                     required={true}/>

              <label htmlFor="примечание">Примечание:</label>
              <Field readOnly={readOnly} id="примечание" name="примечание" type="text"/>

              <label htmlFor="контакт_данные">
                  Контактные данные ответственного за заполнение формы мониторинга
                  (Ф.И.О., телефон (с указанием кода региона), адрес электронной
                  почты)
              </label>
              <Field readOnly={readOnly} id="контакт_данные" name="контакт_данные" type="text" required={true}/>
              <p></p>
              <button type="submit">Отправить</button>
              <p></p>
              {respData.file && <a href={respData.file}>Скачать файл</a>}
              <p></p>
              <div style={{color: "red"}}>{err}</div>
          </Form>
      )}
    </Formik>
  );
};