import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs10.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs10 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Мастер-класс на тему: Развитие самостоятельности детей (ММСО)
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    О группах сопровождаемого взросления, тренировочных квартирах для воспитанников с ментальными
                    нарушениями рассказала методист ГБУ ЦССВ «Вера. Надежда. Любовь», директор Благотворительного Фонда
                    «Расправь крылья!» Романова Елизавета Александровна во время мастер-класса «Развитие
                    самостоятельности детей». В мастер-классе приняли участие 822 участника из 41 субъекта Российской
                    Федерации, а также участники из Республики Казахстан.
                </div>
                <YouTube videoId="ASc316eqUng" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs10
