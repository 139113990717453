import {partnersList} from "./Partners";
import s from "./PartnerSlider.module.css"

const PartnerSlider = () => {


    return (
        <div className={s.carousel}>
            {partnersList.map(el =>
                <div className={s.item}>
                    <a href={el.url}>
                        <img alt={el.text} src={el.img}/>
                    </a>
                </div>
            )}
        </div>

    )
}

export default PartnerSlider