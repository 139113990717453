import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs28.png";
import YouTube from "react-youtube";
import { WebShareBlock } from "./Webs1";

const Webs28 = () => {
  const opts = {
    width: "100%",
    minHeight: "400",
  };

  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: X Всероссийский Съезд руководителей организаций для
        детей-сирот и детей, оставшихся без попечения родителей.
      </h1>
      <div className={s.date}></div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <strong>Открытие</strong> X Всероссийского Съезда руководителей
          организаций для детей-сирот и детей, оставшихся без попечения
          родителей.
        </div>
        <YouTube videoId="4kh_HfVmYoc" opts={opts} />
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>ДИСКУССИОННЫЕ ПЛОЩАДКИ</strong>
        </div>
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <strong>Секция 1</strong> «Реализация мероприятий по переходу до 2024
          года к единой ведомственной подчиненности организаций для детей-сирот
          и детей, оставшихся без попечения родителей, и органов опеки и
          попечительства в субъектах Российской Федерации»
        </div>

        <YouTube videoId="p4-wjzRKVto" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_1.pdf">
            Презентация Аккуратовой А.П.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_2.pdf">
            Презентация Битовой А.Л.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_3.pdf">
            Презентация Жуковой М.А.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_4.pdf">
            Презентация Кудрявцевой И.В.
          </a>
        </div>
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <strong>Секция 2</strong> «Показатели и оценка готовности и успешности
          выпускников в самостоятельной жизни с учетом социально-психологических
          особенностей»
        </div>
        <YouTube videoId="3XKugv0hfJs" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_5.pdf">
            Презентация Ослон В.Н.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_6.pdf">
            Презентация Романовой Е.А.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_7.pdf">
            Презентация Шпитальской И.Ю.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_8.pdf">
            Презентация Бобылевой И.А.
          </a>
        </div>
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <strong>Секция 3</strong> «Особенности воспитательного процесса в
          организации для детей-сирот и детей, оставшихся без попечения
          родителей»
        </div>
        <YouTube videoId="5q-6qXM_dKw" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_9.pdf">
            Презентация Горюновой Н.А.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_10.pdf">
            Презентация Толкачевой Н.В.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_11.pdf">
            Презентация Синькевич М.В.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_12.pdf">
            Презентация Краснянской Ю.А.{" "}
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            ОТКРЫТАЯ ЭКСПЕРТНАЯ СЕССИЯ
            <br />с участием членов Совета руководителей организаций для
            детей-сирот и детей, оставшихся без попечения родителей, и
            представителей негосударственных некоммерческих организаций,
            осуществляющих деятельность в сфере защиты прав и интересов
            детей-сирот и детей, оставшихся без попечения родителей
          </strong>
        </div>
        <YouTube videoId="4DIs6py5KYk" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_13.pdf">
            Презентация Альшанской Е.Л.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_14.pdf">
            Презентация Новожиловой Н.Р.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_15.pdf">
            Презентация Соколовой О.А.
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>ПЛЕНАРНОЕ ЗАСЕДАНИЕ</strong>
        </div>
        <YouTube videoId="4J0r0ifFNx4" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="/">Презентация Фальковской Л.П.</a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            КРУГЛЫЙ СТОЛ
            <br />
            «Эффективный менеджмент организации для детей-сирот и детей,
            оставшихся без попечения родителей»
          </strong>
        </div>
        <YouTube videoId="93NVUyCFAyY" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_16.pdf">
            Презентация Фальковской Л.П.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_17.pdf">
            Презентация Травничек Б.Е.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_18.pdf">
            Презентация Ушаковой М.А.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_19.pdf">
            Презентация Акопянца И.А.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_20.docx">
            Текст выступления Акопянца И.А.
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            ВЕБИНАРЫ
            <p></p>
            Алгоритм работы психолога с ребенком в кризисной ситуации
          </strong>
        </div>
        <YouTube videoId="v-LWhbFLKDQ" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_21.pdf">
            Презентация Новиковой М.В.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_22.pdf">
            Книга Психологическая помощь ребенку в кризисных ситуациях
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_23.pdf">
            Проект методических рекомендаций Психологическое сопровождение в
            организации для детей-сирот
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            Программа групповой работы для детей, проявляющих агрессию
          </strong>
        </div>
        <YouTube videoId="qRQjW8GNErw" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_24.pdf">
            Презентация Болотовой А.С., Едемской Е.А.
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            Изменение поведенческой и эмоциональной реакции у детей-сирот,
            <br />с помощью метода «Внутренний договор» (Рефрейминг)
          </strong>
        </div>
        <YouTube videoId="FOiXy0wqxmg" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_25.pdf">
            Презентация Ермолаевой А.Е.
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            Методика обсуждения с детьми кризисного размещения вне семьи и
            других острых ситуаций в работе с семьей «История здесь и сейчас»
          </strong>
        </div>
        <YouTube videoId="321mi8RcHgg" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_26.pdf">
            Презентация Арчаковой Т.О., Логиновой Н.И.
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            Психологические техники по восстановлению ресурсного состояния
            специалистов помогающих профессий
          </strong>
        </div>
        <YouTube videoId="BmklNhYyrtg" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_27.pdf">
            Презентация Кузнецовой И.В.
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_28.docx">
            Приложение 1
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_29.docx">
            Приложение 2
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_30.docx">
            Приложение 3
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            Тренинг для подростков, направленный на снижение риска суицидов «Мир
            в ярких красках»
          </strong>
        </div>
        <YouTube videoId="_tZby0IDcP0" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_31.pdf">
            Презентация Дорошенко А.Ю., Сизякиной Н.Я.
          </a>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            PRO сексуальное развитие и половое воспитание в условиях социального
            учреждения
          </strong>
        </div>
        <YouTube videoId="y3F3geZpRkI" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_32.pdf">
            Книга Наука правильной заботы
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_33.pdf">
            Настольная книга воспитателя
          </a>
          <p></p>
          <a href="https://frcopeca.ru/media/media/behavior/web28_34.pdf">
            Презентация Чистяковой Н.В.
          </a>
          <p></p>
        </div>
        <div
          style={{
            paddingBottom: "30px",
            paddingTop: "30px",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <strong>
            «Кибербезопасность. Как не стать жертвой финансовых мошенников»
          </strong>
        </div>
        <YouTube videoId="0GRyMm8AdsY" opts={opts} />
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <a href="https://frcopeca.ru/media/media/behavior/web28_35.pdf">
            Презентация Потехина А.М.
          </a>
        </div>
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs28;
