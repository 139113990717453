import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs30.jpg";
import { WebShareBlock } from "./Webs1";
import { FileUrl } from "./Webs26";

const Webs30 = () => {
  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Вебинар по вопросам правильного наполнения ЕГИССО в
        части формирования списков детей-сирот, детей, оставшихся без попечения
        родителей, и лиц из их числа, подлежащих обеспечению жилыми помещениями
      </h1>
      <div className={s.date}>27.10.2022</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "20px",
          }}
        >
          Видео
        </div>
        <FileUrl
          url="https://www.youtube.com/embed/hqa4Mg6QbtM"
          title="Видео материал вебинара"
        />
        <p></p>
        <FileUrl
          url="https://youtu.be/f3OkrXcJ2pI"
          title="Включение в список"
        />
        <p></p>
        <FileUrl
          url="https://youtu.be/aM2hy4PtSCg"
          title="Исключение из списка"
        />
        <p></p>
        <FileUrl
          url="https://youtu.be/_2zs4jttLV4"
          title="Редактирование записи"
        />
        <p></p>
        <FileUrl url="https://youtu.be/-TtGIIEwBs8" title="Конвертация файла" />
        <p></p>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "20px",
          }}
        >
          Презентации участников
        </div>
        <FileUrl
          url="https://frcopeca.ru/media/media/behavior/web30_1.pdf"
          title="Размещение в ЕГИССО сведений"
        />
        <p></p>
        <FileUrl
          url="https://frcopeca.ru/media/media/behavior/web30_2.pdf"
          title="Обзор вопросов"
        />
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs30;
