import s from "./NewsPages.module.css"
import img from "../../assets/News/News070721.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News070721 = () => {

    return (
        <div className={s.main}>
            <h1>
                IX Съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов исполнительной власти субъектов Российской Федерации, осуществляющих управление в сфере опеки и попечительства в отношении несовершеннолетних граждан
            </h1>
            <div className={s.date}>
               7 июля 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    1–2 июля 2021 г. в режиме онлайн-трансляции состоялся IX Съезд руководителей организаций для
                    детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов исполнительной
                    власти субъектов Российской Федерации, осуществляющих управление в сфере опеки и попечительства в
                    отношении несовершеннолетних граждан.
                    <p></p>
                    Основной акцент Съезда – качественное улучшение жизни детей, проживающих в организациях для
                    детей-сирот.
                    <p></p>
                    Одной из ключевых тем Съезда была определена тема о переходе к единой ведомственной подчиненности
                    организаций для детей-сирот и органов опеки и попечительства, в рамках которой рассмотрены вопросы,
                    касающиеся влияния единой модели ведомственной подчиненности на всех участников и, прежде всего, на
                    детей, находящихся в организациях для детей-сирот, а также сохранения кадрового состава организаций
                    для детей-сирот и прав на трудовые льготы.
                    <p></p>
                    Также были затронуты вопросы о совместном нахождении работников организаций для детей-сирот,
                    представителей негосударственных некоммерческих организаций с детьми в медицинской организации в
                    случае их госпитализации, об исключении необоснованной госпитализации детей перед их помещением под
                    надзор в организацию для детей-сирот, о повышении профессиональных компетенций работников таких
                    организаций, адаптации примерной программы воспитания в организациях для детей-сирот.
                    <p></p>
                    С приветствием к участникам Съезда обратились член Комитета Совета Федерации по социальной политике
                    Мария Алексеевна Львова-Белова, первый заместитель председателя Комитета Государственной Думы по
                    вопросам семьи, женщин и детей Ольга Владимировна Окунева, председатель Общероссийской
                    общественно-государственной организации «Фонд защиты детей» Альберт Анатольевич Лиханов и
                    председатель Высшего Координационного совета Всероссийской общественной организации «Содружество
                    выпускников детских домов «Дети всей страны» Альберт Маратович Сарбалаев.
                    <p></p>
                    Свое приветствие участникам Съезда направила Уполномоченный при Президенте Российской Федерации по
                    правам ребенка Анна Юрьевна Кузнецова.
                    <p></p>
                    По актуальным вопросам, касающимся деятельности организаций для детей-сирот, на Съезде были
                    организованы мастер-классы и интерактивный семинар.
                    <p></p>
                    В работе Съезда приняли участие более 600 человек, включая представителей федеральных органов
                    исполнительной власти, органов исполнительной власти субъектов Российской Федерации, руководителей
                    организаций для детей-сирот, образовательных организаций высшего образования, некоммерческих
                    организаций.
                    <p></p>
                    <strong>Для просмотра всех мероприятий Съезда, перейдите <a
                        href="https://fcprc.ru/news/post-reliz-o-provedenii-ix-sezda-rukovoditelej-organizatsij-dlya-detej-sirot-i-detej-ostavshihsya-bez-popecheniya-roditelej-i-rukovoditelej-organov-ispolnitelnoj-vlasti-subektov-rossijskoj-federatsii/">по
                        ссылке</a> на основную статью мероприятия</strong>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News070721
