import s from "./NewsPages.module.css"
import img from "../../assets/News/News241123.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";
import ImageSlider from "../Carousel/ImageSlider";
import c1 from "../../assets/News/News241123_1.jpg"
import c2 from "../../assets/News/News241123_2.jpg"
import c3 from "../../assets/News/News241123_3.jpg"
import ImageSlider2 from "../Carousel/ImageSlider2";

const News241123 = () => {

    return (
        <div className={s.main}>
            <h1>
                Сергей Кравцов открыл Всероссийское совещание руководителей органов опеки и попечительства на выставке
                «Россия»
            </h1>
            <div className={s.date}>
                24 ноября 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>

                    В рамках Международной выставки-форума «Россия» в Центре детства павильона № 57 на ВДНХ 16
                    ноября стартовало Всероссийское совещание руководителей органов опеки и попечительства в
                    отношении несовершеннолетних граждан. Мероприятие объединило 200 специалистов из всех субъектов
                    Российской Федерации. В совещании принял участие Министр просвещения РФ Сергей Кравцов.

                    <div>
                        <ImageSlider2 slides={slides}/>
                    </div>
                    Открывая работу совещания, глава Минпросвещения России выразил благодарность сотрудникам органов
                    опеки и попечительства и отметил значимость воспитательной работы с приемными детьми.
                    <p></p>
                    «Самое главное для нас всех, чтобы каждый ребенок в нашей стране чувствовал себя защищенным и
                    уверенным в завтрашнем дне. Мы для этого делаем все возможное. Я надеюсь, что в рамках
                    Всероссийского совещания вы определите, какие новые проекты по защите детей и сохранению семьи нам
                    сегодня необходимо развивать и поддерживать», – добавил Сергей Кравцов.
                    <p></p>
                    Министр просвещения сообщил, что сфере сопровождения детей-сирот и детей, оставшихся без попечения
                    родителей, уделяется особое внимание. Минпросвещения России совместно с Министерством труда и
                    социальной защиты, Министерством здравоохранения оказывает всю необходимую поддержку специалистам,
                    вовлеченным в работу с детьми-сиротами. За последние пять лет численность детей в организациях для
                    детей-сирот сократилась с 42 до 36 тыс.
                    <p></p>
                    В рамках мероприятия Министр просвещения РФ также отметил сотрудников, осуществляющих управление в
                    сфере опеки и попечительства. Благодарственными письмами Минпросвещения России за эффективную и
                    добросовестную работу в сфере защиты прав и законных интересов детей-сирот и детей, оставшихся без
                    попечения родителей, профессионализм в решении актуальных вопросов по опеке и попечительству в
                    отношении несовершеннолетних были награждены Эльвира Эрдыниева, заместитель министра социальной
                    защиты населения Республики Бурятия, и Александр Крашенинников, начальник отдела Департамента
                    образования Ямало-Ненецкого автономного округа.
                    <p></p>
                    Звания «Почетный работник сферы воспитания детей и молодежи Российской Федерации» удостоена Марина
                    Лашкул, заместитель директора ФГБУ «Центр защиты прав и интересов детей».
                    <p></p>
                    Всероссийское совещание руководителей органов опеки и попечительства проходило 16-17 ноября.

                    <p></p>
                    <span style={{fontSize: "28px"}}>Справочно</span>
                    <p></p>
                    Минпросвещения России ежегодно проводит Всероссийское совещание руководителей органов опеки и
                    попечительства в отношении несовершеннолетних граждан. В нем принимают участие руководители
                    исполнительных органов всех субъектов Российской Федерации, осуществляющих государственное
                    управление в сфере опеки и попечительства, а также представители федеральных органов исполнительной
                    власти и общественных организаций, ведущие ученые и эксперты в сфере защиты прав детей. Цель
                    мероприятия – обмен опытом и лучшими региональными практиками.
                    <p></p>
                    Мероприятие прошло в рамках Знание.Лектория Российского общества «Знание» – генерального партнера
                    образовательной программы Международной выставки-форума «Россия».
                    <p></p>
                    <a href="https://disk.yandex.ru/d/YN-c5Kb-FTEdAA">Материалы Всероссийского совещания руководителей органов опеки и попечительства</a>
                    <p></p>
                    Ссылка на источник:<br/>
                    <a href="https://edu.gov.ru/press/7817/sergey-kravcov-otkryl-vserossiyskoe-soveschanie-rukovoditeley-organov-opeki-i-popechitelstva-na-vystavke-rossiya/">https://edu.gov.ru/press/7817/sergey-kravcov-otkryl-vserossiyskoe-soveschanie-rukovoditeley-organov-opeki-i-popechitelstva-na-vystavke-rossiya/</a>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News241123

const slides = [
    c1, c2, c3
]