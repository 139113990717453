import s from "./Main.module.css";

const Navigator3 = () => {

    return (
        <div className={s.main}>
            <h1>Реестр дополнительных профессиональных программ (модулей), организаций, осуществляющих обучение
                специалистов органов опеки и попечительства по дополнительным профессиональным программам</h1>
            <div className={s.navigator3}>
                <table className={s.zagolovok} >
                    <tbody>
                    <tr>
                        <th className={s.col1}>Субъект Российской Федерации</th>
                        <th className={s.col2}>Наименование дополнительной профессиональной программы</th>
                        <th className={s.col3}>Реквизиты документа, утверждающего программу</th>
                        <th className={s.col4}>Форма обучения по программе</th>
                        <th className={s.col5}>Предусмотрена реализация программы с применением дистанционных
                            технологий
                        </th>
                        <th className={s.col6}>Предусмотрена реализация программы полностью или частично в форме
                            стажировки
                        </th>
                        <th className={s.col7}>Срок освоения программы</th>
                        <th className={s.col8}>Организация, осуществляющая обучение специалистов органов опеки и
                            попечительства по
                            программе
                        </th>
                    </tr>
                    </tbody>
                </table>
                <details>
                    <summary>Дальневосточный федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Амурская область</td>
                            <td>Вопросы деятельности специалистов органов опеки и попечительства,
                                развитие семейных форм
                                устройства детей-сирот и детей, оставшихся без попечения родителей
                            </td>
                            <td>Программа обучения утверждена
                                генеральным директором

                                АНО ДПО «Амурский центр опережающей

                                профессиональной подготовки» Остапенко А.А. 28.05.2021
                            </td>
                            <td align="center">очная</td>
                            <td align="center">Нет</td>
                            <td align="center">Нет</td>
                            <td align="center">5 рабочих дней (48 часов)</td>
                            <td>АНО ДПО «Амурский центр опережающей
                                профессиональной подготовки» Федерального государственного бюджетного образовательного
                                учреждения высшего образования «Амурский государственный университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Амурская область</td>
                            <td>Профилактика жестокого обращения с детьми, в том числе проживающими в
                                замещающих семьях
                                и стационарных учреждениях
                            </td>
                            <td>Программа обучения утверждена ректором

                                Федерального государственного бюджетного образовательного учреждения

                                высшего образования

                                «Амурский государственный университет» Плутенко А.Д. 14.08.2023
                            </td>
                            <td align="center">очная</td>
                            <td align="center">Нет</td>
                            <td align="center">Нет</td>
                            <td align="center">5 рабочих дней (48 часов)</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Амурский государственный университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Забайкальский край</td>
                            <td>Содействие обеспечению и защите прав и законных интересов детей, их социальных и иных
                                государственных гарантий (деятельность специалистов органов опеки и попечительства)
                            </td>
                            <td>Утверждена Педагогическим советом Государственного учреждения «Центр
                                психолого-педагогической помощи населению «Доверие» Забайкальского края (далее - ГУ
                                ЦПППН «Доверие» Забайкальского края»), протокол №1 от 30 января 2020г.
                            </td>
                            <td align="center">очная, очно - заочная, заочная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">144 ч</td>
                            <td>ГУ ЦПППН «Доверие» Забайкальского края»</td>
                        </tr>
                        <tr>
                            <td align="center">Камчатский край</td>
                            <td>Нормативно-правовое сопровождение детей-сирот и детей, оставшихся без попечения
                                родителей
                            </td>
                            <td>Протокол от 5 декабря 2014г. №4</td>
                            <td align="center">очная с применением дистанционных образовательных технологий</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>Краевое государственное автономное учреждение дополнительного профессионального
                                образования «Камчатский институт развития образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Камчатский край</td>
                            <td>Современные подходы к организации и осуществлению деятельности школы приемных
                                родителей
                            </td>
                            <td>Приказ №09 от 18 февраля 2021г.</td>
                            <td align="center">очная с применением дистанционных образовательных технологий</td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">28 ч</td>
                            <td>Краевое государственное автономное учреждение «Камчатский ресурсный центр содействия
                                развитию семейных форм устройства»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Магаданская область</td>
                            <td>Актуальные вопросы защиты прав детей-сирот и детей, оставшихся без попечения родителей
                            </td>
                            <td> -</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">32 ч</td>
                            <td>ФГБОУ ВО Северо-Восточный государственный университет, г.Магадан</td>
                        </tr>
                        <tr>
                            <td align="center">Приморский край</td>
                            <td>Формы и методы работы с гражданами, выразившими желание стать опекунами или попечителями
                                либо принять детей, оставшихся без попечения родителей, в семью на воспитание
                            </td>
                            <td>-</td>
                            <td align="center">Заочная, с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">48 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования
                                «Учебный центр подготовки кадров для края» (ГАУ ДПО «УЦПК»)
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Бурятия</td>
                            <td>Подготовка и сопровождение семей, принявших на воспитание детей-сирот и детей,
                                оставшихся без попечения родителей
                            </td>
                            <td>Протокол заседания Научно-методического совета от 09.02.2021 № 1</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГАУДПО РБ «Бурятский республиканский институт образовательной политики»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Бурятия</td>
                            <td>Программа воспитания образовательной организации: проектирование и реализация</td>
                            <td>Протокол заседания Научно-методического совета от 09.02.2021 № 1</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГАУ ДПО РБ «Бурятский республиканский институт образовательной политики»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Бурятия</td>
                            <td>Психолого-педагогические аспекты работы с семьей</td>
                            <td>Протокол заседания Научно-методического совета от 27.12.2022 № 6</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГАУДПО РБ «Бурятский республиканский институт образовательной политики»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Бурятия</td>
                            <td>Социально-психологические аспекты профилактической работы с семьей</td>
                            <td>Протокол заседания Научно-методического совета от 09.02.2021 № 1</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГАУ ДПО РБ «Бурятский республиканский институт образовательной политики»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Саха (Якутия)</td>
                            <td>Половое воспитание детей и подростков</td>
                            <td>Приказ ГБУ РС(Я) «Республиканский центр содействия семейному воспитанию» от 12.09.2022г.
                                №106-од
                            </td>
                            <td align="center">дистанционное</td>
                            <td align="center">да</td>
                            <td align="center">Нет</td>
                            <td align="center">36 академических часов</td>
                            <td>ГБУ РС(Я) «Республиканский центр содействия семейному воспитанию»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Саха (Якутия)</td>
                            <td>Профилактика жестокого обращения с детьми</td>
                            <td>Приказ ГБУ РС(Я) «Республиканский центр содействия семейному воспитанию» от 14.02.2023г.
                                №22-од
                            </td>
                            <td align="center">дистанционное</td>
                            <td align="center">да</td>
                            <td align="center">Нет</td>
                            <td align="center">36 академических часов</td>
                            <td>ГБУ РС(Я) «Республиканский центр содействия семейному воспитанию»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Саха (Якутия)</td>
                            <td>Профилактика семейного неблагополучия и социального сиротства</td>
                            <td>Приказ ГБУ РС(Я) «Республиканский центр содействия семейному воспитанию» от 23.04.2020г.
                                №316-од
                            </td>
                            <td align="center">дистанционное</td>
                            <td align="center">да</td>
                            <td align="center">Нет</td>
                            <td align="center">36 академических часов</td>
                            <td>ГБУ РС(Я) «Республиканский центр содействия семейному воспитанию»</td>
                        </tr>
                        <tr>
                            <td align="center">Сахалинская область</td>
                            <td>Дополнительная профессиональная программа профессиональной переподготовки по направлению
                                «Специалист органа опеки и попечительства в отношении несовершеннолетних
                            </td>
                            <td>Протокол заседания Ученого совета ГБОУ ДПО «Институт развития образования Сахалинской
                                области» от 24 июня 2014г. №5
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">2 года 5 сессий</td>
                            <td>В настоящее время обучение не осуществляется</td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Приволжский федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Кировская область</td>
                            <td>Деятельность органов опеки и попечительства в сфере защиты прав несовершеннолетних</td>
                            <td>Решение Ученого совета №2 от 2 марта 2017г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>Кировское областное государственное образовательное автономное учреждение
                                дополнительного профессионального образования «Институт развития образования Кировской
                                области»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кировская область</td>
                            <td>Содержание и организация деятельности Службы по сопровождению замещающих семей</td>
                            <td>Решение Ученого совета №5 от 30 сентября 2020г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">40 ч</td>
                            <td>Кировское областное государственное образовательное автономное учреждение
                                дополнительного профессионального образования «Институт развития образования Кировской
                                области»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Нижегородская область</td>
                            <td>Психолого-педагогическое обеспечение и развитие компетенций специалистов органов опеки и
                                попечительства в отношении несовершеннолетних
                            </td>
                            <td>Решение кафедры теории и практики управления образованием, протокол №6 от 13.06.2022 г
                            </td>
                            <td align="center">Очно-дистанционная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">76 часов</td>
                            <td>Государственное бюджетное образовательное учреждение дополнительного профессионального
                                образования «Нижегородский институт развития ообразования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Оренбургская область</td>
                            <td>Правовое регулирование деятельности органов опеки и попечительства в Российской
                                Федерации
                            </td>
                            <td>ФГБОУ ВО «Оренбургский государственный университет»</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Оренбургский государственный университет» Межотраслевой региональный центр
                                повышения квалификации и профессиональной переподготовки специалистов
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Пензенская область</td>
                            <td>Психолог в социальной сфере и в сфере образования</td>
                            <td>Утверждена ректором ФГБОУВО
                                «ПГУ» Гуляковым А.Д. 20.01.2020
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">250 ч</td>
                            <td>МРЦПКиДО Института непрерывного образования ФГБОУ ВО «ПГУ»</td>
                        </tr>
                        <tr>
                            <td align="center">Пермский край</td>
                            <td>Курсы повышения квалификации специалистов органов опеки и попечительства</td>
                            <td>Приказ Министерства социального развития Пермского края от 9 ноября 2020г.
                                №СЭД-33-01-03/1-479
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГБУ ДО Пермского края «Центр психолого-педагогического и медико-социального
                                сопровождения»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Актуальные вопросы в деятельности опеки и попечительства</td>
                            <td>-</td>
                            <td align="center">очная с применением ЭО и ДОТ</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">36 часов</td>
                            <td>ГБОУ ВО «Башкирская академия государственной службы и управления при главе Республики
                                Башкортостан» (ГБОУ ВО «БАГСУ»)
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Логопедическая абилитация и реабилитация лиц с инвалидностью и ОВЗ в учреждениях
                                здравоохранения и социальной защиты населения
                            </td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 01.09. 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часов</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Логопедическая абилитация и реабилитация лиц с инвалидностью и ОВЗ в учреждениях
                                здравоохранения и социальной защиты населения
                            </td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 1 сентября 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Организация социально-психологической работы по профилактике социально опасных
                                зависимостей в молодежной среде
                            </td>
                            <td>Приказ от 28.04.2021 № 57</td>
                            <td align="center">очная</td>
                            <td align="center">Нет</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часов</td>
                            <td>Башкирский институт социальных технологий (филиал) Образовательного учреждения
                                профсоюзов высшего образования «Академия труда и социальных отношений»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Подготовка родителей к возврату ребенка из организации для детей-сирот и детей,
                                оставшихся без попечения родителей
                            </td>
                            <td>Программа повышения квалификации</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">полностью</td>
                            <td align="center">36 часов</td>
                            <td>ФГБОУ ВО «Башкирский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Правовое регулирование деятельности органов опеки и попечительства в Российской
                                Федерации
                            </td>
                            <td>-</td>
                            <td align="center">очная</td>
                            <td align="center">Нет</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часов</td>
                            <td>Башкирский институт социальных технологий (филиал) Образовательного учреждения
                                профсоюзов высшего образования «Академия труда и социальных отношений»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Современные технологии деятельности социального педагога</td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 01.09. 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">502 часов</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Современные технологии деятельности социального педагога</td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 1 сентября 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">502 ч</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Социальная работа в системе социальных служб</td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 01.09. 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">260 ч</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Социальная работа в системе социальных служб</td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 1 сентября 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">260 ч</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Социальная работа в системе социальных служб с присвоением квалификации «Специалист по
                                социальной работе
                            </td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 1 сентября 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">510 ч</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Башкортостан</td>
                            <td>Социальная работа в системе социальных служб с присвоением квалификации «Специалист по
                                социальной работе»
                            </td>
                            <td>Утверждено ректором ФГБОУ ВО «БГПУ им.М.Акмуллы» С.Т.Сагитовым 01.09. 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">510 ч</td>
                            <td>Институт педагогики БГПУ им.М.Акмуллы</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Марий Эл</td>
                            <td>Курсы повышения квалификации специалистов органов опеки и попечительства</td>
                            <td>Министерства образования и науки Республики Марий Эл от 7 ноября 2022 r.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение Республики Марий Эл «Центр
                                психолого-педагогической, медицинской и социальной помощи «Детство»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Мордовия</td>
                            <td>«Современные подходы к работе с кандидатами в приемные родители:
                                организационно-педагогическое и социально-педагогическое сопровождение приемных и
                                замещающих семей в современных условиях»
                            </td>
                            <td>Одобрена и рекомендована на заседании регионального учебно-методического объединения
                                (протокол №4 от 17.06.2022 г.)
                            </td>
                            <td align="center">очная</td>
                            <td align="center">С применением дистанционных образовательных технологий и электронного
                                обучения
                            </td>
                            <td align="center">нет</td>
                            <td align="center">8 дней</td>
                            <td>Государственное бюджетное учреждение дополнительного профессионального образования
                                Республики Мордовия «Центр непрерывного повышения профессионального мастерства
                                педагогических работников – «Педагог 13.ру»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Татарстан</td>
                            <td>Программа повышения квалификации муниципальных служащих Республики Татарстан «Опека и
                                попечительство»
                            </td>
                            <td>-</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Высшая Школа Государственного и Муниципального Управления Казанского (Приволжского)
                                Федерального Университета
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Самарская область</td>
                            <td>Специалист органов опеки и попечительства. Развитие профессиональных и личностных
                                качеств
                            </td>
                            <td>Программа составляется организацией, осуществляющей обучение, совместно с заказчиком и
                                утверждается перед началом обучения
                            </td>
                            <td align="center">Дистанционно</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">16 ч</td>
                            <td>Автономная некоммерческая образовательная организация дополнительного профессионального
                                образования «Центр развития талантов и трансформации управления при Губернаторе
                                Самарской области «Таволга»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Саратовская область</td>
                            <td>Организация деятельности специалистов органов опеки и попечительства</td>
                            <td>Утверждена ученым советом ГАУ ДПО «СОИРО» (протокол от 26.01.2023r. №1) и ректором ГАУ
                                ДПО «СОИРО» от 27.01.2023г. (утверждается ежегодно)
                            </td>
                            <td align="center">очно</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования
                                «Саратовский областной институт развития образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ульяновская область</td>
                            <td>Деятельность органов опеки и попечительства в отношении несовершеннолетних в условиях
                                введения профессионального стандарта специалиста
                            </td>
                            <td>Протокол заседания ученого совета ФГБОУ ВО «Ульяновский государственный педагогический
                                университет имени И.Н. Ульянова» от 27.03.2020 №7
                            </td>
                            <td align="center">Дистанционная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ФГБОУ ВО «Ульяновский государственный педагогический университет имени И.Н. Ульянова»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ульяновская область</td>
                            <td>Курс по программе Психология «Приёмный ребёнок в образовательном учреждении»</td>
                            <td>Лицензия Министерства просвещения и воспитания Ульяновской области №3491 от 21.09.2021
                            </td>
                            <td align="center">очно-дистанционная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">12 ч</td>
                            <td>Ассоциация приёмных семей и опекунов Ульяновской области</td>
                        </tr>
                        <tr>
                            <td align="center">Ульяновская область</td>
                            <td>Правовое регулирование и организация деятельности органов опеки и попечительства</td>
                            <td>Протокол заседания ученого совета ФГБОУ ВО «Ульяновский государственный педагогический
                                университет имени И.Н. Ульянова» от 25.10.2018 №2
                            </td>
                            <td align="center">Дистанционная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ФГБОУ ВО «Ульяновский государственный педагогический университет имени И.Н. Ульянова»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ульяновская область</td>
                            <td>Современные методы и технологии работы с семьей и детьми, направленные на профилактику
                                социального сиротства и неблагополучия
                            </td>
                            <td>Протокол заседания ученого совета ФГБОУ ВО «Ульяновский государственный педагогический
                                университет имени И.Н. Ульянова» от 25.10.2018 №2
                            </td>
                            <td align="center">Дистанционная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ФГБОУ ВО «Ульяновский государственный педагогический университет имени И.Н. Ульянова»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Чувашская Республика</td>
                            <td>Опека и попечительство в отношении несовершеннолетних (повышение квалификации)</td>
                            <td>Договор от 01.10.2021 № 15</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">Полностью</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное учреждение Чувашской Республики дополнительного
                                профессионального образования Учебно-методический центр «Аспект» Министерства труда и
                                социальной защиты Чувашской Республики
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Чувашская Республика</td>
                            <td>Программа дополнительного профессионального образования (повышение квалификации)
                                Социальное сопровождение принимающих семей
                            </td>
                            <td>приказ от 01 октября 2019г. №56-п</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">да</td>
                            <td align="center">18 ч</td>
                            <td>Бюджетное учреждение Чувашской Республики дополнительного профессионального образования
                                «Чувашский республиканский институт образования» Министерства образования и молодежной
                                политики Чувашской Республики
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Чувашская Республика</td>
                            <td>Программа оказания дополнительных образовательных услуг на тему «Организационные и
                                правовые основы деятельности специалистов органов опеки и попечительства»
                            </td>
                            <td>Приказ от 12.09.2019 № 71</td>
                            <td align="center">очно - заочная (с отрывом от службы)</td>
                            <td align="center">да</td>
                            <td align="center">Полностью</td>
                            <td align="center">72 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Российская академия народного хозяйства и
                                государственной службы при Президенте Российской Федерации» Чебоксарский филиал РАНХиГС
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Уральский федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Курганская область</td>
                            <td>Программа повышения квалификации специалистов органов опеки и попечительства.</td>
                            <td>Проходит утверждение</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">Стажировка не предусмотрена</td>
                            <td align="center">36 ч</td>
                            <td>ГАОУ ДПО «Институт развития образования и социальных технологий»</td>
                        </tr>
                        <tr>
                            <td align="center">Свердловская область</td>
                            <td>Медиация и переговорные навыки</td>
                            <td>Утверждено директором ГАУ ДПОСО «Региональный кадровый центр государственного и
                                муниципального управления» 15 марта 2021г.
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>ГАУ ДПО СО «Региональный кадровый центр государственного и муниципального управления»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Свердловская область</td>
                            <td>Организация работы органов опеки и попечительства</td>
                            <td>Протокол №1 от 18 января 2021г. Ученого совета ФГБОУ ВО «Уральский государственный
                                юридический университет»
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>ФГБОУ ВО «Уральский государственный юридический университет», ГАУ ДПО СО «Региональный
                                кадровый центр государственного и муниципального управления»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Тюменская область</td>
                            <td>Правовое регулирование органов опеки и попечительства в Российской Федерации</td>
                            <td>Договор №82/20-3 об оказании платных образовательных услуг по дополнительной
                                профессиональной программе повышения квалификации от 2 декабря 2020г.
                            </td>
                            <td align="center">заочная с применением дистанционных образовательных технологий</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">18 ч</td>
                            <td>Федеральное государственное автономное образовательное учреждение высшего образования
                                «Тюменский государственный университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ханты-Мансийский автономный округ</td>
                            <td>«Специалист органов опеки и попечительства. Развитие профессиональных и личностных
                                качеств»
                            </td>
                            <td>Программа составляется организацией, осуществляющей обучение, совместно с заказчиком и
                                утверждается перед началом обучения
                            </td>
                            <td align="center">Дистанционно</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">16 ч</td>
                            <td>Автономная некоммерческая образовательная организация дополнительного профессионального
                                образования «Центр развития талантов и трансформации управления при Губернаторе
                                Самарской области «Таволга»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Челябинская область</td>
                            <td>Организация подготовки, переподготовки и повышения квалификации работающих специалистов
                                с гражданами-кандидатами в приемные родители, опекуны (попечители), усыновители
                            </td>
                            <td>Постановление Правительства Челябинской области от 17 декабря 2020г. №2 689-П «О
                                государственной программе Челябинской области «Развитие социальной защиты населения в
                                Челябинской области» на 2021-2025 годы
                            </td>
                            <td align="center">очная, заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Ежегодно осуществляется закупка и заключается государственный контракт на поставку
                                товаров, выполнение работ, оказание услуг для государственных нужд Челябинской области в
                                соответствии с Федеральным законом от 5 апреля 2013 г. №44-ФЗ «О контрактной системе в
                                сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных
                                служб»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ямало-Ненецкий автономный округ</td>
                            <td>«Совершенствование деятельности организаций для детей-сирот и детей, оставшихся без
                                попечения родителей, по защите прав несовершеннолетних, предупреждению социального
                                сиротства, развитию семейных форм устройства детей-сирот»
                            </td>
                            <td>Приказ ГАУ ДПО ЯНАО «РИРО» от 30.12.2021 года №340</td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования ЯНАО
                                «Региональный институт развития образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ямало-Ненецкий автономный округ</td>
                            <td>«Сопровождение замещающих семей: нормативная база, методы и технологии работы с
                                семьёй»
                            </td>
                            <td>Приказ ГАУ ДПО ЯНАО «РИРО» от 30.12.2021 года №340</td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования ЯНАО
                                «Региональный институт развития образования»
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Северо-Западный федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Архангельская область</td>
                            <td>Организация и содержание обучения замещающих родителей</td>
                            <td>В рамках реализации государственной программы Архангельской области «Развитие
                                образования и науки Архангельской области», утвержденной постановлением Правительства
                                Архангельской области от 12 октября 2012 г. №463-пп
                            </td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>Государственное автономное образовательное учреждение дополнительного профессионального
                                образования Архангельской области «Архангельский областной институт открытого
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Архангельская область</td>
                            <td>Психолого-педагогические основы взаимодействия сотрудников государственных учреждений с
                                детьми, оставшимися без попечения родителей
                            </td>
                            <td>В рамках реализации государственной программы Архангельской области «Развитие
                                образования и науки Архангельской области», утвержденной постановлением Правительства
                                Архангельской области от 12 октября 2012 г. №463-пп
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">24 ч</td>
                            <td>Государственное автономное образовательное учреждение дополнительного профессионального
                                образования Архангельской области «Архангельский областной институт открытого
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Архангельская область</td>
                            <td>Развитие профессиональной компетентности специалистов
                                органов опеки
                                и попечительства
                                в отношении несовершеннолетних
                            </td>
                            <td>В рамках реализации государственной программы Архангельской области «Развитие
                                образования и науки Архангельской области», утвержденной постановлением Правительства
                                Архангельской области от 12 октября 2012 г. №463-пп
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное образовательное учреждение дополнительного профессионального
                                образования Архангельской области «Архангельский областной институт открытого
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Архангельская область</td>
                            <td>Сопровождение замещающей семьи
                                в условиях муниципального образования
                            </td>
                            <td>В рамках реализации государственной программы Архангельской области «Развитие
                                образования и науки Архангельской области», утвержденной постановлением Правительства
                                Архангельской области от 12 октября 2012 г. №463-пп
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">24 ч</td>
                            <td>Государственное автономное образовательное учреждение дополнительного профессионального
                                образования Архангельской области «Архангельский областной институт открытого
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Вологодская область</td>
                            <td>Организация и содержание деятельности специалиста органа опеки и попечительства</td>
                            <td>Утверждена проректором по образовательной деятельности</td>
                            <td align="center">очно-заочное</td>
                            <td align="center">предусмотрена</td>
                            <td align="center">не предусмотрена</td>
                            <td align="center">48 ч</td>
                            <td>Межотраслевой региональный центр повышения квалификации и переподготовки кадров ФГБОУ ВО
                                «Вологодский государственный университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Вологодская область</td>
                            <td>Профессиональная деятельность специалистов органов опеки и попечительства в отношении
                                несовершеннолетних
                            </td>
                            <td>Принята на заседании экспертного совета учреждения</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">предусмотрена
                            </td>
                            <td align="center">не предусмотрена
                            </td>
                            <td align="center">48 ч</td>
                            <td>АОУ ВО ДПО «Вологодский институт развития образования»</td>
                        </tr>
                        <tr>
                            <td align="center">Калининградская область</td>
                            <td>Выявление угроз жизни и здоровью детей, оценка риска жестокого обращения»</td>
                            <td>Протокол заседания Медико-педагогического Совета Государственном автономном учреждении
                                Калининградской области для обучающихся , нуждающихся в психолого-педагогической и
                                медико-социальной помощи «Центр диагностики и консультирования детей и подростков» № 01
                                от 16.01.2023
                            </td>
                            <td align="center">очно - заочная
                            </td>
                            <td align="center">Да</td>
                            <td align="center">Без стажировки
                            </td>
                            <td align="center">72 часа</td>
                            <td>Государственное автономное учреждение Калининградской области для обучающихся,
                                нуждающихся в психолого-педагогической и медико-социальной помощи «Центр диагностики и
                                консультирования детей и подростков»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ленинградская область</td>
                            <td>Организация деятельности по
                                опеке и попечительству
                            </td>
                            <td>Приказ АНО ДПО
                                «Инновационно- образовательный центр «Северная столица»
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Автономная некоммерческая организация
                                дополнительного профессионального образования
                                «Инновационно- образовательный центр «Северная
                                столица»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ленинградская область</td>
                            <td>Профилактика социального сиротства и
                                семейного неблагополучия. Современные формы работы с кризисной семьей
                            </td>
                            <td>Приказ АНО ДПО
                                «Инновационно- образовательный центр «Северная столица»
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Автономная некоммерческая организация
                                дополнительного профессионального образования
                                «Инновационно- образовательный центр «Северная
                                столица»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Мурманская область</td>
                            <td>Развитие профессиональных компетенций специалистов органов опеки и попечительства в
                                отношении несовершеннолетних
                            </td>
                            <td>Согласована на заседании Ученого совета ГАУДПО МО «ИРО» (протокол от 20 января 2021г.
                                №1); утверждена ректором ГАУДПО МО «ИРО» 20 января 2021г.
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное Автономное учреждение дополнительного профессионального образования
                                Мурманской области «Институт развития образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Новгородская область</td>
                            <td>Деятельность органов опеки и попечительства по обеспечению и защите прав и интересов
                                несовершеннолетних
                            </td>
                            <td>Утверждена ученым советом Новгородского филиала Федерального государственного бюджетного
                                образовательного учреждения высшего образования
                                «Российская академия народного хозяйства и государственной службы при Президенте
                                Российской от 10.12.2021
                            </td>
                            <td align="center">очно - заочная с применением дистанционных технологий</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">2 недели</td>
                            <td>Новгородский филиал Федерального государственного бюджетного образовательного учреждения
                                высшего образования
                                «Российская академия народного хозяйства и государственной службы при Президенте
                                Российской Федерации»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Псковская область</td>
                            <td>Особенности семьи с приемным ребенком и технологии работы с ними в образовательной
                                организации
                            </td>
                            <td>Утверждена решением ученого совета ГБОУ ДПО ПОИПКРО
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">48 ч</td>
                            <td>Государственное бюджетное учреждение дополнительного образования «Псковский областной
                                институт повышения квалификации работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Организационно-методическая поддержка и психолого-педагогическая поддержка замещающих
                                семей
                            </td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 11.05.2020г. № 27/а/ о/д
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Основы эффективного взаимодействия с различными категориями граждан</td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 27.03.2023 г. № 07-кпк
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Особенности подготовки и сопровождения замещающих семей со сложной категорией детей</td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 19.09.2022 г. № 19-кпк
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">25 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Особенности подготовки родителей, желающих принять детей-сирот «сложно» категории, а
                                также сопровождение замещающих семей с приемными детьми данной категории
                            </td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 15.08.2021 №10-КпК
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Особенности подготовки родителей, желающих принять детей-сирот «сложной» категории, а
                                также сопровождение замещающих семей с приемными детьми данной категории
                            </td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 15.08.2021 г.
                                №10-кпк
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Подготовка и сопровождение семей,
                                желающих принять на воспитание детей-сирот и детей, оставшихся без попечения родителей
                            </td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 01.04.2022 г. № 09-кпк
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Специалист органа опеки и попечительства</td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 12.11.2020 № 54.1-о/д
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Карелия</td>
                            <td>Специалист органа опеки и попечительства</td>
                            <td>Приказ директора Государственного бюджетного учреждения Республики Карелия «Карельский
                                ресурсный центр развития социальных технологий» от 12.11.2020г. № 54.1-о/д
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение Республики Карелия «Карельский ресурсный центр
                                развития социальных технологий»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Коми</td>
                            <td>«Актуальные вопросы правового регулирования в сфере опеки и попечительства»</td>
                            <td>Решение Ученого совета ГОУ ВО «Коми республиканская академия государственной службы и
                                управления» № 11 от 10.06.2021
                            </td>
                            <td align="center">очная, в том числе с применением дистанционных образовательных
                                технологий
                            </td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>ГОУ ВО «Коми республиканская академия государственной службы и управления»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Коми</td>
                            <td>«Требования к деятельности специалистов органов опеки и попечительства на современном
                                этапе. Правовое регулирование в сфере опеки и попечительства»
                            </td>
                            <td>Решение Ученого совета ГОУ ВО «Коми республиканская академия государственной службы и
                                управления» № 11 от 10.06.2021
                            </td>
                            <td align="center">очная, с отрывом от профессиональной служебной деятельности</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГОУ ВО «Коми республиканская академия государственной службы и управления»</td>
                        </tr>
                        <tr>
                            <td align="center">Санкт-Петербург</td>
                            <td>Организация деятельности по опеке и попечительству</td>
                            <td>Приказ ООО «Инновационно-образовательный центр
                                «Северная столица»
                                от 09.01.2023 № 01/ОД
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ООО «Инновационно-образовательный центр «Северная столица»</td>
                        </tr>
                        <tr>
                            <td align="center">Санкт-Петербург</td>
                            <td>Профилактика социального сиротства и семейного неблагополучия. Современные формы работы
                                с кризисной семьей
                            </td>
                            <td>Приказ ООО «Инновационно-образовательный центр
                                «Северная столица»
                                от 09.01.2023 № 01/ОД
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ООО «Инновационно-образовательный центр «Северная столица»</td>
                        </tr>
                        <tr>
                            <td align="center">Санкт-Петербург</td>
                            <td>Система работы по подготовке граждан, желающих принять
                                в свою семью детей-сирот и детей, оставшихся без попечения родителей
                            </td>
                            <td>График проведения программ дополнительного профессионального образования
                                от 25.04.2023
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Санкт-Петербургское государственное автономное образовательное учреждение высшего
                                образования «Санкт-Петербургский государственный институт психологии и социальной
                                работы»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Санкт-Петербург</td>
                            <td>Специалист органа опеки
                                и попечительства
                                в отношении несовершеннолетних
                            </td>
                            <td>Приказ ООО «Инновационно-образовательный центр
                                «Северная столица»
                                от 09.01.2023 № 01/ОД
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ООО «Инновационно-образовательный центр «Северная столица»</td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Северо-Кавказский федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Карачаево-Черкесской Республике</td>
                            <td>Организация и содержание работы специалистов органов опеки и попечительства в отношении
                                несовершеннолетних граждан в Российской Федерации
                            </td>
                            <td>Решение Ученого совета РГБУ ДПО «КЧРИПКРО» от 18 декабря 2019г., протокол №40</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>РГБУ ДПО «Карачаево-Черкесский Республиканский институт повышения квалификации
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Дагестан</td>
                            <td>«Профилактика безнадзорности и социального сиротства несовершеннолетних»</td>
                            <td>Утверждено на заседании УМС ГБУ ДПО РД «ДИРО» от 24.06.2022 г. Протокол №4</td>
                            <td align="center">Очная</td>
                            <td align="center">Не предусмотрена</td>
                            <td align="center">Полностью</td>
                            <td align="center">36 часов</td>
                            <td>ГБУ ДПО РД «Дагестанский институт развития образования»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Северная Осетия-Алания</td>
                            <td>Комплексные меры по профилактике аддиктивного и асоциального поведения, незаконного
                                употребления психоактивных и наркотических средств несовершеннолетними
                            </td>
                            <td>Приказ от 12 апреля 2021г. №82</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГБОУ ДПО «Северо-Осетинский республиканский институт повышения квалификации работников
                                образован»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Северная Осетия-Алания</td>
                            <td>Курсы повышения квалификации по дополнительным профессиональным программам социальных
                                педагогов организаций, осуществляющих образовательную деятельность, Центров содействия
                                семейному воспитанию, Республиканского центра социальной реабилитации несовершеннолетних
                                «Доброе сердце», организаций социальной защиты населения, специалистов территориальных
                                органов опеки и попечительства, специалистов служб сопровождения замещающих семей и
                                постинтернатного сопровождения выпускников организаций для детей-сирот и детей,
                                оставшихся без попечения родителей
                            </td>
                            <td>Соглашение о сотрудничестве от 1 марта 2021 года между Министерством труда и социального
                                развития Республики Северная Осетия-Алания и ГБОУ ДПО «Северо-Осетинский республиканский
                                институт повышения квалификации работников образования»
                            </td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ГБОУ ДПО «Северо-Осетинский республиканский институт повышения квалификации работников
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Северная Осетия-Алания</td>
                            <td>Курсы повышения квалификации по дополнительным профессиональным программам социальных
                                педагогов организаций, осуществляющих образовательную деятельность, Центров содействия
                                семейному воспитанию, Республиканского центра социальной реабилитации несовершеннолетних
                                «Доброе сердце», организаций социальной защиты населения, специалистов территориальных
                                органов опеки и попечительства, специалистов служб сопровождения замещающих семей и
                                постинтернатного сопровождения выпускников организаций для детей-сирот и детей,
                                оставшихся без попечения родителей
                            </td>
                            <td>Соглашение о сотрудничестве от 1 марта 2021 года между Министерством труда и социального
                                развития Республики Северная Осетия-Алания и ГБОУ ДПО «Северо-Осетинский республиканский
                                институт повышения квалификации работников образования»
                            </td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ГБОУ ДПО «Северо-Осетинский республиканский институт повышения квалификации работников
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ставропольский край</td>
                            <td>Технологии организации постинтернатного сопровождения выпускников организаций для
                                детей-сирот и детей, оставшихся без попечения родителей, в том числе с ОВЗ, а также
                                замещающих семей. Создание условий для их социальной адаптации и профессионального
                                самоопределения
                            </td>
                            <td>Протокол № 1 заседания кафедры коррекционной психологии и психофизиологии ГБОУ ВО
                                «Ставропольский государственный педагогический институт» от 01.09.2023г.
                            </td>
                            <td align="center">Очная с применением дистанционных образовательных технологий</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">32 ч</td>
                            <td>Государственное бюджетное образовательное учреждение высшего образования «Ставропольский
                                государственный педагогический институт»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Чеченская Республика</td>
                            <td>Деятельность органов опеки и попечительства в отношении несовершеннолетних</td>
                            <td>Утверждено проректором по учебной работе (дата утверждения 01.12.2021)</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Чеченский государственный университет имени А.А.Кадырова»</td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Сибирский федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Алтайский край</td>
                            <td>Жестокое обращение с детьми в замещающих семьях: профилактика, выявление, работа со
                                случаем
                            </td>
                            <td>Приказ Министерства образования и науки Алтайского края от 31.01.2023 №79</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>КАУ ДПО «Алтайский институт развития образования им.А.М.Топорова»</td>
                        </tr>
                        <tr>
                            <td align="center">Алтайский край</td>
                            <td>Опека и попечительство</td>
                            <td>Программа утверждается образовательной организацией после объявления ее победителем по
                                итогам конкурсных процедур
                            </td>
                            <td align="center">очно</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>Определяется по результатам конкурсных процедур в соответствии с Федеральным законом от
                                05.04.2013 N 44-ФЗ "О контрактной системе в сфере закупок товаров, робот, услуг, для
                                обеспечения государственных и муниципальных нужд".
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Иркутская область</td>
                            <td>Об организации деятельности отделов опеки и попечительства в области охраны прав и
                                законных интересов несовершеннолетних
                            </td>
                            <td>Государственный контракт на оказание образовательных услуг по дополнительному
                                профессиональному образованию государственных гражданских служащих Иркутской области в
                                исполнительных органах государственной власти Иркутской области №
                                01342000001220065940001 от 06.02.2023
                            </td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">40 ч</td>
                            <td>Иркутский институт (филиал) федерального государственного бюджетного образовательного
                                учреждения высшего образования «Всероссийский государственный университет юстиции (РПА
                                Минюста России)»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>Дополнительная профессиональная программа повышения квалификации «Организация и
                                содержание деятельности специалистов по охране прав и законных интересов
                                несовершеннолетних»
                            </td>
                            <td>Приказ департамента образования и науки Кемеровской области от 23 декабря 2019г. №2485
                            </td>
                            <td align="center">очная, заочная, очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>Дополнительная профессиональная программа повышения квалификации «Организация
                                психолого-педагогического и социального сопровождения замещающих семей»
                            </td>
                            <td>Приказ департамента образования и науки Кемеровской области от 23 декабря 2019г. №2485
                            </td>
                            <td align="center">очная, заочная, очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>Дополнительная профессиональная программа профессиональной переподготовки «Организация и
                                содержание деятельности специалиста органов опеки и попечительства в отношении
                                несовершеннолетних»
                            </td>
                            <td>Приказ департамента образования и науки Кемеровской области от 23 декабря 2019г. №2485
                            </td>
                            <td align="center">очная, заочная, очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Актуальные проблемы психолого-педагогической и социальной поддержки развития и
                                социализации детей-сирот и детей, оставшихся без попечения родителей»
                            </td>
                            <td>Протокол заседания УМС № 1 от 09.09.2021 г.</td>
                            <td align="center">Очная, заочная, очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">120 часов</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Защита прав детей»</td>
                            <td>Протокол заседания УМС № 1 от 09.09.2021 г.</td>
                            <td align="center">Очная, заочная, очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">16 часов</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Организация и осуществление деятельности по подготовке лиц, желающих принять на
                                воспитание в свою семью ребенка, оставшегося без попечения родителей»
                            </td>
                            <td>Приказ ГОО «Кузбасский РЦППМС от 23.06.2022 №35</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">120 часов</td>
                            <td>ГОО Кузбасский региональный центр психолого-педагогической, медицинской и социальной
                                помощи «Здоровье и развитие личности»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Организация и содержание деятельности специалистов по охране прав и законных
                                интересов несовершеннолетних»
                            </td>
                            <td>Протокол заседания УМС № 1 от 09.09.2021 г.</td>
                            <td align="center">Очная, заочная, очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Организация психолого-педагогического и социального сопроворждения»</td>
                            <td>Протокол заседания УМС № 1 от 09.09.2021 г.</td>
                            <td align="center">Очная, заочная, очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ГОУ ДПО (ПК) С «Кузбасский региональный институт повышения квалификации и переподготовки
                                работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Подготовка специалиста службы примирения в системе образования» (модуль 1.
                                Восстановительная медиация и создание службы примирения. 2. Восстановительная технология
                                «Семейная групповая конференция»)
                            </td>
                            <td>Приказ ГОО «Кузбасский РЦППМС от 23.06.2022 №35</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ГОО Кузбасский региональный центр психолого-педагогической, медицинской и социальной
                                помощи «Здоровье и развитие личности»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Кемеровская область</td>
                            <td>ДПП «Профилактика и раннее выявление фактов жестокого обращения с детьми в замещающих
                                семьях»
                            </td>
                            <td>Приказ ГОО «Кузбасский РЦППМС от 23.06.2022 №35</td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ГОО Кузбасский региональный центр психолого-педагогической, медицинской и социальной
                                помощи «Здоровье и развитие личности»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Красноярский край</td>
                            <td>Деятельность специалистов органов опеки и
                                попечительства в системе защиты прав детей и профилактики социального сиротства
                            </td>
                            <td>Приказ ректора КК ИПК от 29.09.2015
                                № 112
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Краевое государственное автономное
                                учреждение дополнительного профессионального образования «Красноярский краевой институт
                                повышения квалификации и профессиональной переподготовки работников образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Красноярский край</td>
                            <td>Сопровождение детей и семей, находящихся
                                в трудной жизненной ситуации
                            </td>
                            <td>Приказ ректора ФГБОУ «Красноярский государственный педагогический университет им. В.П.
                                Астафьева»
                                от 02.6.2020
                                № 234 (п)
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Красноярский государственный педагогический университет им.
                                В.П. Астафьева»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Красноярский край</td>
                            <td>Специалист органа опеки
                                и попечительства в отношении несовершенно
                                летних
                            </td>
                            <td>Приказ ректора ФГБОУ «Красноярский государствен
                                ный педагогический университет им. В.П. Астафьева»
                                от 17.06.2019 № 409 (п)
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">504 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Красноярский государственный педагогический университет им.
                                В.П. Астафьева»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Красноярский край</td>
                            <td>Специалист по опеке и попечительству: соответствие профессиональному стандарту</td>
                            <td>Программа утверждается образовательной организацией после объявления ее победителем по
                                итогам конкурсных процедур
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">280 ч</td>
                            <td>Федеральное государственное автономное образовательное учреждение высшего образования

                                «Сибирский федеральный университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Красноярский край</td>
                            <td>Теория и практика работы с семьей группы риска</td>
                            <td>Приказ ректора ФГБОУ «Красноярский государственный педагогический университет им. В.П.
                                Астафьева» от 01.12.2020 № 625 (п)
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">16 часов</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Красноярский государственный педагогический университет им. В.П. Астафьева»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Новосибирская область</td>
                            <td>Базовые компетенции специалистов, работающих в сфере жизнеустройства детей-сирот и
                                детей, оставшихся без попечения родителей
                            </td>
                            <td>Лицензия №11796 БЕССРОЧНАЯ от 20 февраля 2021 года. Вид образования - дополнительное
                                образование, подвид образования – дополнительное профессиональное образование.
                                Организация, выдавшая лицензию: МИНИСТЕРСТВО ОБРАЗОВАНИЯ НОВОСИБИРСКОЙ ОБЛАСТИ. Приказ №
                                8 АНО ДПО Обучающий центр «Солнечный город» об утверждении программы от 01 октября 2020
                                года
                            </td>
                            <td align="center">Очная, с отрывом от производства; дистанционная, без отрыва от
                                производства
                            </td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">72 ч</td>
                            <td>Автономная некоммерческая организация дополнительного профессионального образования
                                «Обучающий центр подготовки специалистов помощи детям «Солнечный город»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Новосибирская область</td>
                            <td>Дополнительная профессиональная программа повышения квалификации руководителей
                                организаций социального обслуживания и органов опеки и попечительства «Управление
                                персоналом в социальной сфере»
                            </td>
                            <td>Программа принята советом учредителей АНО ДПО «Учебный центр новых социальных
                                технологий, протокол № 6 от 12 ноября 2018г.; утверждена директором АНО ДПО УЦНСТ,
                                приказ № 11 от 20 ноября 2018г.
                            </td>
                            <td align="center">дистанционная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">40 ч</td>
                            <td>Автономная некоммерческая организация дополнительного профессионального образования
                                «Учебный центр новых социальных технологий» (лицензии №10837, выдана Министерством
                                образования Новосибирской области)
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Тыва</td>
                            <td>«Нормативно-правовое, методическое и психолого-педагогическое обеспечение деятельности в
                                сфере защиты прав детей-сирот и детей, оставшихся без попечения родителей»
                            </td>
                            <td>Протокол № 2 от 01.09.2021 г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">Полностью</td>
                            <td align="center">72 ч</td>
                            <td>ГАОУ ДПО «Тувинский институт развития образования и повышения квалификации»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Хакасия</td>
                            <td>ДПП ПК «Защита прав детей-сирот и детей, лишенных родительского попечительства,
                                находящихся на воспитании в семьях граждан»
                            </td>
                            <td>Программа согласована на заседании Педагогического совета и утверждена ректором
                                «ХакИРОиПК» (Протокол заседания ПС ГАОУ РХ ДПО «ХакИРОиПК» № 2 от 26.05.2022г.)
                            </td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное образовательное учреждение Республики Хакасия дополнительного
                                профессионального образования «Хакасский институт развития образования и повышения
                                квалификации»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Республика Хакасия</td>
                            <td>ДПП ПК «Психолого-педагогическое сопровождение замещающих семей»</td>
                            <td>Программа согласована на заседании Педагогического совета и утверждена ректором
                                «ХакИРОиПК» (Протокол заседания ПС ГАОУ РХ ДПО «ХакИРОиПК» № 4 от 22.12.2022г.)
                            </td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное автономное образовательное учреждение Республики Хакасия дополнительного
                                профессионального образования «Хакасский институт развития образования и повышения
                                квалификации»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Томская область</td>
                            <td>Профессиональная программа повышения квалификации для работников органов опеки и
                                попечительства
                            </td>
                            <td>Региональная дополнительная профессиональная программа повышения квалификации для
                                специалистов органов опеки и попечительства разработана в соответствии с приказом
                                Минобрнауки России от 24 февраля 2015 г. №121 «Об утверждении примерной дополнительной
                                профессиональной программы повышения квалификации для работников органов опеки и
                                попечительства» (реквизиты отсутствуют)
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">40 ч</td>
                            <td>ОГБУ «Томский региональный ресурсный центр», Национальный Исследовательский Томский
                                Государственный Университет, Ресурсный центр ОГБУ «Центр социальной помощи семье и детям
                                «Огонек» г.Томск»
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Центральный федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Белгородская область</td>
                            <td>Комплексное обеспечение социальной реабилитации и абилитации воспитанников
                                социально-реабилитационного центра
                            </td>
                            <td>Приказ ОГАОУ ДПО "БелИРО" от 21.03.2023 №279-ОД "О решениях Ученого совета"</td>
                            <td align="center">заочная с применением ДОТ</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">54 ч</td>
                            <td>Областное государственное автономное образовательное учреждение дополнительного
                                профессионального образования "Белгородский институт развития образования"
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Белгородская область</td>
                            <td>Проектирование и реализация программ социально-педагогической поддержки обучающихся</td>
                            <td>Приказ ОГАОУ ДПО "БелИРО" от 28.02.2023 №170-ОД "О решениях Ученого совета"</td>
                            <td align="center">очная, заочная с применением ДОТ</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Областное государственное автономное образовательное учреждение дополнительного
                                профессионального образования "Белгородский институт развития образования"
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Белгородская область</td>
                            <td>Технология социокультурной адаптации воспитанников учреждений интернатного типа</td>
                            <td>Приказ ОГАОУ ДПО "БелИРО" от 28.02.2023 №170-ОД "О решениях Ученого совета"</td>
                            <td align="center">заочная с применением ДОТ</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Областное государственное автономное образовательное учреждение дополнительного
                                профессионального образования "Белгородский институт развития образования"
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Брянская область</td>
                            <td>Организация деятельности специалистов по опеке и попечительству</td>
                            <td>Утверждена проректором по учебной работе ФГБОУ ВО «Брянский государственный университет
                                имени академика И.Г.Петровского» Л.Ю.Лупоядовой
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>Факультет дополнительного образования ФГБОУ ВО «Брянский государственный университета
                                имени академика И.Г.Петровского»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Брянская область</td>
                            <td>Оценка рисков жестокого обращения с
                                детьми, нанесения им существенного вреда, рисков
                                утраты родительского попечения»
                            </td>
                            <td>Утверждена проректором по учебной работе ФГБОУ ВО «Брянский государственный университет
                                имени академика И.Г.Петровского» Л.Ю.Лупоядовой
                            </td>
                            <td align="center">дистанционная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">16 ч</td>
                            <td>Факультет дополнительного образования ФГБОУ ВО «Брянский государственный университета
                                имени академика И.Г.Петровского»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Владимирская область</td>
                            <td>Повышение профессиональной компетенции работников органов опеки и попечительства,
                                осуществляющих содействие обеспечению и защите прав и законных интересов детей, их
                                социальных и иных государственных гарантий
                            </td>
                            <td>Протокол заседания кафедры педагогического менеджмента № 1 от 15.01.2018</td>
                            <td align="center">очно, с отрывом от работы; заочно</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 часа, 6-8 ч. в день</td>
                            <td>Государственное автономное образовательное учреждение дополнительного профессионального
                                образования Владимирской области «Владимирский институт развития образования имени Л.И.
                                Новиковой»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Воронежская область</td>
                            <td>О мерах по усилению защиты прав детей-сирот и детей, оставшихся без попечения родителей,
                                находящихся на воспитании в замещающих семьях
                            </td>
                            <td>Образователь-ная программа утверждена приказом
                                № 05-УМР
                                от 01.02.2023
                            </td>
                            <td align="center">Очно -заочная</td>
                            <td align="center">Применяются дистанционные технологии</td>
                            <td align="center">нет</td>
                            <td align="center">108 академических часов</td>
                            <td>АНОДПО «Академия непрерывного образования»</td>
                        </tr>
                        <tr>
                            <td align="center">Ивановская область</td>
                            <td>«Государственная политика в области опеки и попечительства: профилактика социального
                                сиротства, конфликты интересов и урегулирование споров»
                            </td>
                            <td>Приказ от 21.06.2023 «Об утверждении учебного плана по дополнительной профессиональной
                                программе повышения квалификации «Государственная политика в области опеки и
                                попечительства: профилактика социального сиротства, конфликты интересов и урегулирование
                                споров»
                            </td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Автономная некоммерческая организация дополнительного профессионального образования
                                «Научно-исследовательский институт менеджмента, экономики, статистики, информатики» (АНО
                                ДПО «НИИ МЭСИ»)
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Калужская область</td>
                            <td>Медиативный подход и восстановительные практики в деятельности органов опеки и
                                попечительства
                            </td>
                            <td>Утверждена 01.06.2023 г. первым проректором КГУ им. К,Э, Циолковского</td>
                            <td align="center">очная, очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">108 ч</td>
                            <td>ФГБОУ ВО «Калужский государственный университет им. К.Э. Циолковского</td>
                        </tr>
                        <tr>
                            <td align="center">Калужская область</td>
                            <td>Нормативно-правовое и методическое обеспечение деятельности органов опеки и
                                попечительства
                            </td>
                            <td>Утверждена 16.09.2019 г. первым проректором КГУ им. К,Э, Циолковского</td>
                            <td align="center">очная, очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Калужский государственный университет им. К.Э. Циолковского</td>
                        </tr>
                        <tr>
                            <td align="center">Калужская область</td>
                            <td>Технологии профилактики и коррекции деструктивного поведения детей и молодежи в
                                деятельности органов опек и попечительства
                            </td>
                            <td>Утверждена 01.06.2023г. первым проректором КГУ им. К,Э, Циолковского</td>
                            <td align="center">очная, очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">108 ч</td>
                            <td>ФГБОУ ВО «Калужский государственный университет им. К.Э. Циолковского</td>
                        </tr>
                        <tr>
                            <td align="center">Костромская область</td>
                            <td>Государственная и региональная политика в сфере социальной и психологической поддержки
                                семьи
                            </td>
                            <td>Программа утверждаются после согласования с заказчиком перед началом обучения</td>
                            <td align="center">очно - заочная с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">-</td>
                            <td align="center">36 часов</td>
                            <td>ОГБОУ ДПО «КОИРО»</td>
                        </tr>
                        <tr>
                            <td align="center">Костромская область</td>
                            <td>Психология семейных отношений</td>
                            <td>Программа утверждаются после согласования с заказчиком перед началом обучения</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Нет</td>
                            <td align="center">-</td>
                            <td align="center">72 часов</td>
                            <td>ФГБОУ ВО «Костромской государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Костромская область</td>
                            <td>Социальная работа: обеспечение реализации социальных услуг и мер социальной поддержки
                                населения
                            </td>
                            <td>Программа утверждаются после согласования с заказчиком перед началом обучения</td>
                            <td align="center">Заочная с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">-</td>
                            <td align="center">200 часов</td>
                            <td>ФГБОУ ВО «Костромской государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Костромская область</td>
                            <td>Социально-психолого- педагогические основы профессиональной деятельности специалиста
                                органов опеки и попечительства
                            </td>
                            <td>Приказ ФГБОУ
                                во
                                «Костромской
                                государственный университет от
                                17.07.2023
                                №7-ДОП
                            </td>
                            <td align="center">заочная с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">-</td>
                            <td align="center">72 часов</td>
                            <td>ФГБОУ ВО «Костромской государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Инновационные технологии комплексной реабилитации детей с ограниченными возможностями
                                здоровья и инвалидностью
                            </td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 10 от 01.06.2022
                                г. Одобрена на заседании Совета непрерывного образования ФГБОУ ВО «Курский
                                государственный университет»
                            </td>
                            <td align="center">Очная, с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">120 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Инновационные технологии комплексной реабилитации инвалидов</td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 10 т 01.06.2022
                                г. Одобрена на заседании Совета непрерывного образования ФГБОУ ВО
                                «Курский государственный университет», протокол № 3 от 03.06.2022 г.
                            </td>
                            <td align="center">очно - заочная, с применением дистанционных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">120 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Менеджмент в социальной сфере</td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 3
                                от 02.11.2020 г. Одобрена на заседании Совета непрерывного образования ФГБОУ ВО «Курский
                                государственный университет», протокол № 1 от 05.11.2020 г.
                            </td>
                            <td align="center">Очная, с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Нормативное правовое обеспечение деятельности в сфере защиты прав детей-сирот и детей,
                                оставшихся без попечения родителей
                            </td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 3 от 02.11.2020
                                г.Одобрена на заседании Совета непрерывного образования ФГБОУ ВО «Курский
                                государственный университет», протокол № 1 от 05.11.2020 г.
                            </td>
                            <td align="center">очно - заочная, с применением дистанционных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Профессиональные основы деятельности специалистов органов опеки и попечительства</td>
                            <td>Утверждена Администрацией Курской области. Календарный план повышения квалификации
                                муниципальных служащих органов местного самоуправления муниципальных районов и городских
                                округов Курской области в 2022 году. Приказ Академии госслужбы
                                №01 от 10 января 2022 года
                            </td>
                            <td align="center">Очная, с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">24 ч</td>
                            <td>Государственное образовательное автономное учреждение высшего образования Курской
                                области «Курская академия государственной и муниципальной службы» (Академия госслужбы)
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Социально-психологическое сопровождение семей с детьми, находящихся в трудной жизненной
                                ситуации
                            </td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 3 от 02.11.2020
                                г. Одобрена на заседании Совета непрерывного образования ФГБОУ ВО «Курский
                                государственный университет», протокол № 1 от 05.11.2020 г.
                            </td>
                            <td align="center">Очная, с применением дистанционных образовательных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Технология оказания государственной социальной помощи на основе социального контракта
                            </td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 3 от 02.11.2020
                                г. Одобрена на заседании Совета непрерывного образования ФГБОУ ВО «Курский
                                государственный университет»,
                                протокол № 1 от 05.11.2020 г.
                            </td>
                            <td align="center">очно - заочная, с применением дистанционных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Курская область</td>
                            <td>Управление персоналом в сфере социального обслуживания населения</td>
                            <td>Обсуждена и рекомендована на заседании кафедры педагогики и профессионального
                                образования ФГБОУ ВО «Курский государственный университет», протокол № 3 от 02.11.2020
                                г. Одобрена на заседании Совета непрерывного образования ФГБОУ ВО «Курский
                                государственный университет», протокол № 1
                            </td>
                            <td align="center">очно - заочная, с применением дистанционных технологий</td>
                            <td align="center">Да</td>
                            <td align="center">Да</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Курский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Липецкая область</td>
                            <td>Организационно-методическое сопровождение деятельности по устройству детей, оставшихся
                                без попечения родителей
                            </td>
                            <td>Утверждена на заседании Ученого совета, протокол №5 от 29 декабря 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">144 ч</td>
                            <td>ГАУДПО ДО «ИРО»</td>
                        </tr>
                        <tr>
                            <td align="center">Липецкая область</td>
                            <td>Организация и содержание деятельности работников органов опеки и попечительства в
                                отношении несовершеннолетних граждан
                            </td>
                            <td>Утверждена на заседании Ученого совета, протокол №4 от 15 мая 2020г.</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">72 ч</td>
                            <td>ГАУДПО ДО «ИРО»</td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Базовый курс для специалистов, осуществляющих сопровождение замещающих семей</td>
                            <td>Приказ ГБУ Центра «Содействие» от 15.06.23 №287</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">56 ч</td>
                            <td>Государственное бюджетное учреждение города Москвы Ресурсный центр по вопросам опеки и
                                попечительства «Содействие» Департамента труда и социальной защиты населения города
                                Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Медиативные технологии в работе специалиста опеки и попечительства</td>
                            <td>Утверждена 09 марта 2023г. ГАУ города Москвы
                                «Институт дополнительного профессионального образования работников социальной сферы»
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>ГАУ города Москвы «Институт дополнительного профессионального образования работников
                                социальной сферы» Департамента труда и социальной защиты населения города Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Организация сопровождения замещающих семей</td>
                            <td>Утверждена 25 марта 2021г. ГАУ города Москвы
                                «Институт дополнительного профессионального образования работников социальной сферы»
                            </td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">24 ч</td>
                            <td>ГАУ города Москвы «Институт дополнительного профессионального образования работников
                                социальной сферы» Департамента труда и социальной защиты населения города Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Основы психологической коррекции детей, переживших психотравму, находящихся в
                                учреждениях социальной защиты и замещающих семьях
                            </td>
                            <td>Приказ ГБУ Центра «Содействие» от 15.06.23 №287</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение города Москвы Ресурсный центр по вопросам опеки и
                                попечительства «Содействие» Департамента труда и социальной защиты населения города
                                Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Работа органов опеки и попечительства по защите прав несовершеннолетних</td>
                            <td>Приказ ГБУ Центра «Содействие» от 15.06.23 №287ы»</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение города Москвы Ресурсный центр по вопросам опеки и
                                попечительства «Содействие» Департамента труда и социальной защиты населения города
                                Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Реабилитационная работа с несовершеннолетними, пережившими горе и утрату в детском и
                                подростковом возрасте
                            </td>
                            <td>Приказ ГБУ Центра «Содействие» от 15.06.23 №287</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">56 ч</td>
                            <td>Государственное бюджетное учреждение города Москвы Ресурсный центр по вопросам опеки и
                                попечительства «Содействие» Департамента труда и социальной защиты населения города
                                Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Москва</td>
                            <td>Эффективные коммуникации специалиста органов опеки и попечительства</td>
                            <td>Утверждена 14 января 2022г. ГА У города Москвы
                                «Институт дополнительного профессионального образования работников социальной сферы»
                            </td>
                            <td align="center">Очная</td>
                            <td align="center">нет</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>ГАУ города Москвы «Институт дополнительного профессионального образования работников
                                социальной сферы» Департамента труда и социальной защиты населения города Москвы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Московская область</td>
                            <td>«Выявление угроз жизни и здоровью детей, оценка риска жестокого обращения с ними»</td>
                            <td>Приказ ЧОУ ДПО «Национальный институт защиты детства» от 03.02.22 №01-21/Л</td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">24 часа</td>
                            <td>ЧОУ ДПО «Национальный институт защиты детства»</td>
                        </tr>
                        <tr>
                            <td align="center">Московская область</td>
                            <td>«Жестокое обращение с ребёнком. Что нужно знать специалисту, работающему с семьей и
                                детьми»
                            </td>
                            <td>Приказ ЧОУ ДПО «Национальный институт защиты детства» от 03.02.22 №01-21/Л</td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">24 часа</td>
                            <td>ЧОУ ДПО «Национальный институт защиты детства»</td>
                        </tr>
                        <tr>
                            <td align="center">Московская область</td>
                            <td>«Профилактика социального сиротства»</td>
                            <td>Приказ ЧОУ ДПО «Национальный институт защиты детства» от 03.02.22 №01-21/Л</td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 часа</td>
                            <td>ЧОУ ДПО «Национальный институт защиты детства»</td>
                        </tr>
                        <tr>
                            <td align="center">Московская область</td>
                            <td>«Семейная система: что нужно знать о семье, чтобы помогать качественно»</td>
                            <td>Приказ ЧОУ ДПО «Национальный институт защиты детства» от 03.02.22 №01-21/Л</td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">16 часов</td>
                            <td>ЧОУ ДПО «Национальный институт защиты детства»</td>
                        </tr>
                        <tr>
                            <td align="center">Московская область</td>
                            <td>«Специалист по работе с семьёй»</td>
                            <td>Приказ ЧОУ ДПО «Национальный институт защиты детства» от 13.01.22 №01-22/Л</td>
                            <td align="center">Дистанционная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">276 часов</td>
                            <td>ЧОУ ДПО «Национальный институт защиты детства»</td>
                        </tr>
                        <tr>
                            <td align="center">Московская область</td>
                            <td>Профессиональные основы деятельности специалистов органов опеки</td>
                            <td>Программы утверждаются после согласования с заказчиком перед началом обучения</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">Да</td>
                            <td align="center">Нет</td>
                            <td align="center">72 ч</td>
                            <td>ГБОУ ДПО «Московский областной учебный центр»</td>
                        </tr>
                        <tr>
                            <td align="center">Орловская область</td>
                            <td>Нормативно-правовое и методическое обеспечение деятельности органов опеки и
                                попечительства
                            </td>
                            <td>Программа утверждается после согласования с заказчиком перед началом обучения</td>
                            <td align="center">очная</td>
                            <td align="center">нет</td>
                            <td align="center">полностью</td>
                            <td align="center">72 ч</td>
                            <td>БУ ОО «Кризисный центр помощи женщинам и детям «Орловский»</td>
                        </tr>
                        <tr>
                            <td align="center">Рязанская область</td>
                            <td>Профилактическая работа с эмоциональным выгоранием специалистов органов опеки и
                                попечительства
                            </td>
                            <td>Приказ № 71-ЮЗ от 17.08.2023</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">16 ч</td>
                            <td>ОГБУДО «Центр семьи и детства»</td>
                        </tr>
                        <tr>
                            <td align="center">Рязанская область</td>
                            <td>Расширение психологических компетенций специалистов органов опеки и попечительства в
                                сфере поддержки семьи
                            </td>
                            <td>Протокол методического совета № 1 от 14.08.2020 г.</td>
                            <td align="center">очно/заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">36 ч</td>
                            <td>ГКУ РО «Центр психолого-педагогической, медицинской и социальной помощи»</td>
                        </tr>
                        <tr>
                            <td align="center">Смоленская область</td>
                            <td>Организационно-методические основы деятельности специалистов органов опеки и
                                попечительства в отношении несовершеннолетних.
                            </td>
                            <td>Приказ № 4-осн/д 22.01.2021 (с изм. № 107 –осн/д от 12.09.2021)</td>
                            <td align="center">очная с применением дистанционных образовательных технологий и
                                электронного обучения
                            </td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">250 ч</td>
                            <td>ГАУ ДПО «Смоленский областной институт развития образования»</td>
                        </tr>
                        <tr>
                            <td align="center">Смоленская область</td>
                            <td>Организация и совершенствование деятельности органов опеки и попечительства</td>
                            <td>Приказ № 29-осн/д 08.02.2019</td>
                            <td align="center">очная с применением дистанционных образовательных технологий и
                                электронного обучения
                            </td>
                            <td align="center">да</td>
                            <td align="center">да</td>
                            <td align="center">72 ч</td>
                            <td>ГАУ ДПО «Смоленский областной институт развития образования»</td>
                        </tr>
                        <tr>
                            <td align="center">Тамбовская область</td>
                            <td>Организация деятельности специалистов в области развития семейных форм устройства</td>
                            <td>Программа утверждена протоколом
                                №4 заседания Ученого Совета ТОГОАУ ДПО ИПКРО от 20 декабря 2021г.
                            </td>
                            <td align="center">очная, заочная</td>
                            <td align="center">Да</td>
                            <td align="center">частично</td>
                            <td align="center">36 ч</td>
                            <td>Тамбовское областное государственное образовательное автономное учреждение
                                дополнительного профессионального образования
                                «Институт повышения квалификации работников
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Тамбовская область</td>
                            <td>Социально- педагогическое сопровождение семей группы риска в рамках технологии
                                раннего выявления и
                                работы со
                                случаем

                            </td>
                            <td>Программа утверждена протоколом
                                №4 заседания Ученого Совета ТОГОАУ ДПО ИПКРО от 20
                                декабря 2021г
                            </td>
                            <td align="center">очная, заочная</td>
                            <td align="center">Да</td>
                            <td align="center">частично</td>
                            <td align="center">24 ч</td>
                            <td>Тамбовское областное государственное образовательное автономное учреждение
                                дополнительного профессионального образования
                                «Институт повышения квалификации работников
                                образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Тверская область</td>
                            <td>Специалист органов опеки и попечительства</td>
                            <td>Протокол заседания ученого совета Института непрерывного образования ТвГУ от 05.10.2020
                                №1
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">С применением дистанционных образовательных технологий</td>
                            <td align="center">
                            </td>
                            <td align="center">500 часов</td>
                            <td>ФГБОУ ВО «Тверской государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Тверская область</td>
                            <td>Специалист органов опеки и попечительства в отношении несовершеннолетних</td>
                            <td>Протокол заседания ученого совета Института непрерывного образования ТвГУ от 05.10.2020
                                №1
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">С применением дистанционных образовательных технологий</td>
                            <td align="center">
                            </td>
                            <td align="center">1500 часов</td>
                            <td>ФГБОУ ВО «Тверской государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Тверская область</td>
                            <td>Специалист по работе с семьей</td>
                            <td>Протокол заседания ученого совета Института непрерывного образования ТвГУ от 05.10.2020
                                №1
                            </td>
                            <td align="center">очно - заочная</td>
                            <td align="center">С применением дистанционных образовательных технологий</td>
                            <td align="center">
                            </td>
                            <td align="center">500 часов</td>
                            <td>ФГБОУ ВО «Тверской государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Тульская область</td>
                            <td>Повышение квалификации работников органов опеки и попечительства</td>
                            <td>Протокол заседания Учебно-методического совета Тульский институт (филиал) федерального
                                государственного бюджетного образовательного учреждения высшего образования
                                «Всероссийский государственный университет юстиции

                                (РПА Минюста России)»
                                № 6 от 24.03.2023
                            </td>
                            <td align="center">С отрывом от работы и посредством дистанционных образовательных
                                технологий, электронного обучения
                            </td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Тульский институт (филиал) федерального государственного бюджетного образовательного
                                учреждения высшего образования «Всероссийский государственный университет юстиции (РПА
                                Минюста России)»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Тульская область</td>
                            <td>Повышение квалификации специалистов органов опеки и попечительства</td>
                            <td>Протокол заседания Учебно-методического совета Тульский институт (филиал) федерального
                                государственного бюджетного образовательного учреждения высшего образования
                                «Всероссийский государственный университет юстиции

                                (РПА Минюста России)»

                                № 6 от 24.03.2023
                            </td>
                            <td align="center">С отрывом от работы и посредством дистанционных образовательных
                                технологий, электронного обучения
                            </td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Тульский институт (филиал) федерального государственного бюджетного образовательного
                                учреждения высшего образования «Всероссийский государственный университет юстиции (РПА
                                Минюста России)»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ярославская область</td>
                            <td>Воспитательная деятельность в детском доме и школе-интернате</td>
                            <td>Протокол учёного совета от 22.09.2022 №7</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">56 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования
                                Ярославской области «Институт развития образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ярославская область</td>
                            <td>Комплексное сопровождение замещающих, приемных семей</td>
                            <td>Протокол учёного совета от 20.10.2022 №8</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">56 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования
                                Ярославской области «Институт развития образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ярославская область</td>
                            <td>Нормативно-правовые и организационные основы деятельности работников органов опеки и
                                попечительства
                            </td>
                            <td>Протокол учёного совета от 17.02.2022 № 2</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">56 ч</td>
                            <td>Государственное автономное учреждение дополнительного профессионального образования
                                Ярославской области «Институт развития образования»
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </details>
                <details>
                    <summary>Южный федеральный округ</summary>
                    <table className="poisk_3_1">
                        <tbody>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа повышения квалификации «Актуальные вопросы социальной педагогики (для
                                специалистов реабилитационных центров и центров социальной поддержки)»
                            </td>
                            <td>Приказ №08-01-01/649 от 16 июня 2020г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии с требованием заказчика</td>
                            <td align="center">72 ч</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа повышения квалификации «Индивидуальное психологическое консультирование
                                взрослых»
                            </td>
                            <td>Приказ №08-01-01/1497 от 21 ноября 2017г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии с требованием заказчика</td>
                            <td align="center">120 ч</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа повышения квалификации «Обеспечение и защита жилищных прав детей-сирот, детей,
                                оставшихся без попечения родителей»
                            </td>
                            <td>Приказ №08-01-01/1485б от 17 ноября 2017г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии с требованием заказчика</td>
                            <td align="center">18 ч</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа повышения квалификации «Психолого-педагогическая деятельность с лицами
                                девиантного поведения в условиях реабилитационного центра»
                            </td>
                            <td>Приказ №08-01-01/1133 от 13 августа 2017г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии
                                с требованием
                                заказчика
                            </td>
                            <td align="center">16 ч</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа повышения квалификации «Руководитель организации социального обслуживания»
                            </td>
                            <td>Приказ №08-01-01/566 от 16 мая 2017г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии
                                с требованием
                                заказчика
                            </td>
                            <td align="center">120 ч</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа профессиональной подготовки «Коррекционная работа педагога-дефектолога с
                                детьми с ограниченными возможностями здоровья»
                            </td>
                            <td>Приказ №08-01-01/441 от 22 апреля 2021г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии
                                с требованием
                                заказчика
                            </td>
                            <td align="center">10 месяцев</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа профессиональной подготовки «Педагогика и психология»</td>
                            <td>Приказ №08-01-01/312 от 15 марта 2018г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии
                                с требованием
                                заказчика
                            </td>
                            <td align="center">10 месяцев</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа профессиональной подготовки «Помощник воспитателя (для специалистов учреждений
                                для детей, оставшихся без попечения родителей)»
                            </td>
                            <td>Приказ №08-01-01/672 от 16 мая 2020г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии
                                с требованием
                                заказчика
                            </td>
                            <td align="center">3 месяца</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Астраханская область</td>
                            <td>Программа профессиональной подготовки «Социальная педагогика»</td>
                            <td>Приказ №08-01-01/621 от 29 апреля 2021г.</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">В соответствии
                                с требованием
                                заказчика
                            </td>
                            <td align="center">10 месяцев</td>
                            <td>ФГБОУ ВО «Астраханский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Волгоградская область</td>
                            <td>Программа повышения квалификации «Специалист органа опеки и попечительства в отношении
                                несовершеннолетних: полномочия и ответственность»
                            </td>
                            <td>Утверждена ученым Советом ВГСПУ, Протокол № 4 от 06.10.2021</td>
                            <td align="center">Заочная с использованием дистанционных образовательных технологий</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Волгоградский государственный социально-педагогический университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Волгоградская область</td>
                            <td>Программа профессиональной переподготовки "Специалист по социальной работе»</td>
                            <td>Утверждена ученым Советом ВГСПУ 11.01.2019</td>
                            <td align="center">Заочная с использованием дистанционных образовательных технологий</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">352 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Волгоградский государственный социально-педагогический университет»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Волгоградская область</td>
                            <td>Современные технологии профилактики профессионального выгорания в работе специалистов
                                органов опеки и попечительства
                            </td>
                            <td>На переутверждении</td>
                            <td align="center">очно - заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Волгоградский государственный социально-педагогический университет«
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Волгоградская область</td>
                            <td>Специалист органа опеки и попечительства в отношении несовершеннолетних, ключевые
                                аспекты деятельности, полномочия и ответственность
                            </td>
                            <td>Приказ ВГСПУ от 08 ноября 2019г. №02.3-774</td>
                            <td align="center">заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Волгоградский государственный социально-педагогический университет«
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Комиссии по делам несовершеннолетних и защите их прав: правовая реализация
                                деятельности
                            </td>
                            <td>Одобрена на заседании Совета Новороссийского института (филиала) АНО ВО МГЭУ от 26
                                апреля 2021г., протокол №9
                            </td>
                            <td align="center">Заочная</td>
                            <td align="center">да</td>
                            <td align="center">Предусмотрен</td>
                            <td align="center">1 месяца</td>
                            <td>Краснодарский кооперативный институт (филиал) Российского университета кооперации</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Нейропсихологическая диагностика и коррекция в детском возрасте</td>
                            <td>Одобрена решением Ученого совета СГУ (протокол № 8 от 27.04.2023г.)</td>
                            <td align="center">Очная</td>
                            <td align="center">Предусмотрена</td>
                            <td align="center">Предусмотрена, форме стажировки</td>
                            <td align="center">80 ч</td>
                            <td>ФГБОУ ВО «Сочинский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Опека и попечительство</td>
                            <td>-</td>
                            <td align="center">Заочная</td>
                            <td align="center">да</td>
                            <td align="center">Предусмотрен</td>
                            <td align="center">3 месяца</td>
                            <td>Краснодарский кооперативный институт (филиал) Российского университета кооперации</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Организация и деятельность органов опеки, попечительства и патронажа</td>
                            <td>Одобрена решением Учёного совета ФГБОУ ВО «СГУ» протокол №10 от 6 июля 2020г.</td>
                            <td align="center">Заочная</td>
                            <td align="center">да</td>
                            <td align="center">Предусмотрен</td>
                            <td align="center">3 месяца</td>
                            <td>Краснодарский кооперативный институт (филиал) Российского университета кооперации</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Организация социально-педагогической работы с родителями обучающихся с девиантным
                                поведением
                            </td>
                            <td>Одобрена решением Ученого совета СГУ (протокол № 10 от 31.03.2022г.)</td>
                            <td align="center">Очная</td>
                            <td align="center">Предусмотрена</td>
                            <td align="center">Не предусмотрена</td>
                            <td align="center">144 ч</td>
                            <td>ФГБОУ ВО «Сочинский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Правовое регулирование деятельности органов опеки и попечительства в Российской
                                Федерации
                            </td>
                            <td>Утверждена проректором по контролю качества образовательной деятельности и
                                дистанционному обучению ФГБОУ ВО КубГТУ 26 ноября 2020г., протокол методического совета
                                ФГБОУ ВО КубГТУ от 26 ноября 2020г. №3
                            </td>
                            <td align="center">Заочная</td>
                            <td align="center">да</td>
                            <td align="center">Предусмотрен</td>
                            <td align="center">1 месяца</td>
                            <td>Краснодарский кооперативный институт (филиал) Российского университета кооперации</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Программа повышения квалификации «Деятельность органов опеки и попечительства. Развитие
                                семейных форм устройства детей-сирот и детей, оставшихся без попечения родителей»
                            </td>
                            <td>Утверждена Ученым советом ФГБОУ ВО «АГПУ» №21 от 10.11.2020 г.</td>
                            <td align="center">заочная</td>
                            <td align="center">Предусмотрена</td>
                            <td align="center">Нет</td>
                            <td align="center">72 ч</td>
                            <td>Федеральное государственное бюджетное образовательное учреждение высшего образования
                                «Армавирский государственный педагогический университет
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Профессиональный профайлинг</td>
                            <td>Одобрена решением Ученого совета СГУ (протокол № 10 от 29.05.2023г.)</td>
                            <td align="center">Очная

                            </td>
                            <td align="center">Предусмотрена</td>
                            <td align="center">Не предусмотрена</td>
                            <td align="center">16 ч</td>
                            <td>ФГБОУ ВО «Сочинский государственный университет»</td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Социальное обслуживание в целях нормализации условий жизнедеятельности</td>
                            <td>Одобрена на заседании Совета Новороссийского института (филиала) АНО ВО МГЭУ от 26
                                апреля 2021г. протокол №9
                            </td>
                            <td align="center">Заочная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">1 месяца</td>
                            <td>Новороссийский институт (филиал) АНО ВО «Московский гуманитарно-экономический
                                институт»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Краснодарский край</td>
                            <td>Специалист органа опеки и попечительства в отношении несовершеннолетних</td>
                            <td>Утверждена проректором по контролю качества образовательной деятельности и
                                дистанционному обучению ФГБОУ ВО КубГТУ 24 декабря 2020г.
                            </td>
                            <td align="center">Заочная</td>
                            <td align="center">да</td>
                            <td align="center">Предусмотрен</td>
                            <td align="center">3 месяца</td>
                            <td>Краснодарский кооперативный институт (филиал) Российского университета кооперации</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Адыгея</td>
                            <td>Адаптация детей-сирот, оставшихся без попечения родителей, к жизни в новых семьях</td>
                            <td>Протокол заседания кафедры педагогики, психологии и управления образованием ГБУ ДПО РА
                                «АРИПК» №1 от 4 февраля 2021г.
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">72 ч</td>
                            <td>ГБУ ДПО РА «АРИПК»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Адыгея</td>
                            <td>Нормативно-правовые и организационные основы деятельности работников органов опеки и
                                попечительства
                            </td>
                            <td>Протокол заседания кафедры педагогики, психологии и управления образованием ГБУ ДПО РА
                                «АРИПК» №1 от 4 февраля 2021г.
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">18 ч</td>
                            <td>ГБУ ДПО РА «АРИПК»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Адыгея</td>
                            <td>Обеспечение жилыми помещениями детей-сирот и детей, оставшихся без попечения родителей
                            </td>
                            <td>Протокол заседания кафедры педагогики, психологии и управления образованием ГБУ ДПО РА
                                «АРИПК» №1 от 4 февраля 2021г.
                            </td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">18 ч</td>
                            <td>ГБУ ДПО РА «АРИПК»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Адыгея</td>
                            <td>Профилактика самовольных уходов и деструктивного поведения несовершеннолетних</td>
                            <td>Протокол заседания кафедры педагогики, психологии и управления образованием ГБУ ДПО РА
                                «АРИПК» № 4 от 24 декабря 2021 г.
                            </td>
                            <td align="center">Очная</td>
                            <td align="center">да</td>
                            <td align="center">нет</td>
                            <td align="center">18 ч</td>
                            <td>ГБУ ДПО РА «АРИПК»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Калмыкия</td>
                            <td>Актуальные вопросы правового регулирования опеки и попечительства</td>
                            <td>Утвержден проректором по международному сотрудничеству ФГБОУ ВО «КГУ им. Б.Б.
                                Городовикова» от 02.06.2022 Протокол НМС №10 от 19.05.2022
                            </td>
                            <td align="center">Очная</td>
                            <td align="center">Да</td>
                            <td align="center">В соответствии с требованием заказчика</td>
                            <td align="center">40 ч.</td>
                            <td>ФГБОУ ВО «Калмыцкий государственный университет имени Б.Б. Городовикова»</td>
                        </tr>
                        <tr>
                            <td align="center">Республика Крым</td>
                            <td>«Профессиональная деятельность специалистов органа опеки и попечительства в отношении
                                несовершеннолетних в рамках профессионального стандарта»
                            </td>
                            <td>Программа находится в разработке на утверждении. Планируется реализация программы в 2022
                                г.
                            </td>
                            <td align="center">Очная</td>
                            <td align="center">Да (с применением платформы Discord)</td>
                            <td align="center">Нет</td>
                            <td align="center">36 часов</td>
                            <td>Государственное бюджетное образовательное учреждение дополнительного профессионального
                                образования Республики Крым «Крымский республиканский институт постдипломного
                                педагогического образования»
                            </td>
                        </tr>
                        <tr>
                            <td align="center">Ростовская область</td>
                            <td>Система социально-педагогической поддержки детства в условиях деятельности муниципальных
                                образований по опеке и попечительству в контексте трансформации российского образования
                            </td>
                            <td>Заседание ученого Совета, протокол от 21 октября 2022 г. №1</td>
                            <td align="center">очная</td>
                            <td align="center">да</td>
                            <td align="center">частично</td>
                            <td align="center">72 ч</td>
                            <td>Государственное бюджетное учреждение дополнительного профессионального образования
                                Ростовской Области «Ростовский институт повышения квалификации и профессиональной
                                переподготовки работников образования»
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </details>
            </div>
        </div>
    )
}
export default Navigator3