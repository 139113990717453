import s from "./NewsPages.module.css";
import img from "../../assets/News/News091023.jpg";
import { AdviceBlock, ShareBlock } from "./News170621";

const News020322 = () => {
  return (
    <div className={s.main}>
      <h1>
        Утвержден новый профессиональный стандарт «Специалист органа опеки и
        попечительства в отношении несовершеннолетних»
      </h1>
      <div className={s.date}>9 октября 2023</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div>
          Утвержден новый профессиональный стандарт «Специалист органа опеки и
          попечительства в отношении несовершеннолетних» приказом Министерства
          труда и социальной защиты Российской Федерации от 6 сентября 2023 г. №
          691н.
          <p></p>
          Профессиональный стандарт «Специалист органа опеки и попечительства в
          отношении несовершеннолетних» применяется работодателями при
          формировании кадровой политики и в управлении персоналом, при
          организации обучения и аттестации работников, заключении трудовых
          договоров, разработке должностных инструкций и установлении систем
          оплаты труда с 1 января 2015 года.
          <p></p>
          Актуализация профессионального стандарта вызвана изменениями в
          законодательство Российской Федерации в сфере опеки и попечительства,
          в общероссийские классификаторы видов экономической деятельности,
          занятий, специальностей по образованию, а также результатами анализа
          применения профессионального стандарта.
          <p></p>В новый стандарт внесены изменения в группы занятий,
          конкретизированы требования к образованию для специалистов, уточнены и
          определены требования к опыту практической работы в состав и
          наименование обобщенных трудовых функций с перераспределением входящих
          в них трудовых функций. С учетом внедрения информационных технологий
          произведена корректировка формулировок при описании трудовых действий,
          а также требования к необходимым умениям и знаниям.
          <p></p>
          Ознакомиться с новым профессиональным стандартом «Специалист органа
          опеки и попечительства в отношении несовершеннолетних» можно по
          ссылке:{" "}
          <a href="https://frcopeca.ru/documents-category/akty-foiv/">
            https://frcopeca.ru/documents-category/akty-foiv/
          </a>
          .
        </div>
        <ShareBlock />
      </div>
      <AdviceBlock />
    </div>
  );
};
export default News020322;
