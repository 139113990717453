import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/auth/authActions";

const Profile = () => {
  return (
    <div className={s.main}>
      <h1>Личный кабинет</h1>
      <CabinetNav />
      <ProfileInfo />
    </div>
  );
};
export default Profile;
const ProfileInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);

  const [respData, setData] = useState("");

  const loadInfo = () => {
    if(user){

      axios
      .get(`https://frcopeca.ru/api/usersinfo?id=${user?.id}`)
      .then((res) => {
        
        setData(res.data[0]);
      })
      .catch((e) => {});
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      
      let config = {
        method: "GET",
        maxBodyLength: Infinity,
        url: `https://frcopeca.ru/api/usersinfo?id=${user.id}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      await axios
        .request(config)
        .then((response) => {
          let userData = response.data.find((news) => news.id === user.id);
          setUserInfo(userData);
          console.log(userData);
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData(user);
  }, [user]);

  useEffect(() => {
    loadInfo(user);
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (!userInfo) {
    return <div></div>;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        pos: respData.должность,
        fio: respData.ФИО,
        mail: respData.email,
        phone: respData.МОБИЛЬНЫЙ_ТЕЛЕФОН,
        workPhone: respData.РАБОЧИЙ_ТЕЛЕФОН,
        name: respData.НАЗВАНИЕ_ВЕДОМСТВА,
        region: respData.РЕГИОН,
      }}
    >
      <Form style={{ paddingBottom: "20px" }}>
        <div className={s.profileInfo}>
          <h2>Данные профиля</h2>
          <div className={s.work}>
            <div className={s.infoName}>ДОЛЖНОСТЬ ПОЛЬЗОВАТЕЛЯ:</div>
            <Field type="text" readOnly={!editMode} name="pos" />
          </div>
          <div className={s.work}>
            <div className={s.infoName}>ФИО ПОЛЬЗОВАТЕЛЯ:</div>
            <Field type="text" readOnly={!editMode} name="fio" />
          </div>
          <div className={s.mail}>
            <div className={s.infoName}>EMAIL:</div>
            <Field type="text" readOnly={true} name="mail" />
          </div>
        </div>
        <div className={s.contactInfo}>
          <h2>Контактная информация</h2>
          <div className={s.anyInfo}>
            <div className={s.infoName}>МОБИЛЬНЫЙ ТЕЛЕФОН:</div>
            <Field type="text" readOnly={!editMode} name="phone" />
          </div>
          <div className={s.anyInfo}>
            <div className={s.infoName}>РАБОЧИЙ ТЕЛЕФОН:</div>
            <Field type="text" readOnly={!editMode} name="workPhone" />
          </div>
          <div className={s.anyInfo}>
            <div className={s.infoName}>НАЗВАНИЕ ВЕДОМСТВА:</div>
            <Field type="text" readOnly={!editMode} name="name" />
          </div>
          <div className={s.anyInfo}>
            <div className={s.infoName}>РЕГИОН:</div>
            <Field type="text" readOnly={true} name="region" />
          </div>
        </div>
        {editMode ? (
          <div className={s.editMode}>
            <div className={s.submit}>
              <button type="submit">Отправить</button>
            </div>
            <div className={s.cancel}>
              <div onClick={() => setEditMode(false)}>Отменить</div>
            </div>
          </div>
        ) : (
          <div className={s.edit}>
            <div onClick={() => setEditMode(true)}>Редактировать</div>
            <p></p>
            <div
              onClick={() => {
                dispatch(logout());
                navigate("/");
              }}
            >
              Выйти
            </div>
          </div>
        )}
        <p style={{ marginBottom: "40px" }}></p>
      </Form>
    </Formik>
  );
};

const CabinetNav = () => {
  const hrefs = [
    "/profile",
    "/reports-list/otchety-2020",
    "/reports-list/otchety-2021",
    "/reports-list/2022",
    "/reports-list/2023",
    "/reports-list/2024",
  ];
  const url = window.location.href;
  return (
    <div className={s.navigator}>
      <a
        href={hrefs[0]}
        style={
          url.includes(hrefs[0])
            ? {
                backgroundColor: "#eef0f7",
                border: "1px solid #dadfe9",
                borderBottom: "none",
              }
            : { backgroundColor: "white" }
        }
      >
        Мои данные
      </a>
      <a
        href={hrefs[1]}
        style={
          url.includes(hrefs[1])
            ? {
                backgroundColor: "#eef0f7",
                border: "1px solid #dadfe9",
                borderBottom: "none",
              }
            : { backgroundColor: "white" }
        }
      >
        2020
      </a>
      <a
        href={hrefs[2]}
        style={
          url.includes(hrefs[2])
            ? {
                backgroundColor: "#eef0f7",
                border: "1px solid #dadfe9",
                borderBottom: "none",
              }
            : { backgroundColor: "white" }
        }
      >
        2021
      </a>
      <a
        href={hrefs[3]}
        style={
          url.includes(hrefs[3])
            ? {
                backgroundColor: "#eef0f7",
                border: "1px solid #dadfe9",
                borderBottom: "none",
              }
            : { backgroundColor: "white" }
        }
      >
        2022
      </a>
      <a
        href={hrefs[4]}
        style={
          url.includes(hrefs[4])
            ? {
                backgroundColor: "#eef0f7",
                border: "1px solid #dadfe9",
                borderBottom: "none",
              }
            : { backgroundColor: "white" }
        }
      >
        2023
      </a>
      <a
        href={hrefs[5]}
        style={
          url.includes(hrefs[5])
            ? {
                backgroundColor: "#eef0f7",
                border: "1px solid #dadfe9",
                borderBottom: "none",
              }
            : { backgroundColor: "white" }
        }
      >
        2024
      </a>
    </div>
  );
};
