import s from "./Files.module.css";
import { FileRef, hrefArray, InfoPanel } from "./FZ";
import { useEffect, useState } from "react";
import axios from "axios";

const Letters1 = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/NPD").then((res) => {
      setData(res);
    });
  }, []);
  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <div className={s.leftSide}>
          <h2>Письма Федеральных органов исполнительной власти</h2>
          <FileRef
            text={respData ? respData.data[0].description : ""}
            url={respData ? respData.data[0].file : ""}
          />
          <FileRef
            text={respData ? respData.data[1].description : ""}
            url={respData ? respData.data[1].file : ""}
          />
          <FileRef
            text={respData ? respData.data[2].description : ""}
            url={respData ? respData.data[2].file : ""}
          />
          <FileRef
            text={respData ? respData.data[3].description : ""}
            url={respData ? respData.data[3].file : ""}
          />
          <FileRef
            text={respData ? respData.data[4].description : ""}
            url={respData ? respData.data[4].file : ""}
          />
          <FileRef
            text={respData ? respData.data[5].description : ""}
            url={respData ? respData.data[5].file : ""}
          />
          <FileRef
            text={respData ? respData.data[6].description : ""}
            url={respData ? respData.data[6].file : ""}
          />
          <FileRef
            text={respData ? respData.data[7].description : ""}
            url={respData ? respData.data[7].file : ""}
          />
          <FileRef
            text={respData ? respData.data[8].description : ""}
            url={respData ? respData.data[8].file : ""}
          />
          <FileRef
            text={respData ? respData.data[9].description : ""}
            url={respData ? respData.data[9].file : ""}
          />
          <FileRef
            text={respData ? respData.data[10].description : ""}
            url={respData ? respData.data[10].file : ""}
          />
          <FileRef
            text={respData ? respData.data[11].description : ""}
            url={respData ? respData.data[11].file : ""}
          />
          <FileRef
            text={respData ? respData.data[12].description : ""}
            url={respData ? respData.data[12].file : ""}
          />
          <FileRef
            text={respData ? respData.data[13].description : ""}
            url={respData ? respData.data[13].file : ""}
          />
          <FileRef
            text={respData ? respData.data[14].description : ""}
            url={respData ? respData.data[14].file : ""}
          />
        </div>
        <div className={s.rightSide}>
          <InfoPanel hrefArray={hrefArray} />
        </div>
      </div>
    </div>
  );
};
export default Letters1;
