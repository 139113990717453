import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs8.jpg"
import img1 from "../../assets/Webs/Webs8_2.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";
import {NewsImage} from "../News/News170621";


const Webs8 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: 8 правил безопасного общения с подростками: как родителям реагировать на «трудное»
                поведение ребенка
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    20 апреля 2020 г. прошел вебинар на актуальную тему для специалистов, работающих в сфере семейного
                    устройства: «О лучших практиках по подготовке граждан к приему на воспитание в семью ребенка,
                    оставшегося без попечения родителей». В мероприятии приняли участие 1223 человека из 80 регионов
                    Российской Федерации.
                    <p></p>
                    Ведущая вебинара И.А. Меркуль, кандидат психологических наук, заместитель директора ФГБУ «Центр
                    защиты прав и интересов детей», рассказала о проведении федеральным ресурсным центром по вопросам
                    опеки и попечительства в отношении несовершеннолетних мониторинга о реализации полномочий органов
                    опеки и попечительства по подготовке граждан, желающих принять на воспитание в свою семью ребенка,
                    во всех субъектах Российской Федерации с точки зрения оценки социально – ориентированной
                    деятельности.
                    <p></p>
                    Гость вебинара – заместитель директора Департамента социального развития Ханты-Мансийского АО-Югры
                    Немчинова Елена Владимировна представила одну из лучших региональных практик по подготовке
                    потенциальных кандидатов в замещающие родители. Аналитик Центра Мария Алексеевна Назарова
                    познакомила участников с лучшими программами, реализуемыми школами приемных родителей, а также
                    дополнительными программами по подготовке будущих приемных родителей к принятию в свою семью ребенка
                    с ограниченными возможностями здоровья, братьев и сестер (сиблингов), а также, подростков.
                    <p></p>
                    В ходе выступления ведущие ответили на вопросы, волнующие специалистов органов опеки и
                    попечительства и школ приемных родителей. Одним из самых насущных был вопрос об организации и
                    проведении школы приемных родителей в условиях пандемии.
                </div>
                <NewsImage img={img1}/>
                <YouTube videoId="BQ2Q6PWS690" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs8
