import s from "./Carousel.module.css";
import {useEffect, useState} from "react";
import {ArrowRightOutlined} from "@ant-design/icons";
import {formatDate} from "../Pages/News";


const NewsSlider2 = (props) => {

    const {children} = props;
    const [touchPosition, setTouchPosition] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)

// Set the length to match current children from props
    useEffect(() => {
        setLength(children.length)
    }, [children])

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    return (
        <div className={s.carouselContainer}>
            <div className={s.carouselWrapper}>
                <button onClick={prev} className={s.leftArrow}>
                    <svg width="50px" height="50px" viewBox="2 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 7L10 12L15 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div className={s.carouselContentWrapper} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div className={s.carouselContent} style={{transform: `translateX(-${currentIndex * 100}%)`}}>
                        {children}
                    </div>
                </div>
                <button onClick={next} className={s.rightArrow}>
                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 7L15 12L10 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export const NewsWindow = ({el}) => {
    return (
        <div className={s.item}>
            <img src={el.img} alt=""/>
            <div className={s.newsText}>
                <div className={s.newsFlex}>
                    <span className={s.newsTitle}>
                        <a href={el.ref}>{el.Header}</a>
                    </span>
                    <div className={s.newsDate}>
                        {formatDate(el.date)}
                    </div>
                    <div className={s.newsDescription}>
                        {el.description}
                    </div>
                </div>
                <div className={s.btn}>
                    <a href={el.ref}>Перейти<ArrowRightOutlined
                        style={{paddingLeft: "6px"}}/></a>
                </div>
            </div>
        </div>
    )
}

export default NewsSlider2