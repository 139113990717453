import s from "./Files.module.css";
import { ArrowRightOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

const FZ = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/NPD").then((res) => {
      setData(res);
    });
  }, []);
  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <div className={s.leftSide}>
          <h2>Федеральные законы</h2>
          <FileRef
            text={respData ? respData.data[30].description : ""}
            url={respData ? respData.data[30].file : ""}
          />
          <FileRef
            text={respData ? respData.data[31].description : ""}
            url={respData ? respData.data[31].file : ""}
          />
          <FileRef
            text={respData ? respData.data[32].description : ""}
            url={respData ? respData.data[32].file : ""}
          />
          <FileRef
            text={respData ? respData.data[33].description : ""}
            url={respData ? respData.data[33].file : ""}
          />
          <FileRef
            text={respData ? respData.data[34].description : ""}
            url={respData ? respData.data[34].file : ""}
          />
          <FileRef
            text={respData ? respData.data[35].description : ""}
            url={respData ? respData.data[35].file : ""}
          />
          <FileRef
            text={respData ? respData.data[36].description : ""}
            url={respData ? respData.data[36].file : ""}
          />
          <FileRef
            text={respData ? respData.data[37].description : ""}
            url={respData ? respData.data[37].file : ""}
          />
          <FileRef
            text={respData ? respData.data[38].description : ""}
            url={respData ? respData.data[38].file : ""}
          />
          <FileRef
            text={respData ? respData.data[39].description : ""}
            url={respData ? respData.data[39].file : ""}
          />
          <FileRef
            text={respData ? respData.data[40].description : ""}
            url={respData ? respData.data[40].file : ""}
          />
          <FileRef
            text={respData ? respData.data[41].description : ""}
            url={respData ? respData.data[41].file : ""}
          />
          <FileRef
            text={respData ? respData.data[42].description : ""}
            url={respData ? respData.data[42].file : ""}
          />
          <FileRef
            text={respData ? respData.data[43].description : ""}
            url={respData ? respData.data[43].file : ""}
          />
          <FileRef
            text={respData ? respData.data[44].description : ""}
            url={respData ? respData.data[44].file : ""}
          />
          <FileRef
            text={respData ? respData.data[45].description : ""}
            url={respData ? respData.data[45].file : ""}
          />
          <FileRef
            text={respData ? respData.data[46].description : ""}
            url={respData ? respData.data[46].file : ""}
          />
          <FileRef
            text={respData ? respData.data[47].description : ""}
            url={respData ? respData.data[47].file : ""}
          />
          <FileRef
            text={respData ? respData.data[48].description : ""}
            url={respData ? respData.data[48].file : ""}
          />
          <FileRef
            text={respData ? respData.data[49].description : ""}
            url={respData ? respData.data[49].file : ""}
          />
          <FileRef
            text={respData ? respData.data[50].description : ""}
            url={respData ? respData.data[50].file : ""}
          />
          <FileRef
            text={respData ? respData.data[51].description : ""}
            url={respData ? respData.data[51].file : ""}
          />
          <FileRef
            text={respData ? respData.data[52].description : ""}
            url={respData ? respData.data[52].file : ""}
          />
        </div>
        <div className={s.rightSide}>
          <InfoPanel hrefArray={hrefArray} />
        </div>
      </div>
    </div>
  );
};
export default FZ;

export const FileRef = ({ text, url }) => {
  return (
    <div className={s.buttonClass}>
      <a href={url}>
        <FilePdfOutlined className={s.pdfStyle} />
        <span style={{ paddingLeft: "10px", lineHeight: "1.5" }}>{text}</span>
      </a>
    </div>
  );
};

export const LinkRef = ({ text, url }) => {
  return (
    <div className={s.buttonClass}>
      <a href={url}>
        <ArrowRightOutlined className={s.pdfStyle} />
        <span style={{ paddingLeft: "10px", lineHeight: "1.5" }}>{text}</span>
      </a>
    </div>
  );
};

export const InfoPanel = ({ hrefArray }) => {
  return (
    <div className={s.infoPanel}>
      {hrefArray.map((el) => (
        <div className={s.navRef}>
          <a href={el.url}>
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 322.000000 250.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,290.000000) scale(0.100000,-0.100000)"
                fill="#currentColor"
                stroke="none"
              >
                <path
                  d="M807 2482 c-9 -10 -17 -40 -19 -72 l-3 -55 -81 -6 c-85 -6 -127 -24
                                    -171 -71 -11 -13 -136 -251 -277 -530 l-257 -507 3 -544 3 -544 30 -48 c22
                                    -36 45 -57 84 -77 l54 -28 1447 2 1447 3 49 30 c37 23 56 44 77 84 l27 55 0
                                    526 c0 355 -4 537 -11 558 -25 71 -500 997 -526 1025 -43 45 -98 67 -175 67
                                    l-68 0 0 49 c0 34 -6 56 -21 75 l-20 26 -788 0 c-740 0 -789 -1 -804 -18z
                                    m1503 -242 l0 -140 -700 0 -700 0 0 140 0 140 700 0 700 0 0 -140z m-1522 -82
                                    l-3 -53 -57 -3 c-86 -4 -88 -10 -88 -219 l0 -173 -44 0 c-29 0 -52 -7 -70 -21
                                    -26 -20 -26 -22 -26 -150 l0 -129 -131 0 -130 0 196 388 c107 213 203 393 212
                                    400 9 6 45 12 80 12 l64 0 -3 -52z m1782 42 c11 -6 103 -175 212 -392 106
                                    -210 195 -385 196 -390 2 -4 -55 -8 -127 -8 l-131 0 0 128 c0 70 -4 132 -8
                                    138 -13 20 -50 34 -91 34 l-41 0 0 177 c0 209 -2 213 -87 213 l-53 0 0 55 0
                                    55 56 0 c30 0 64 -5 74 -10z m-110 -355 l0 -135 -850 0 -850 0 0 128 c0 71 3
                                    132 7 135 3 4 386 7 850 7 l843 0 0 -135z m140 -350 l0 -85 -182 0 c-243 0
                                    -233 5 -315 -162 l-63 -128 -430 0 -431 0 -44 98 c-61 134 -66 142 -98 166
                                    -26 19 -45 21 -220 26 l-192 5 -3 83 -3 82 991 0 990 0 0 -85z m-1621 -267
                                    c10 -24 35 -78 55 -121 60 -126 17 -117 576 -117 460 0 476 1 508 20 27 17 45
                                    45 90 140 l56 120 385 0 c381 0 385 0 408 -22 l23 -21 0 -521 0 -520 -22 -23
                                    -21 -23 -1424 0 -1424 0 -24 25 -25 24 0 514 c0 505 0 515 21 541 l20 26 390
                                    0 390 0 18 -42z"
                />
                <path
                  d="M1211 756 c-43 -24 -50 -51 -51 -199 l0 -139 29 -29 29 -29 392 0
                                    392 0 29 29 29 29 0 142 c0 78 -5 150 -10 161 -26 47 -45 49 -442 49 -290 -1
                                    -378 -4 -397 -14z m729 -196 l0 -80 -330 0 -330 0 0 80 0 80 330 0 330 0 0
                                       -80z"
                />
              </g>
            </svg>
            {el.text}
          </a>
        </div>
      ))}
    </div>
  );
};

export const hrefArray = [
  {
    text: "Письма Федеральных органов исполнительной власти",
    url: "/documents-category/pisma-federalnyh-organov-ispolnitelnoj-vlasti",
  },
  {
    text: "Письма Федеральной службы судебных приставов",
    url: "/documents-category/pisma-federalnoj-sluzhby-sudebnyh-pristavov",
  },
  {
    text: "Документы Высших Судов РФ",
    url: "/documents-category/dokumenty-vysshih-sudov-rf",
  },
  {
    text: "Федеральные законы",
    url: "/documents-category/federalnye-zakony",
  },
  {
    text: "Международные акты",
    url: "/documents-category/mezhdunarodnye-akty",
  },
  {
    text: "Указы Президента Российской Федерации",
    url: "/documents-category/ukazy-prezidenta-rossijskoj-federatsii",
  },
  {
    text: "Акты Правительства Российской Федерации",
    url: "/documents-category/akty-prezidenta-i-pravitelstva-rf",
  },
  {
    text: "Акты федеральных органов исполнительной власти",
    url: "/documents-category/akty-foiv",
  },
];
