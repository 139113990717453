import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs7.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs7 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Лучшие региональные практики в сфере обеспечения детей-сирот, детей, оставшихся без
                попечения родителей, и лиц из их числа жилыми помещениями
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    16 апреля 2020 г. состоялся вебинар на тему «Лучшие региональные практики в сфере обеспечения
                    детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа жилыми помещениями».
                    <p></p>
                    Ведущая вебинара: аналитик ФГБУ «Центр защиты прав и интересов детей» Середа Альбина Леонидовна.
                    <p></p>
                    В ходе встречи был проведен обзор эффективных практик по организации деятельности органов
                    исполнительной власти субъектов Российской Федерации в сфере обеспечения детей-сирот, детей,
                    оставшихся без попечения родителей, и лиц из их числа (далее – дети-сироты и лица из их числа)
                    жилыми помещениями.
                    <p></p>
                    В вебинаре приняли участие руководители органов исполнительной власти Приморского края, Томской
                    области, Волгоградской области, которые познакомили участников вебинара с региональными практиками,
                    имеющими положительный результат в сфере обеспечения детей-сирот и лиц из их числа жилыми
                    помещениями.
                    <p></p>
                    Всего в вебинаре приняли участие 430 человек из 68 субъектов Российской Федерации и 2 иностранных
                    государств.
                </div>
                <YouTube videoId="3e_HD-_M_l4" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs7
