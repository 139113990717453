import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs4.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs4 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: 8 правил безопасного общения с подростками: как родителям реагировать на «трудное»
                поведение ребенка
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    13 апреля 2020 г. прошел вебинар по актуальной для родителей теме: «8 правил безопасного общения с
                    подростками: как родителям реагировать на «трудное» поведение ребенка».
                    <p></p>
                    Ведущая вебинара — аналитик Центра, кандидат педагогических наук Горнова Татьяна Сергеевна.
                    <p></p>
                    В мероприятии приняли участие 1039 человек из 62 регионов Российской Федерации, а также Украины,
                    Нидерландов, Германии и США.
                    <p></p>
                    На вебинаре были раскрыты основные сложности, которые испытывают родители подростков. Родителям даны
                    рекомендации о том, как сделать подростковый возраст безопасным для родителей и самих подростков,
                    как правильно реагировать на поведение взрослеющего ребенка. В завершении вебинара родители получили
                    ответы на интересующие вопросы.
                </div>
                <YouTube videoId="4kh_HfVmYoc" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs4
