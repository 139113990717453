import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs16.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs16 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Мастер-класс «Роль организации для детей-сирот в подготовке к жизни в семье»
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <YouTube videoId="ICJx43aUh90" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs16
