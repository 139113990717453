import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs23.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs23 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: О выдаче органами опеки предварительных разрешений на осуществление имущественных прав ребенка
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <YouTube videoId="iAI6SR9Euo0" opts={opts}/>
                <div style={{paddingTop: "15px"}}>
                    <a href="/">▶ Ответы на вопросы участников вебинара</a>
                    <p></p>
                    <a href="/">▶ Методические рекомендации</a>
                </div>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs23
