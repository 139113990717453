import s from "./NewsPages.module.css"
import img from "../../assets/News/News160822.jpeg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News160822 = () => {

    return (
        <div className={s.main}>
            <h1>
                Обсуждение изменений в профессиональный стандарт «Специалист органа опеки и попечительства в отношении несовершеннолетних»
            </h1>
            <div className={s.date}>
                16 августа 2022
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    В 2022 году Совет по профессиональным квалификациям в сфере безопасности труда, социальной защиты
                    и занятости населения при участии ФГБУ «Центр защиты прав и интересов детей» осуществляет
                    актуализацию профессионального стандарта «Специалист органа опеки и попечительства в отношении
                    несовершеннолетних», утвержденного приказом Министерства труда и социальной защиты
                    Российской Федерации от 18 ноября 2013 г. № 680н.
                    <p></p>
                    Актуализация профессионального стандарта вызвана изменениями в общероссийских классификаторах
                    видов экономической деятельности, занятий, специальностей по образованию, законодательстве
                    Российской Федерации в сфере опеки и попечительства.
                    <p></p>
                    Актуализация и реализация профессионального стандарта отвечает задачам кадрового обеспечения
                    в рамках совершенствования деятельности органов опеки и попечительства.
                    <p></p>
                    В целях обсуждения предлагаемых изменений проект актуализированного профессионального стандарта
                    размещен по <a href="/file">ссылке</a>.
                    <p></p>
                    Замечания и предложения по доработке проекта можно направлять до 1 сентября 2022 г. на адрес
                    электронной почты: opeca@fcprc.ru
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News160822
