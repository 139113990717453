import s from "./Main.module.css";
import img from "../../assets/Pages/Navigator.jpg"
const NavigatorOOIP = () => {
    return (
        <div className={s.main}>
            <h1>Навигатор ООиП</h1>
            <div className={s.navigatorImg}>
                <a href="/navigator"><img src={img} alt="Ссылка-картинка на навигатор"/></a>
            </div>
        </div>
    )
}
export default NavigatorOOIP