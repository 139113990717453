import s from "./NewsPages.module.css"
import img from "../../assets/News/News230323.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News230323 = () => {

    return (
        <div className={s.main}>
            <h1>
                Обучающий вебинар по теме: «Исполнение законодательства об опеке и попечительстве в отношении
                несовершеннолетних граждан»
            </h1>
            <div className={s.date}>
                23 марта 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    31 марта 2022 г. в 10:00 по московскому времени ФГБУ «Центр защиты прав и интересов детей» при
                    участии Минпросвещения России и Университета прокуратуры Российской Федерации проводит вебинар для
                    специалистов органов опеки и попечительства по теме: «Исполнение законодательства об опеке и
                    попечительстве в отношении несовершеннолетних граждан».
                    <p></p>
                    На вебинаре будут рассмотрены в том числе вопросы, связанные с ненадлежащим исполнением органами
                    опеки и попечительства обязанностей по выявлению, учету и жизнеустройству детей, оставшихся без
                    попечения родителей; отсутствием должного контроля за условиями содержания, воспитания и образования
                    несовершеннолетних подопечных, а также за расходованием опекунами (попечителями) денежных средств,
                    принадлежащих несовершеннолетним подопечным.
                    <p></p>
                    Для участия в вебинаре приглашаются также и представители органов исполнительной власти субъектов
                    Российской Федерации, осуществляющих государственное управление в сфере опеки и попечительства в
                    отношении несовершеннолетних граждан.
                    <p></p>
                    Регистрация участников доступна по ссылке <a
                    href="https://events.webinar.ru/39914579/2046125962">https://events.webinar.ru/39914579/2046125962</a>.
                    <p></p>
                    С учетом технических возможностей цифровой платформы допустимо не более одного подключения от
                    каждого органа опеки и попечительства и одного от каждого органа исполнительной власти субъекта
                    Российской Федерации.
                    <p></p>
                    Письмо о проведении вебинара направлено в адрес субъектов Российской Федерации 14 марта.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News230323
