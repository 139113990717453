import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs20.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs20 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Мастер-класс на тему: Арт зачёт по работе с творческими детьми сиротами
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>

                <YouTube videoId="ZbFEoNDDa3I" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs20
