import s from "./NewsPages.module.css"
import img from "../../assets/News/News130723.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News130723 = () => {

    return (
        <div className={s.main}>
            <h1>
                Фонд Тимченко начал прием заявок на конкурс «Туда, где семья», направленный на выявление и поддержку
                действующих эффективных инструментов и технологий работы с уязвимыми семьями с детьми на малых
                территориях
            </h1>
            <div className={s.date}>
                13 июля 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Информируем об объявлении нового конкурса Фонда Тимченко «Туда, где семья».
                    <p></p>
                    6 июля 2023 года Фонд Тимченко начал прием заявок на конкурс «Туда, где семья», направленный на
                    выявление и поддержку действующих эффективных инструментов и технологий работы с уязвимыми семьями с
                    детьми на малых территориях*.
                    <p></p>
                    Решение проблемы социального сиротства, улучшение благополучия детей и семей — в числе основных
                    государственных приоритетов в России.
                    <p></p>
                    Тем не менее в сфере защиты детства по-прежнему есть ряд проблем, которые сложно решить только
                    мерами государственной поддержки: концентрация услуг в крупных городах и их недостаточное количество
                    на малых территориях, недоверие со стороны общества к государственным и муниципальным службам
                    защиты, низкая правовая культура и информированность сообщества, дефицит знаний.
                    <p></p>
                    Необходима активизация местного сообщества!
                    <p></p>
                    Именно на развитие этого подхода направлен новый конкурс. В нём будут поддержаны проекты,
                    направленные на профилактику сиротства, семейного неблагополучия и жестокого обращения с детьми
                    через работу и сопровождение следующих категорий семей с детьми на малых территориях:
                    <ul>
                        <li>в трудной жизненной ситуации или кризисе;</li>
                        <li>с риском или опытом жестокого обращения с ребенком или другими членами семьи;</li>
                        <li>с одним или двумя родителями с алкогольной, наркотической или иной зависимостью;</li>
                        <li>где хотя бы один из детей или родителей имеет ОВЗ или инвалидность в ситуации временного
                            пребывания
                            детей в организациях для детей-сирот и детей, оставшихся без попечения родителей,
                            специализированных
                            учреждениях для несовершеннолетних, замещающих семьях;
                        </li>
                        <li>опекунов или попечителей с возможным риском вторичного сиротства ребенка;</li>
                        <li>с членами семьи — вынужденными переселенцами;</li>
                        <li>с членами семьи — участниками специальной военной операции.</li>
                    </ul>

                    <strong>Заявки на участие в конкурсе принимаются с 6 июля по 21 августа 2023 года через
                        онлайн-систему.</strong>
                    <p></p>
                    Организации-победители получат финансовую поддержку до 2 млн руб., экспертную и методическую
                    поддержку, возможность обучения и обмена опытом, а также участие в мероприятиях Фонда.
                    <p></p>

                    <a href="https://contest.timchenkofoundation.org/tuda-gde-semya/?utm_source=site_ft&utm_medium=referral&utm_campaign=konkurs_tuda-gde-semya&utm_content=start-newspage&utm_term=textlink">Подробная
                        информация на сайте конкурса.</a>
                    <p></p>
                    <div style={{fontSize: "12px"}}>*К малым территориям относятся территории с населением менее 200 000 чел. К малым территориям не
                    могут быть отнесены районы или муниципальные образования с населением менее 200 000 чел., но
                    являющиеся частью крупной городской агломерации (деление крупных городов на районы и
                    муниципалитеты).</div>


                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News130723
