import s from "./Header.module.css"
import {MenuOutlined} from "@ant-design/icons";
import PMPKLogo from "../../assets/Header/frc-opeka-blue.png"
import React, {useState} from "react";
import {BigMenu} from "./Header";

const MobileHeader = () => {
    const [active, setActive] = useState(false);

    return <div>
    <header style={{maxHeight: "150px"}}>
        <span className={s.minPros} style={{display: "flex", justifyContent: "center"}}>
            <div>
                <a href="https://edu.gov.ru/" style={{fontSize: "10px"}}>
                    Министерство просвещения Российской Федерации
                </a>
            </div>
        </span>

            <div className={s.mobileHeader} style={active ? {borderBottomRightRadius: "0"} : {}}>
                <div onClick={() => {
                    !active ?
                        setActive(true) : setActive(false)
                }} className={s.menu} style={active ? {borderBottomLeftRadius: "0"} : {}}>
                    <MenuOutlined/>
                </div>
                <div className={s.ppms} style={{margin: "0 auto"}}>
                    <a href="/"><img src={PMPKLogo} alt="ПМПК"/></a>
                </div>

            </div>

        </header>
        {active ? <BigMenu/> : <>
        </>}
    </div>
}
export default MobileHeader

