import s from "./NewsPages.module.css"
import img from "../../assets/News/News260621.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News260621 = () => {

    return (
        <div className={s.main}>
            <h1>
                1-2 июля состоится Съезд IX Съезд руководителей организаций для детей-сирот и детей, оставшихся без
                попечения родителей, и руководителей органов исполнительной власти субъектов Российской Федерации,
                осуществляющих управление в сфере опеки и попечительства в отношении несовершеннолетних граждан
            </h1>
            <div className={s.date}>
                26 июня 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    1 – 2 июля 2021 г. в режиме онлайн-трансляции состоится IX Съезд руководителей организаций для
                    детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов исполнительной
                    власти субъектов Российской Федерации, осуществляющих управление в сфере опеки и попечительства в
                    отношении несовершеннолетних граждан (далее соответственно – Съезд, организации для детей-сирот).
                    <p></p>
                    Это ежегодное мероприятие, организуемое Минпросвещения России в рамках методического обеспечения
                    вопроса реализации государственной политики в сфере деятельности организаций для детей-сирот.
                    <p></p>
                    Обеспечение проведения Съезда осуществляется ФГБУ «Центр защиты прав и интересов детей».
                    <p></p>
                    Ключевые темы Съезда:
                    <p></p>
                    1. Переход к единой модели подчиненности организаций для детей-сирот и органов опеки и
                    попечительства в
                    субъектах Российской Федерации: достигнутые результаты и вопросы, требующие решения.
                    <p></p>
                    2. Организация воспитательной работы, подготовка и реализация воспитательных программ в организациях
                    для детей-сирот.
                    <p></p>
                    3. Достижение ключевых показателей Комплекса мер по повышению эффективности функционирования
                    механизмов
                    реализации, соблюдения и защиты прав и законных интересов детей, проживающих в детских
                    домах-интернатах, а также детей, помещенных под надзор
                    в организации для детей-сирот, в целях качественного улучшения
                    их жизни.
                    <p></p>
                    Ключевые темы Съезда будут обсуждаться в формате конференции, круглых столов. В ходе мероприятия для
                    руководителей организаций для детей-сирот будут организованы мастер-классы по актуальным вопросам в
                    сфере защиты прав и интересов детей.
                    <p></p>
                    В мероприятии примут участие представители Аппарата Уполномоченного при Президенте Российской
                    Федерации по правам ребенка, палат Федерального Собрания, федеральных органов исполнительной власти,
                    делегации из всех субъектов Российской Федерации, а также ведущие эксперты в сфере защиты прав и
                    интересов детей и представители некоммерческих организаций.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News260621
