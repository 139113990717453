import s from "./Main.module.css"
const RussianNews = () => {
    return (
        <div className={s.main}>
            <h1>Тематическая лента новостей страны</h1>
            <div className={s.rNews}>
                Страница находится в разработке!
                {/*<ol>*/}
                {/*    <li>*/}
                {/*        <a href="/">*/}
                {/*            Новость с большим описанием для проверки переноса*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="/">*/}
                {/*            Новость*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="/">*/}
                {/*            Новость*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="/">*/}
                {/*            Новость*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="/">*/}
                {/*            Новость*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <a href="/">*/}
                {/*            Новость*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*</ol>*/}
            </div>
        </div>
    )
}
export default RussianNews