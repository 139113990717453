import s from "./NewsPages.module.css"
import img from "../../assets/News/News221222.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News221222 = () => {

    return (
        <div className={s.main}>
            <h1>
                Вебинар «Возможности медиации при урегулировании семейных конфликтов».
            </h1>
            <div className={s.date}>
                22 декабря 2022
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    26 декабря 2022 г. в 10:00 по московскому времени состоится вебинар для специалистов органов опеки и
                    попечительства по теме: «Возможности медиации при урегулировании семейных конфликтов».
                    <p></p>
                    В качестве ведущего приглашен международный семейный медиатор, тренер медиаторов, аналитик отдела
                    медиации ФГБУ «Центр защиты прав и интересов детей» Тюльканов Сергей Леонидович, который расскажет о
                    семейных конфликтах: причинах, последствиях и способах их разрешения с помощью медиации. В ходе
                    вебинара Сергей Леонидович рассмотрит примеры из личной практики.
                    <p></p>
                    Регистрация участников доступна по <a href="https://events.webinar.ru/48429317/427805436">ссылке</a>.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News221222
