import s from "./NewsPages.module.css"
import img from "../../assets/News/News311023.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News311023 = () => {

    return (
        <div className={s.main}>
            <h1>
                Обучение по медиации и восстановительным технологиям
            </h1>
            <div className={s.date}>
                31 октября 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Если у вас есть желание и необходимость понимать и разрешать конфликты мирным путем, выстраивать добрые и доверительные отношения с учениками и их родителями, а также организовать в школе службу медиации (примирения), то этот курс для вас!


                    <p></p>
                    Наш Центр приглашает на программу повышения квалификации «Медиативно-восстановительные технологии в образовательной организации», 36 академических часов.
                    <p></p>


                    <strong>Период обучения:</strong> с 28 ноября по 25 декабря 2023 года

                    <p></p>
                    <strong>Формат обучения:</strong>
                    <br/>
                    Онлайн занятия.
                    <br/>
                    Все занятия будут проводиться с личным участием преподавателя.
                    <br/>
                    В рамках программы предусмотрен широкий блок по отработке медиативно-восстановительных технологий и практике
                    проведения медиативно-восстановительной беседы.

                    <p></p>
                    После прохождения обучения, вы получите <strong>удостоверение о повышение
                    квалификации</strong> установленного образца.
                    <p></p>

                    Преподаватель:
                    <br/>
                    <strong>Анатолий Андреевич Козлов</strong>, медиатор, педагог-психолог, сотрудник отдела медиации ФГБУ
                    «Центр защиты прав и
                    интересов детей», член Ассоциации профессиональных медиаторов «Единство».
                    <p></p>

                    <strong>Эта программа для вас, если вы:</strong>
                    <ul>


                        <li>специалист службы медиации или примирения;</li>
                        <li>сотрудник образовательной организации;</li>
                        <li>сотрудник КДН и ЗП или органа опеки и попечительства;</li>
                        <li>желаете ближе познакомиться с медиативно-восстановительным подходом и узнать об особенностях его
                            применения в работе с несовершеннолетними и в условиях образовательной организации
                        </li>
                    </ul>
                    <p></p>
                    <strong>После прохождения обучения вы сможете:</strong>
                    <ul>
                        <li>разбираться в основах медиативно-восстановительного подхода и конфликтологического знания;</li>
                        <li>проводить медиативно-восстановительную беседу между обучающимися, коллегами, родителями;</li>
                        <li>использовать медиативные и восстановительные технологии, такие как: «Петля понимания», «Активное
                            слушанье»,
                            «Рефрейминг»;
                        </li>
                        <li>понимать возможности создания и развития службы медиации или примирения.</li>
                    </ul>
                    <p></p>
                    Программа включает в себя преподавательский опыт сотрудников отдела медиации ФГБУ «Центр защиты прав и
                    интересов детей», а также опыт в области консультативной, экспертной и организационно-методической работы по
                    развитию системы служб медиации и примирения в Российской Федерации.
                    <p></p>

                    <strong>Стоимость обучения:</strong> 10 000 рублей

                    <p></p>
                    <strong><a href="https://fcprc.ru/education/registration" >Записаться на курс повышения
                        квалификации</a></strong>
                    <p></p>

                    Программа разработана в соответствии с профессиональным стандартом «Педагог (педагогическая деятельность в
                    сфере дошкольного, начального общего, основного общего, среднего общего образования) (воспитатель,
                    учитель)».
                    <p></p>

                    <strong><a href="https://dpo.fcprc.ru/course/медиация/">Подробнее о
                        курсе</a></strong>

                    <p></p>
                    ФГБУ «Центр защиты прав и интересов детей» Министерства просвещения Российской Федерации разрабатывает и
                    проводит <a href="https://dpo.fcprc.ru" >курсы повышения
                    квалификации</a> для специалистов в сфере защиты прав и интересов детей по вопросам:
                    <ul>
                        <li>опеки и попечительства</li>
                        <li>медиации и восстановительных технологий</li>
                        <li>помощи обучающимся с ограниченными возможностями здоровья, с инвалидностью</li>
                        <li>профилактики девиантного поведения несовершеннолетних</li>
                    </ul>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News311023
