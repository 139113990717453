import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs2.png"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs2 = () => {

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Методические рекомендации для специалистов организаций для детей-сирот и служб
                сопровождения замещающих семей по социальной адаптации детей-сирот: как подготовить ребенка к
                самостоятельной жизни
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    27 апреля 2020 г. состоялся вебинар для специалистов организаций для детей-сирот и детей, оставшихся
                    без попечения родителей, и служб сопровождения замещающих семей «Методические рекомендации для
                    специалистов организаций для детей-сирот и служб сопровождения замещающих семей по социальной
                    адаптации детей-сирот: как подготовить ребенка к самостоятельной жизни».
                    <p></p>
                    Доктор педагогических наук, профессор Сергей Владимирович Тетерский представил комплект методических
                    материалов для повышения компетентности специалистов, работающих с детьми-сиротами и детьми,
                    оставшимися без попечения родителей, с лицами из их числа.
                    <p></p>
                    Спикер дал практические рекомендации по вопросу о подготовке ребенка к самостоятельной жизни,
                    ответил на вопросы участников вебинара.
                    <p></p>
                    В вебинаре приняли участие 1258 слушателей из 73 регионов Российской Федерации.
                </div>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs2
