import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs27.jpg";
import { WebShareBlock } from "./Webs1";
import { FileUrl } from "./Webs26";

const Webs27 = () => {
  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Вебинар по вопросам, связанным с организацией и
        осуществлением совместного нахождения с ребенком из организации для
        детей-сирот и детей, оставшихся без попечения родителей, при оказании
        медицинской помощи в стационарных условиях (1 марта 2022 г., второй
        день)
      </h1>
      <div className={s.date}>01.03.2022</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div style={{ paddingTop: "15px" }}>
          <FileUrl url="/" title="Видео и материалы вебинара" />
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "20px",
            }}
          >
            Презентации участников
          </div>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/alshanskaja-e.l._ePu7WiL.pdf"
            title="Альшанская Е.Л."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/vahnina-z.a._6ajyGIk.pdf"
            title="Вахнина З.А."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/davydova-n.g._1w2ktmz.pdf"
            title="Давыдова Н.Г."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/poljanskaja-i.v._JIpPZXI.pdf"
            title="Полянская И.В."
          />
        </div>
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs27;
