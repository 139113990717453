import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Report2024_2 = () => {
  const [respData, setData] = useState({
    o1: "",
    o11: "",
    o12: "",
    o13: "",
    o14: "",
    o15: "",
    o16: "",
    o17: "",
    o171: "",
    o18: "",
    o19: "",
    o110: "",
    o111: "",
    o112: "",
    o113: "",
    o1131: "",
    o2: "",
    comm: "",
    region: "",
  });
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id) {
        let config = {
          method: "GET",
          maxBodyLength: Infinity,
          url: `https://frcopeca.ru/api/houseupd?author=${user.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        };
        try {
          const response = await axios.request(config);
          if (response.data[0]) {
            setData(response.data[0]);
          }
        } catch (error) {
          setData({
            o1: "",
            o11: "",
            o12: "",
            o13: "",
            o14: "",
            o15: "",
            o16: "",
            o17: "",
            o171: "",
            o18: "",
            o19: "",
            o110: "",
            o111: "",
            o112: "",
            o113: "",
            o1131: "",
            o2: "",
            comm: "",
            region: "",
          });
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className={s.main}>
      <h1>Отчет за 2024 год</h1>
      <ReportForm respData={respData} />
      <AddForm />
    </div>
  );
};
export default Report2024_2;

const ReportForm = ({ respData }) => {
  return (
    <div>
      <FormDetails title="Второй отчет">
        <Report2 respData={respData} />
      </FormDetails>
    </div>
  );
};

const Report2 = ({ respData }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // const readOnly = respData.статус === 'На утверждении' || respData.статус === 'Опубликовано'
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        o1: respData.o1,
        o11: respData.o11,
        o12: respData.o12,
        o13: respData.o13,
        o14: respData.o14,
        o15: respData.o15,
        o16: respData.o16,
        o17: respData.o17,
        o171: respData.o171,
        o18: respData.o18,
        o19: respData.o19,
        o110: respData.o110,
        o111: respData.o111,
        o112: respData.o112,
        o113: respData.o113,
        o1131: respData.o1131,
        o2: respData.o2,
        comm: respData.комментарии,
        region: respData.регион,
      }}
      onSubmit={(values) => {
        let data2 = new FormData();
        data2.append("o1", values.o1);
        data2.append("o11", values.o11);
        data2.append("o12", values.o12);
        data2.append("o13", values.o13);
        data2.append("o14", values.o14);
        data2.append("o15", values.o15);
        data2.append("o17", values.o17);
        data2.append("o171", values.o171);
        data2.append("o18", values.o18);
        data2.append("o16", values.o16);
        data2.append("o19", values.o19);
        data2.append("o110", values.o110);
        data2.append("o111", values.o111);
        data2.append("o112", values.o112);
        data2.append("o113", values.o113);
        data2.append("o1131", values.o1131);
        data2.append("o2", values.o2);
        data2.append("регион", values.region);
        data2.append("author", user.id);
        let config = {
          method: respData.o1 ? "patch" : "POST",
          maxBodyLength: Infinity,

          url: `https://frcopeca.ru/api/houseupd`,
          //url: `https://frcopeca.ru/api/houseupd`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
          data: data2,
        };
        axios.request(config).finally(() => navigate("/profile"));
      }}
    >
      {(errors, touched) => (
        <Form className={s.reportForm}>
          <label htmlFor="Сведения">
            Сведения по фактическому проживанию лиц из числа детей-сирот и
            детей, оставшихся без попечения родителей, до предоставления им
            жилого помещения
          </label>
          <p></p>
          <label htmlFor="Сокращенные понятия">
            Сокращенные понятия, применяемые в мониторинге: лица из числа
            детей-сирот – лица из числа детей-сирот и детей, оставшихся без
            попечения родителей, лица, которые относились к категории
            детей-сирот и детей, оставшихся без попечения родителей, в возрасте
            старше 23 лет;
          </label>

          <p></p>
          <label htmlFor="region">Регион</label>
          <Field
            readOnly={!!respData.регион}
            required={true}
            id="region"
            name="region"
          />
          <p></p>
          <label htmlFor="o1">
            1. Численность лиц из числа детей-сирот, у которых право на
            обеспечение жилыми помещениями возникло, но не реализовано по
            состоянию на 30 июня 2024 г. (сумма строк
            1.1+1.2+1.3+1.4+1.5+1.6+1.7+1.8+1.9+1.10+1.11+1.12+1.13)
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o1"
            name="o1"
            type="number"
          />

          <label htmlFor="o11">
            1.1 из них (из строки 1): органами исполнительной власти субъекта
            РФ/органами местного самоуправления предоставлены жилые помещения во
            временное пользование, включая жилые помещения маневренного /иного
            жилищного фонда*
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o11"
            name="o11"
            type="number"
          />

          <label htmlFor="o12">
            1.2 проживают в организациях социального обслуживания
            (дома-интернаты, дома ночного пребывания, центры социальной
            адаптации для лиц без определенного места жительства, социальные
            гостиницы и др.)*
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o12"
            name="o12"
            type="number"
          />

          <label htmlFor="o13">
            1.3 проживают в организациях системы здравоохранения*
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o13"
            name="o13"
            type="number"
          />

          <label htmlFor="o14">
            1.4 проживают в общежитиях профессиональных образовательных
            организаций *
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o14"
            name="o14"
            type="number"
          />

          <label htmlFor="o15">
            1.5 проживают в организациях для детей-сирот и детей, оставшихся без
            попечения родителей*
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o15"
            name="o15"
            type="number"
          />

          <label htmlFor="o16">
            1.6 проживают временно на безвозмездной основе в жилых помещениях,
            принадлежащих НКО/частным лицам
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o16"
            name="o16"
            type="number"
          />

          <label htmlFor="o17">
            1.7 проживают в арендованных жилых помещениях
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o17"
            name="o17"
            type="number"
          />

          <label htmlFor="o171">
            1.7.1 из них (из строки 1.7) предоставлена компенсация расходов на
            оплату аренды/найма жилого помещения, в котором они временно
            проживают, из средств бюджета субъекта РФ/местного бюджета*
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o171"
            name="o171"
            type="number"
          />

          <label htmlFor="o18">1.8 проживают у родственников (знакомых)</label>
          <Field
            readOnly={false}
            required={true}
            id="o18"
            name="o18"
            type="number"
          />

          <label htmlFor="o19">
            1.9 проживают у бывших опекунов (попечителей), приемных родителей
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o19"
            name="o19"
            type="number"
          />

          <label htmlFor="o110">1.10 находятся в местах лишения свободы</label>
          <Field
            readOnly={false}
            required={true}
            id="o110"
            name="o110"
            type="number"
          />

          <label htmlFor="o111">
            1.11 проходят службу в вооруженных силах Российской Федерации
            (включая участие в СВО)
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o111"
            name="o111"
            type="number"
          />

          <label htmlFor="o112">
            1.12 фактическое место жительства/пребывания неизвестно
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o112"
            name="o112"
            type="number"
          />

          <label htmlFor="o113">
            1.13 проживают в других местах жительства/пребывания
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o113"
            name="o113"
            type="number"
          />

          <label htmlFor="o1131">
            1.13.1 из них (из строки 1.13): при наличии других мест
            жительства/пребывания - укажите эти места и численность лиц там
            проживающих
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o1131"
            name="o1131"
            type="number"
          />

          <label htmlFor="o2">
            2. Численность лиц из числа детей-сирот зарегистрированных по месту
            жительства в местных администрациях (из строки 1)
          </label>
          <Field
            readOnly={false}
            required={true}
            id="o2"
            name="o2"
            type="number"
          />

          <button type="submit">Submit</button>
        </Form>
      )}
    </Formik>
  );
};

const FormDetails = (props) => {
  const { children, title } = props;
  return (
    <div className={s.formDetails}>
      <details open={true}>
        <summary>
          {title}
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              float: "left",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            className={s.controlIconExpand}
          >
            <path
              d="M7 10L12 15L17 10"
              stroke="#2468b2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              float: "left",
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            className={s.controlIconClose}
          >
            <path
              d="M17 15L12 10L7 15"
              stroke="#2468b2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </summary>
        {children}
      </details>
    </div>
  );
};

const AddForm = () => {
  return (
    <div className={s.addForm}>
      <NavLink to="/add-form">Добавить новый отчет</NavLink>
    </div>
  );
};
