import s from "./NewsPages.module.css"
import img from "../../assets/News/News270624.jpg"
import { AdviceBlock, ShareBlock } from "./News170621";

const News270624 = () => {

    return (
        <div className={s.main}>
            <h1>
                Объявлены финалисты Всероссийского конкурса в сфере профессиональной деятельности специалистов органов
                опеки и попечительства в отношении несовершеннолетних граждан «На стороне ребенка»
            </h1>
            <div className={s.date}>
                27 июля 2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt="" />
                </div>
                <div>
                    По итогам полуфинала Организатором Конкурса ФГБУ «Центр защиты прав и интересов детей» сформирован
                    список участников по каждой номинации, допущенных к участию в финале.
                    <p></p>
                    Всего в финале Конкурса примут участие <strong>20</strong> специалистов и <strong>12</strong> органов
                    опеки и попечительства
                    <p></p>
                    Финал Конкурса пройдет в Москве 15 октября 2024 года.
                    <p></p>
                    Награждение победителей и лауреатов Конкурса состоится 17-18 октября 2024 г. в Москве на ежегодном
                    Всероссийском совещании руководителей органов опеки и попечительства.


                </div>
                <ShareBlock />
            </div>
            <AdviceBlock />
        </div>
    )
}
export default News270624;
