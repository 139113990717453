import s from "./NewsPages.module.css"
import img from "../../assets/News/News310523.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News310523 = () => {

    return (
        <div className={s.main}>
            <h1>
                Открывается набор в магистратуру по профилю «Опека и попечительство в отношении несовершеннолетних»
            </h1>
            <div className={s.date}>
                31 мая 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    6 июня в 18:00 состоится онлайн-презентация единственной в России программы магистратуры «Опека и
                    попечительство в отношении несовершеннолетних» факультета «Психология образования» ФГБОУ ВО
                    «Московский государственный психолого-педагогический университет».
                    <p></p>
                    В рамках мероприятия Вероника Нисоновна Ослон, руководитель программы, кандидат психологических
                    наук, доцент, профессор кафедры «Возрастная психология имени профессора Л.Ф. Обуховой» факультета
                    «Психология образования» ФГБОУ ВО МГППУ, расскажет о целях, задачах, содержании магистерской
                    программы, условиях обучения.
                    <p></p>
                    Подробнее о программе (<a href="/file">скачать</a>)
                    <p></p>
                    Презентация будет проходить в формате вебинара.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News310523
