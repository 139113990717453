import s from "./NewsPages.module.css"
import img from "../../assets/News/News020322.png"
import {AdviceBlock, ShareBlock} from "./News170621";

const News020322 = () => {

    return (
        <div className={s.main}>
            <h1>
                Курсы повышения квалификации для руководителей и специалистов органов опеки и попечительства
            </h1>
            <div className={s.date}>
                2 марта 2022
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Автономная некоммерческая организация Центр развития социальных проектов (АНО ЦРСП) проводит курсы
                    повышения квалификации для руководителей и специалистов органов опеки и попечительства по программе:
                    «Практическая компетентность в сфере опеки и попечительства в отношении несовершеннолетних: учимся
                    на кейсах».
                    <p></p>
                    Сроки проведения онлайн-курсов программы:
                    <ul>
                        <li>11 апреля – 27 апреля 2022 г.</li>
                        <li>23 мая – 08 июня 2022 г.</li>
                        <li>3 октября – 19 октября 2022 г.</li>
                        <li>14 ноября – 30 ноября 2022 г.</li>
                    </ul>

                    В состав преподавателей курсов входят ведущие специалисты в сфере опеки и попечительства в отношении
                    несовершеннолетних граждан, защиты прав детей, психологии. Научный руководитель программы – Семья
                    Г.В.
                    <p></p>
                    Программа рассчитана на 72 академических часа, включающих онлайн лекции, самостоятельную работу и
                    выполнение заданий слушателями.
                    <p></p>
                    С программой онлайн-курсов можно ознакомиться в <a href="/file">письме АНО ЦРСП</a>.
                    <p></p>
                    По вопросам, связанным с условиями участия в программе, регистрацией и оплатой, можно связаться по
                    телефонам: +7 (495) 363-35-80, +7 (985) 088-56-72, а также по электронной почте: <a
                    href="mailto:kursy@centrrsp.ru">kursy@centrrsp.ru</a>.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News020322
