import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Header from "./Components/Header/Header";
import { useEffect, useState } from "react";
import Main from "./Components/Pages/Main";
import News from "./Components/Pages/News";
import Contacts from "./Components/Pages/Contacts";
import NavigatorOOIP from "./Components/Pages/NavigatorOOIP";
import Navigator from "./Components/Pages/Navigator";
import FZ from "./Components/FilesPages/FZ";
import Hotline from "./Components/Pages/Hotline";
import Partners from "./Components/Partners/Partners";
import Letters1 from "./Components/FilesPages/Letters1";
import Letters2 from "./Components/FilesPages/Letters2";
import Documents1 from "./Components/FilesPages/Documents1";
import MA from "./Components/FilesPages/MA";
import Ukazi from "./Components/FilesPages/Ukazi";
import APRF from "./Components/FilesPages/APRF";
import AFOIV from "./Components/FilesPages/AFOIV";
import MetodMaterials from "./Components/FilesPages/MetodMaterials";
import Webinars from "./Components/Pages/Webinars";
import RussianNews from "./Components/Pages/RussianNews";
import AboutUs from "./Components/Pages/AboutUs";
import Comics from "./Components/Pages/Comics";
import TechSupport from "./Components/Pages/TechSupport";
import News170621 from "./Components/News/News170621";
import News240621 from "./Components/News/News240621";
import News260621 from "./Components/News/News260621";
import News070721 from "./Components/News/News070721";
import News140721 from "./Components/News/News140721";
import News200721 from "./Components/News/News200721";
import News221121 from "./Components/News/News221121";
import News101221 from "./Components/News/News101221";
import News020322 from "./Components/News/News020322";
import News160822 from "./Components/News/News160822";
import News080922 from "./Components/News/News080922";
import News131222 from "./Components/News/News131222";
import News221222 from "./Components/News/News221222";
import News230323 from "./Components/News/News230323";
import News310523 from "./Components/News/News310523";
import News040723 from "./Components/News/News040723";
import News110723 from "./Components/News/News110723";
import News130723 from "./Components/News/News130723";
import MetodMaterialsPage from "./Components/MeodMaterialsPage/MetodMaterialsPage";
import Webs1 from "./Components/Webs/Webs1";
import Webs2 from "./Components/Webs/Webs2";
import Webs3 from "./Components/Webs/Webs3";
import Webs4 from "./Components/Webs/Webs4";
import Webs5 from "./Components/Webs/Webs5";
import Webs6 from "./Components/Webs/Webs6";
import Webs7 from "./Components/Webs/Webs7";
import Webs8 from "./Components/Webs/Webs8";
import Webs9 from "./Components/Webs/Webs9";
import Webs10 from "./Components/Webs/Webs10";
import Webs11 from "./Components/Webs/Webs11";
import Webs12 from "./Components/Webs/Webs12";
import Webs13 from "./Components/Webs/Webs13";
import Webs14 from "./Components/Webs/Webs14";
import Webs15 from "./Components/Webs/Webs15";
import Webs16 from "./Components/Webs/Webs16";
import Webs17 from "./Components/Webs/Webs17";
import Webs18 from "./Components/Webs/Webs18";
import Webs19 from "./Components/Webs/Webs19";
import Webs20 from "./Components/Webs/Webs20";
import Webs21 from "./Components/Webs/Webs21";
import Webs22 from "./Components/Webs/Webs22";
import Webs23 from "./Components/Webs/Webs23";
import Webs24 from "./Components/Webs/Webs24";
import Webs25 from "./Components/Webs/Webs25";
import Webs26 from "./Components/Webs/Webs26";
import Webs27 from "./Components/Webs/Webs27";
import Webs28 from "./Components/Webs/Webs28";
import Webs29 from "./Components/Webs/Webs29";
import Webs30 from "./Components/Webs/Webs30";
import Webs31 from "./Components/Webs/Webs31";
import Webs32 from "./Components/Webs/Webs32";
import Webs33 from "./Components/Webs/Webs33";
import Navigator3 from "./Components/Pages/Navigator3";
import Footer from "./Components/Footer/Footer";
import News051023 from "./Components/News/News051023";
import News091023 from "./Components/News/News091023";
import News201023 from "./Components/News/News201023";
import News311023 from "./Components/News/News311023";
import News201123 from "./Components/News/News201123";
import News241123 from "./Components/News/News241123";
import Profile from "./Components/Cabinet/Profile";
import Report2024 from "./Components/Cabinet/Report2024";
import Report2023 from "./Components/Cabinet/Report2023";
import Report2022 from "./Components/Cabinet/Report2022";
import Report2021 from "./Components/Cabinet/Report2021";
import Report2020 from "./Components/Cabinet/Report2020";
import { Provider, useDispatch, useSelector } from "react-redux";
import Login from "./Components/Cabinet/Login";
import Webs34 from "./Components/Webs/Webs34";
import AddFormPage from "./Components/Cabinet/AddFormPage";
import Concurs from "./Components/Pages/Viktorina";
import { LOGIN_FAILURE, LOGIN_SUCCESS } from "./Redux/types";
import { setUserData } from "./Redux/user/userActions";
import { store } from "./Redux/store";
import Report2024_2 from "./Components/Cabinet/Report2024_2";
import Report2024_3 from "./Components/Cabinet/Report2024_3";
import AllReportsPage from "./Components/Cabinet/AllReportsPage";
import SupportForm from "./Components/Cabinet/SupportForm";
import Report2024_4 from "./Components/Cabinet/Report2024_4";
import Report2024_5 from "./Components/Cabinet/Report2024_5";
import Report2024_6 from "./Components/Cabinet/Report2024_6";
import Report2024_7 from "./Components/Cabinet/Report2024_7";
import News300124 from "./Components/News/News300124";
import News020424 from "./Components/News/News020424";
import News270624 from "./Components/News/News270624";
import Webs35 from "./Components/Webs/Webs35";

function App() {
  const [styl, setStyle] = useState("App");
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    const fetchData = async () => {
      if (
        localStorage.getItem("access") &&
        localStorage.getItem("access") !== "undefined"
      ) {
        dispatch({ type: LOGIN_SUCCESS });
        let config = {
          method: "GET",
          maxBodyLength: Infinity,
          url: "https://frcopeca.ru/api/auth/users/me/",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        };
        dispatch(setUserData(config));
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    };
    fetchData();
  }, [isAuthenticated]);

  return (
    <>
      <div>
        <Header styl={styl} setStyle={setStyle} />
        <div>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/category/novosti" element={<News />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route
              path="/reestr-dopolnitelny-programm-ooop"
              element={<NavigatorOOIP />}
            />
            <Route path="/navigator" element={<Navigator />} />
            <Route
              path="/documents-category/federalnye-zakony"
              element={<FZ />}
            />
            <Route
              path="/goryachaya-liniya-po-voprosam-realizatsii-zhilishhnyh-prav-detej-sirot"
              element={<Hotline />}
            />
            <Route path="/partners" element={<Partners />} />
            <Route
              path="/documents-category/pisma-federalnyh-organov-ispolnitelnoj-vlasti"
              element={<Letters1 />}
            />
            <Route
              path="/documents-category/pisma-federalnoj-sluzhby-sudebnyh-pristavov"
              element={<Letters2 />}
            />
            <Route
              path="/documents-category/dokumenty-vysshih-sudov-rf"
              element={<Documents1 />}
            />
            <Route
              path="/documents-category/mezhdunarodnye-akty"
              element={<MA />}
            />
            <Route
              path="/documents-category/ukazy-prezidenta-rossijskoj-federatsii"
              element={<Ukazi />}
            />
            <Route
              path="/documents-category/akty-prezidenta-i-pravitelstva-rf"
              element={<APRF />}
            />
            <Route path="/documents-category/akty-foiv" element={<AFOIV />} />
            <Route path="/methodical-materials" element={<MetodMaterials />} />
            <Route path="/webinars" element={<Webinars />} />
            <Route
              path="/tematicheskaja-lenta-novostej-strany/"
              element={<RussianNews />}
            />
            <Route path="/about" element={<AboutUs />} />
            <Route
              path="/komiks-po-finansovoi-gramotnosti-12-plus"
              element={<Comics />}
            />
            <Route path="/tehnicheskaja-podderzhka" element={<TechSupport />} />
            <Route
              path="/metodicheskie-materialy-konstruktor-tipovoj-programmy-soprovozhdenija-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-lic-iz-chisla-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditele"
              element={<News170621 />}
            />
            <Route
              path="/post-reliz-o-provedenii-vebinara-ispolzovanie-mediativnyh-tehnologij-v-dejatelnosti-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej"
              element={<News240621 />}
            />
            <Route
              path="/ix-sezd-rukovoditelej-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-i-rukovoditelej-organov-ispolnitelnoj-vlasti-subektov-rossijskoj-federacii-osushhestvljajushhih-upravl"
              element={<News260621 />}
            />
            <Route
              path="/post-reliz-o-provedenii-ix-sezda-rukovoditelej-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-i-rukovoditelej-organov-ispolnitelnoj-vlasti-subektov-rossijskoj-federacii-os"
              element={<News070721 />}
            />
            <Route
              path="/vebinar-dlja-specialistov-organov-opeki-i-popechitelstva-po-voprosam-dejatelnosti-organov-opeki-i-popechitelstva-svjazannoj-s-rassmotreniem-sporov-o-vospitanii-detej"
              element={<News140721 />}
            />
            <Route
              path="/obuchajushhij-vebinar-po-voprosam-rassmotrenija-sporov-o-vospitanii-detej"
              element={<News200721 />}
            />
            <Route
              path="/minprosveshhenija-rossii-provedet-vserossijskoe-soveshhanie-rukovoditelej-organov-opeki-i-popechitelstva-i-forum-priemnyh-semej"
              element={<News221121 />}
            />
            <Route
              path="/vserossijskoe-soveshhanie-rukovoditelej-organov-opeki-i-popechitelstva-i-forum-priemnyh-semej"
              element={<News101221 />}
            />
            <Route
              path="/kursy-povyshenija-kvalifikacii-dlja-rukovoditelej-i-specialistov-organov-opeki-i-popechitelstva"
              element={<News020322 />}
            />
            <Route
              path="/obsuzhdenie-izmenenij-v-professionalnyj-standart-specialist-organa-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih"
              element={<News160822 />}
            />
            <Route
              path="/na-storone-rebenka-pervyj-vserossijskij-konkurs-specialistov-organov-opeki-i-popechitelstva"
              element={<News080922 />}
            />
            <Route
              path="/vserossijskoe-soveshhanie-rukovoditelej-organov-opeki-i-popechitelstva-nagrazhdenie-pobeditelej-vserossijskogo-konkursa-v-sfere-professionalnoj-dejatelnosti-specialistov-organov-opeki-i-popechitelstv"
              element={<News131222 />}
            />
            <Route
              path="/vebinar-vozmozhnosti-mediacii-pri-uregulirovanii-semejnyh-konfliktov"
              element={<News221222 />}
            />
            <Route
              path="/obuchajushhij-vebinar-po-teme-ispolnenie-zakonodatelstva-ob-opeke-i-popechitelstve-v-otnoshenii-nesovershennoletnih-grazhdan"
              element={<News230323 />}
            />
            <Route
              path="/otkryvaetsja-nabor-v-magistraturu-po-profilju-opeka-i-popechitelstvo-v-otnoshenii-nesovershennoletnih"
              element={<News310523 />}
            />
            <Route
              path="/6-7-ijulja-2023-g-sostoitsja-xi-vserossijskij-sezd-rukovoditelej-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej"
              element={<News040723 />}
            />
            <Route
              path="/6-7-ijulja-2023-g-v-g-ufe-proshel-xi-vserossijskij-sezd-rukovoditelej-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej"
              element={<News110723 />}
            />
            <Route
              path="/fond-timchenko-nachal-priem-zajavok-na-konkurs-tuda-gde-semja-napravlennyj-na-vyjavlenie-i-podderzhku-dejstvujushhih-jeffektivnyh-instrumentov-i-tehnologij-raboty-s-ujazvimymi-semjami-s-detmi-na-malyh"
              element={<News130723 />}
            />

            <Route
              path="/materials/sbornik-obrazcov-dokumentov-dlja-specialistov-organov-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih-grazhdan/"
              element={
                <MetodMaterialsPage
                  urls={[
                    {
                      fileTitle: "СБОРНИК ОБРАЗЦОВ",
                      url: "https://frcopeca.ru/media/media/behavior/sbornik-obrazcov-1_sDF2pF4_EmkUGmC.docx",
                    },
                  ]}
                  title="Сборник образцов документов для специалистов органов опеки и попечительства в отношении несовершеннолетних граждан"
                />
              }
            />
            <Route
              path="/materials/metodicheskie-rekomendacii-o-pravilah-kommunikativnogo-povedenija-vkljuchaja-rechevuju-kommunikaciju-specialistov-organov-opeki-i-popechitelstva"
              element={
                <MetodMaterialsPage
                  urls={[
                    {
                      fileTitle: "Методические рекомендации (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/metodicheskie-rekomendacii-o-pravilah-kom.pdf",
                    },
                    {
                      fileTitle:
                        "Инфографический материал к методическим рекомендациям (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/infografika_kommunikativnoe_povedenie_Pd62xTX.pdf",
                    },
                  ]}
                  title="Методические рекомендации о правилах коммуникативного поведения, включая речевую коммуникацию, специалистов органов опеки и попечительства"
                />
              }
            />
            <Route
              path="/materials/metodicheskie-rekomendacii-po-okazaniju-semjam-s-detmi-nahodjashhimsja-v-socialno-opasnom-polozhenii-pedagogicheskoj-psihologicheskoj-i-juridicheskoj-pomoshhi-realizacii-programm-napravlennyh-na-podde"
              element={
                <MetodMaterialsPage
                  urls={[
                    {
                      fileTitle: "Методические рекомендации (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/METOD-1.PDF",
                    },
                  ]}
                  title="Методические рекомендации по оказанию семьям с детьми, находящимся в социально опасном положении, педагогической, психологической и юридической помощи, реализации программ, направленных на поддержку и укрепление родительства и детско-родительских отношений"
                />
              }
            />
            <Route
              path="/materials/chek-list-dejstvij-pri-prinjatii-reshenija-organom-opeki-i-popechitelstva-ob-ustrojstve-rebenka-detej-ostavshegosja-bez-popechenija-roditelej-v-semju-na-vospitanie"
              element={
                <MetodMaterialsPage
                  urls={[
                    {
                      fileTitle: "Чек-лист (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/chek-list_75SZg7b_RMiSBh8.pdf",
                    },
                  ]}
                  title="Чек-лист действий при принятии решения органом опеки и попечительства об устройстве ребенка (детей), оставшегося без попечения родителей, в семью на воспитание"
                />
              }
            />
            <Route
              path="/materials/metodicheskie-rekomendacii2023"
              element={
                <MetodMaterialsPage
                  urls={[
                    {
                      fileTitle: "Методические рекомендации (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/metodicheskie-rekomendac.pdf",
                    },
                    {
                      fileTitle:
                        "Инфографические материалы по актуальным вопросам (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/infograficheskie_materialy_po_aktual.pdf",
                    },
                  ]}
                  title="Методические рекомендации по вопросам выдачи органами опеки и попечительства предварительных разрешений на осуществление имущественных прав ребенка"
                />
              }
            />
            <Route
              path="/materials/reestr-programm"
              element={
                <MetodMaterialsPage
                  urls={[
                    {
                      fileTitle: "Реестр программ (скачать)",
                      url: "https://frcopeca.ru/media/media/behavior/REESTR1.XLS",
                    },
                  ]}
                  title="Реестр программ, направленных на укрепление родительства и детско-родительских отношений, организацию работы с несовершеннолетними и (или) семьями, признанными находящимися в социально опасном положении"
                />
              }
            />
            <Route path="/navigator3" element={<Navigator3 />} />

            <Route
              path="/webinars/razvitie-samostoyatelnosti-detej/"
              element={<Webs1 />}
            />
            <Route
              path="/webinars/metodicheskie-rekomendatsii-dlya-spetsialistov-organizatsij-dlya-detej-sirot-i-sluzhb-soprovozhdeniya-zameshhayushhih-semej-po-sotsialnoj-adaptatsii-detej-sirot-kak-podgotovit-rebenka-k-samostoyatelno/"
              element={<Webs2 />}
            />
            <Route
              path="/webinars/effektivnaya-organizatsiya-uchebnoj-deyatelnosti-pri-podgotovke-k-ekzamenam-v-domashnih-usloviyah/"
              element={<Webs3 />}
            />
            <Route
              path="/webinars/8-pravil-bezopasnogo-obshheniya-s-podrostkami-kak-roditelyam-reagirovat-na-trudnoe-povedenie-rebenka/"
              element={<Webs4 />}
            />
            <Route
              path="/webinars/kak-organizovat-sovmestnuyu-igrovuyu-deyatelnost-roditelej-s-detmi-ot-1-goda-do-13-let-v-predelah-svoej-kvartiry/"
              element={<Webs5 />}
            />
            <Route
              path="/webinars/dialog-podrostka-s-roditelyami-prostoj-razgovor-na-slozhnye-temy/"
              element={<Webs6 />}
            />
            <Route
              path="/webinars/luchshie-regionalnye-praktiki-v-sfere-obespecheniya-detej-sirot-detej-ostavshihsya-bez-popecheniya-roditelej-i-lits-iz-ih-chisla-zhilymi-pomeshheniyami/"
              element={<Webs7 />}
            />
            <Route
              path="/webinars/o-luchshih-praktikah-po-podgotovke-grazhdan-k-priemu-na-vospitanie-v-semyu-rebenka-ostavshegosya-bez-popecheniya-roditelej/"
              element={<Webs8 />}
            />
            <Route
              path="/webinars/metodicheskie-rekomendatsii-dlya-spetsialistov-organizatsij-dlya-detej-sirot-i-sluzhb-soprovozhdeniya-zameshhayushhih-semej-po-sotsialnoj-adaptatsii-detej-sirot-kak-podgotovit-rebenka-k-samostoyatel-2/"
              element={<Webs9 />}
            />
            <Route
              path="/webinars/razvitie-samostoyatelnosti-detej-mmso/"
              element={<Webs10 />}
            />
            <Route
              path="/webinars/konstruktor-effektivnoj-kompleksnoj-pomoshhi-vypuskniku-organizatsii-dlya-detej-sirot-uspeshnyj-zhiznennyj-stsenarij/"
              element={<Webs11 />}
            />
            <Route
              path="/webinars/perehod-k-edinoj-vedomstvennoj-podchinennosti-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej/"
              element={<Webs12 />}
            />
            <Route
              path="/webinars/o-rezultatah-issledovanija-o-vremennom-pomeshhenii-detej-v-detskie-uchrezhdenija/"
              element={<Webs13 />}
            />
            <Route
              path="/webinars/organizacija-gendernogo-vospitanija-u-starshih-vospitannikov-organizacii-dlja-detej-sirot/"
              element={<Webs14 />}
            />
            <Route
              path="/webinars/chto-nuzhno-delat-chtoby-uvidet-rezultaty-svoego-truda/"
              element={<Webs15 />}
            />
            <Route
              path="/webinars/master-klass-rol-organizacii-dlja-detej-sirot-v-podgotovke-k-zhizni-v-seme/"
              element={<Webs16 />}
            />
            <Route
              path="/webinars/trudnoe-povedenie-u-podrostkov-vozmozhna-li-pomoshh-v-uslovijah-organizacii-dlja-detej-sirot/"
              element={<Webs17 />}
            />
            <Route
              path="/webinars/plenarnoe-zasedanie-maloe/"
              element={<Webs18 />}
            />
            <Route path="/webinars/plenarnoe-zasedanie/" element={<Webs19 />} />
            <Route
              path="/webinars/art-zachjot-po-rabote-s-tvorcheskimi-detmi-sirotami/"
              element={<Webs20 />}
            />
            <Route path="/webinars/kruglyj-stol-1/" element={<Webs21 />} />
            <Route path="/webinars/kruglyj-stol-2/" element={<Webs22 />} />
            <Route
              path="/webinars/o-vydache-organami-opeki-predvaritelnyh-razreshenij-na-osushhestvlenie-imushhestvennyh-prav-rebenka/"
              element={<Webs23 />}
            />
            <Route
              path="/webinars/obuchajushhij-vebinar-po-voprosam-rassmotrenija-sporov-o-vospitanii-detej/"
              element={<Webs24 />}
            />
            <Route
              path="/webinars/vebinar-dlja-specialistov-organov-opeki-i-popechitelstva-po-voprosam-podbora-ucheta-i-podgotovki-grazhdan-vyrazivshih-zhelanie-stat-usynoviteljami-opekunami-popechiteljami/"
              element={<Webs25 />}
            />
            <Route
              path="/webinars/vebinar-po-voprosam-svjazannym-s-organizaciej-i-osushhestvleniem-sovmestnogo-nahozhdenija-s-rebenkom-iz-organizacii-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-pri-okazanii-medic-2/"
              element={<Webs26 />}
            />
            <Route
              path="/webinars/vebinar-po-voprosam-svjazannym-s-organizaciej-i-osushhestvleniem-sovmestnogo-nahozhdenija-s-rebenkom-iz-organizacii-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej-pri-okazanii-medicin/"
              element={<Webs27 />}
            />
            <Route
              path="/webinars/x-vserossijskij-sezd-rukovoditelej-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej/"
              element={<Webs28 />}
            />
            <Route
              path="/webinars/vebinar-po-voprosam-svjazannym-s-ustrojstvom-na-vospitanie-v-semju-vich-inficirovannyh-detej/"
              element={<Webs29 />}
            />
            <Route
              path="/webinars/vebinar-po-voprosam-pravilnogo-napolnenija-egisso-v-chasti-formirovanija-spiskov-detej-sirot-detej-ostavshihsja-bez-popechenija-roditelej-i-lic-iz-ih-chisla-podlezhashhih-obespecheniju-zhilymi-pomeshh/"
              element={<Webs30 />}
            />
            <Route
              path="/webinars/vozmozhnosti-mediacii-pri-uregulirovanii-semejnyh-konfliktov/"
              element={<Webs31 />}
            />
            <Route
              path="/webinars/ispolnenie-zakonodatelstva-ob-opeke-i-popechitelstve-v-otnoshenii-nesovershennoletnih-grazhdan/"
              element={<Webs32 />}
            />
            <Route
              path="/webinars/vebinar-po-voprosam-oznakomlenija-s-obshhimi-polozhenijami-profilaktiki-sindroma-professionalnogo-vygoranija-i-imejushhejsja-praktikoj-organizacii-dokazatelnogo-analiza-i-ocenki-jeffektivnosti-praktik/"
              element={<Webs33 />}
            />
            <Route
              path="/webinars/vebinar-po-aktualnym-voprosam-zapolnenija-otcheta-opekuna-ili-popechitelja-nesovershennoletnego-podopechnogo/"
              element={<Webs34 />}
            />
            <Route
              path="/webinars/v-ijule-avguste-2024-g-provedeny-vebinary-dlja-specialistov-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej"
              element={<Webs35 />}
            />
            <Route path="/s-dnem-uchitelja/" element={<News051023 />} />
            <Route
              path="/utverzhden-novyj-professionalnyj-standart-specialist-organa-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih/"
              element={<News091023 />}
            />
            <Route path="/90177-2" element={<News201023 />} />
            <Route
              path="/obuchenie-po-mediacii-i-vosstanovitelnym-tehnologijam/"
              element={<News311023 />}
            />
            <Route
              path="/obuchenie-po-razresheniju-sporov-roditelej-svjazannyh-s-vospitaniem-detej-s-uchastiem-inostrannogo-jelementa/"
              element={<News201123 />}
            />
            <Route
              path="/sergej-kravcov-otkryl-vserossijskoe-soveshhanie-rukovoditelej-organov-opeki-i-popechitelstva-na-vystavke-rossija/"
              element={<News241123 />}
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route path="/reports-list/2024" element={<AllReportsPage />} />
            <Route path="/reports-list/2024/1" element={<Report2024 />} />
            <Route path="/reports-list/2024/2" element={<Report2024_2 />} />
            <Route path="/reports-list/2024/3" element={<Report2024_3 />} />
            <Route path="/reports-list/2024/4" element={<Report2024_4 />} />
            <Route path="/reports-list/2024/5" element={<Report2024_5 />} />
            <Route path="/reports-list/2024/6" element={<Report2024_6 />} />
            <Route path="/reports-list/2024/7" element={<Report2024_7 />} />
            <Route
              path="/reports-list/2023"
              element={
                <RequireAuth>
                  <Report2023 />
                </RequireAuth>
              }
            />
            <Route
              path="/reports-list/2022"
              element={
                <RequireAuth>
                  <Report2022 />
                </RequireAuth>
              }
            />
            <Route
              path="/reports-list/otchety-2021"
              element={
                <RequireAuth>
                  <Report2021 />
                </RequireAuth>
              }
            />
            <Route
              path="/reports-list/otchety-2020"
              element={
                <RequireAuth>
                  <Report2020 />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <RequireNotAuth>
                  <Login />
                </RequireNotAuth>
              }
            />
            <Route path="/add-form" element={<AddFormPage />} />
            <Route
              path="/viktorina-po-finansovoj-gramotnosti"
              element={<Concurs />}
            />
            <Route path="/support_form" element={<SupportForm />} />
            <Route
              path="/startuet-vserossijskij-konkurs-v-sfere-professionalnoj-dejatelnosti-specialistov-organov-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih-grazhdan-na-storone-rebenka/"
              element={<News300124 />}
            />
            <Route
              path="/28-29-marta-2024-g-v-moskve-proshel-xii-vserossijskij-sezd-rukovoditelej-organizacij-dlja-detej-sirot-i-detej-ostavshihsja-bez-popechenija-roditelej/"
              element={<News020424 />}
            />
            <Route
              path="/objavleny-finalisty-vserossijskogo-konkursa-v-sfere-professionalnoj-dejatelnosti-specialistov-organov-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih-grazhdan-na-storone-rebenka/"
              element={<News270624 />}
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </>
  );
}

function RequireAuth({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      if (localStorage.getItem("access")) {
        dispatch({ type: LOGIN_SUCCESS });
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    checkAuth();
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated === false) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function RequireNotAuth({ children }) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      if (localStorage.getItem("access")) {
        dispatch({ type: LOGIN_SUCCESS });
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    checkAuth();
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>; // или можно вернуть спиннер или другой компонент-загрузчик
  }

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

const OpecaApp = (props) => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  );
};

export default OpecaApp;
