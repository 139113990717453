import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs17.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs17 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Мастер-класс на тему: Трудное поведение у подростков возможна ли помощь в условиях организации для детей сирот
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <YouTube videoId="VYhcfmPyNd0" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs17
