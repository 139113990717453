import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs26.jpg";
import { WebShareBlock } from "./Webs1";

const Webs26 = () => {
  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Вебинар по вопросам, связанным с организацией и
        осуществлением совместного нахождения с ребенком из организации для
        детей-сирот и детей, оставшихся без попечения родителей, при оказании
        медицинской помощи в стационарных условиях (28 февраля 2022 г., первый
        день)
      </h1>
      <div className={s.date}>28.02.2022</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div style={{ paddingTop: "15px" }}>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "20px",
            }}
          >
            Презентации участников
          </div>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/web26_1.pdf"
            title="Лашкул М.В."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/uvarova-l.b._0XQfMTU.pdf"
            title="Уварова Л.Б."
          />
          <p></p>
          <FileUrl
            url="https://frcopeca.ru/media/media/behavior/chirkova-e.n._p2hzPrj.pdf"
            title="Чиркова Е.Н."
          />
        </div>
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs26;

export const FileUrl = ({ title, url }) => {
  return (
    <div className={s.fileBlock}>
      <a href={url}>{title}</a>
    </div>
  );
};
