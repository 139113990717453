import s from "./NewsPages.module.css"
import img from "../../assets/News/News040723.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News040723 = () => {

    return (
        <div className={s.main}>
            <h1>
                6-7 июля 2023 г. состоится XI Всероссийский Съезд руководителей организаций для детей-сирот и детей,
                оставшихся без попечения родителей
            </h1>
            <div className={s.date}>
                4 июля 2023
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    Минпросвещения России 6-7 июля 2023 г. в Уфе проводит XI Всероссийский Съезд руководителей
                    организаций для детей-сирот и детей, оставшихся без попечения родителей.
                    <p></p>
                    <i>Место проведения Съезда «Конгресс-холл Торатау» (ул. Заки Валиди, д. 2).</i>
                    <p></p>
                    Ключевой темой проводимых Минпросвещения России в 2023 г. мероприятий, в Год педагога и наставника,
                    является роль неравнодушных граждан, принимающих непосредственное участие в формировании личности
                    ребенка, в его воспитании и образовании. С учетом миссии Года педагога и наставника также
                    сформирована программа Съезда.
                    <p></p>
                    На Съезде состоится обмен опытом и взаимодействие руководителей и специалистов организаций для
                    детей-сирот по актуальным вопросам, касающимся деятельности организаций для детей-сирот, в том числе
                    по вопросам наставничества, подготовки и сопровождения выпускников организаций для детей-сирот.
                    <p></p>
                    К участию в Съезде в очном формате приглашены более 250 делегатов из числа руководителей и
                    специалистов организаций для детей-сирот.
                    <p></p>
                    <a href="https://disk.yandex.ru/d/q72ku2Jg7tKkEA">По ссылке на Яндекс диске</a> можно будет
                    познакомиться с программой Съезда, сборником информационных и
                    методических материалов по вопросам опеки и попечительства в отношении несовершеннолетних граждан, а
                    также со списком организаций для детей-сирот, в которые будет организовано посещение делегатов
                    Съезда (по предварительной записи при регистрации делегатов Съезда 6 июля 2023 г.).
                    <p></p>
                    Пленарное заседание Съезда состоится в режиме онлайн-трансляции. Ссылка для подключения на пленарное
                    заседание Съезда 6 июля 2023 г. в 09.00 по московскому времени:
                    <a href="https://vk.com/video/@mintrudrb?z=video-44065111_456239701%2Fclub44065111%2Fpl_-44065111_-2">https://vk.com/video/@mintrudrb?z=video-44065111_456239701%2Fclub44065111%2Fpl_-44065111_-2</a>.
                    <p></p>
                    В рамках Съезда предусмотрено проведение практико-ориентированных вебинаров в онлайн-режиме, которые
                    состоятся 7 июля 2023 г. в 13.00 по московскому времени на цифровой платформе Webinar.
                    <p></p>
                    Для участия в вебинарах приглашаются представители органов исполнительной власти субъектов
                    Российской Федерации, осуществляющих государственное управление в сфере опеки и попечительства в
                    отношении несовершеннолетних граждан, руководители и специалисты организаций
                    для детей-сирот и специалисты органов опеки и попечительства.
                    <p></p>
                    Регистрация участников доступна по ссылкам:
                    <p></p>
                    <a href="https://events.webinar.ru/39914579/1579464940"><strong>Вебинар 1.</strong> «Учебное проживание подростков и молодых людей
                        с ментальными нарушениями»;</a>
                    <p></p>
                    <a href="https://events.webinar.ru/39914579/226455028"><strong>Вебинар 2.</strong> «Как подготовить детей с расстройствами аутистического спектра и другими ментальными
                        особенностями к выездным социокультурным мероприятиям».</a>
                    <p></p>
                    Делегаты Съезда могут получить консультацию по организационным вопросам Съезда с помощью чат-бота в
                    Telegram <a href="https://t.me/CongressOrphansBot">@CongressOrphansBot</a>
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News040723
