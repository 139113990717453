import s from "./Cabinet.module.css"

const Report2023 = () => {
    return (
        <div className={s.main}>
            <h1>Отчет за 2023 год</h1>

        </div>
    )
}
export default Report2023