import s from "./NewsPages.module.css"
import img from "../../assets/News/News300124.jpg"
import { AdviceBlock, ShareBlock } from "./News170621";

const News300124 = () => {

    return (
        <div className={s.main}>
            <h1>
                Стартует Всероссийский конкурс в сфере профессиональной деятельности специалистов органов опеки и
                попечительства в отношении несовершеннолетних граждан «На стороне ребенка»
            </h1>
            <div className={s.date}>
                30 января 2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt="" />
                </div>
                <div>
                    К участию в Конкурсе приглашаются специалисты и руководители территориальных и муниципальных органов опеки
                    и попечительства, специалисты исполнительных органов субъектов Российской Федерации, занимающихся
                    формированием и ведением регионального банка данных о детях, оставшихся без попечения родителей, для
                    индивидуального участия, территориальные и муниципальные органы опеки и попечительства.
                    <p></p>
                    Подробная информация о Конкурсе размещена на сайте <a href='https://на-стороне-ребенка.рф'>https://на-стороне-ребенка.рф</a>
                    <p></p>
                    Регистрация участников Конкурса будет осуществляться с 1 февраля (с 12.00 по московскому времени)
                    по 26 февраля 2024 г. (до 18.00 по московскому времени) на сайте Конкурса.
                    <p></p>
                    2 февраля 2024 г. в 10:00 по московскому времени состоится вебинар по вопросам проведения Конкурса.
                    Предварительная регистрация участников на вебинар доступна по ссылке:
                    <a href='https://my.mts-link.ru/39914579/962049196'>https://my.mts-link.ru/39914579/962049196</a>

                </div>
                <ShareBlock />
            </div>
            <AdviceBlock />
        </div>
    )
}
export default News300124;
