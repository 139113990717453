import { newsExamples } from "../Pages/Main";
import s from "./NewsPages.module.css";
import img from "../../assets/News/News170621.jpg";
import vk from "../../assets/News/vk_icon.PNG";
import ok from "../../assets/News/ok_icon.PNG";
import mail from "../../assets/News/mail_icon.PNG";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { NewsSquare } from "../Pages/News";
import { useEffect, useState } from "react";
import axios from "axios";

const News170621 = () => {
  return (
    <div className={s.main}>
      <h1>
        Методические материалы «Конструктор типовой программы сопровождения
        детей-сирот и детей, оставшихся без попечения родителей, лиц из числа
        детей-сирот и детей, оставшихся без попечения родителей»
      </h1>
      <div className={s.date}>17 июня 2021</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <div>
          Методические материалы «Конструктор типовой программы сопровождения
          детей-сирот и детей, оставшихся без попечения родителей, лиц из числа
          детей-сирот и детей, оставшихся без попечения родителей» разработаны с
          целью оказания методической поддержки специалистам осуществляющим
          сопровождение детей-сирот и детей, оставшихся без попечения родителей,
          лиц из числа детей-сирот и детей, оставшихся без попечения родителей,
          а также специалистам органов опеки и попечительства.
          <p></p>
          Материалы включают два раздела. В первом разделе сопровождение
          рассматривается на уровне социальной ситуации выпускника, как
          технология индивидуального сопровождения.
          <p></p>
          Во втором разделе методических материалов сопровождение выпускников
          рассматривается на уровне субъекта Российской Федерации: развитие
          региональной системы сопровождения на основе единого
          организационно-правового поля.
          <p></p>
          Методические материалы разработаны ФГБУ «Центр защиты прав и интересов
          детей» с учетом практического опыта сопровождения выпускников
          организаций для детей-сирот и замещающих семей.
          <p></p>
          «Конструктор типовой программы сопровождения детей-сирот и детей,
          оставшихся без попечения родителей, лиц из числа детей-сирот и детей,
          оставшихся без попечения родителей» доступен к ознакомлению в разделе{" "}
          <a href="/methodical-materials">Методические материалы</a>.
        </div>
        <ShareBlock />
      </div>
      <AdviceBlock />
    </div>
  );
};
export default News170621;

export const ShareBlock = () => {
  const currentLocation = window.location;
  return (
    <div className={s.shareBlock}>
      <span className={s.btn}>
        <a href="/category/novosti">
          <ArrowLeftOutlined className={s.arrow} />
          Назад к списку новостей
        </a>
      </span>
      <span className={s.share}>
        <span>Поделитесь с коллегами и друзьями:</span>
        <span className={s.icons}>
          <span className={s.item} style={{ backgroundColor: "#5d90ca" }}>
            <a
              href={"https://vk.com/share.php?url=" + currentLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img src={vk} alt="ВК" />
            </a>
          </span>
          <span className={s.item} style={{ backgroundColor: "#eb722e" }}>
            <a
              href={"https://connect.ok.ru/offer?url=" + currentLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img src={ok} alt="OK" />
            </a>
          </span>
          <span className={s.item} style={{ backgroundColor: "#168de2" }}>
            <a
              href={"https://connect.mail.ru/share?url=" + currentLocation}
              target="_blank"
              rel="noreferrer"
            >
              <img src={mail} alt="Мой мир" />
            </a>
          </span>
        </span>
      </span>
    </div>
  );
};

export const AdviceBlock = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/news").then((res) => {
      setData(res);
    });
  }, []);

  let newsArray = respData ? [].concat(respData.data).reverse() : [];
  return (
    <div className={s.advice}>
      <div className={s.sovetuem}>Советуем почитать</div>
      <div className={s.adviceList}>
        {newsArray.slice(0, 3).map((el) => (
          <NewsSquare
            img={el.img}
            title={el.title}
            description={el.description}
            date={el.date}
            refs={el.ref}
          />
        ))}
      </div>
    </div>
  );
};

export const NewsImage = ({ img }) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className={active ? s.anotherBigImg : s.anotherImg}
      onClick={() => {
        active ? setActive(false) : setActive(true);
      }}
    >
      <img src={img} alt="" />
    </div>
  );
};
