import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const SupportForm = () => {
    return (
        <div className={s.main}>
            <h1>Обращение в поддержку</h1>
            <ReportForm/>
        </div>
    );
};
export default SupportForm;

const ReportForm = () => {
    return (
        <div>

            <Report1/>

        </div>
    );
};

const Report1 = ({respData}) => {
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState(false);
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                email: "",
                name: "",
                description: "",
            }}
            onSubmit={(values, {setSubmitting}) => {
                let data1 = new FormData();
                data1.append("email", values.email);
                data1.append("name", values.name);
                data1.append("description", values.description);

                let config = {
                    method: "POST",
                    maxBodyLength: Infinity,

                    url: "https://frcopeca.ru/api/form",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data: data1,
                };
                axios.request(config).then(()=> {
                    setMessage("Отправка...")
                }).then((response) => {
                    if (response === undefined) {
                        setDisabled(true)
                        setMessage("Ваше сообщение отправлено поддержке!")
                    }
                }).catch((e)=> {
                    setMessage("Ошибка! Проверьте свои данные или попробуйте позже.")
                })
            }}
        >
            {({errors, touched, values, isSubmitting}) => (
                <Form className={s.reportForm}>
                    <label htmlFor="email">Введите почту для обратной связи</label>
                    <Field
                        as="textarea"
                        required={true}
                        name="email"
                        placeholder="Введите e-mail"
                    />
                    {errors.email && touched.email ? (
                        <div className={s.wrong}>{errors.email}</div>
                    ) : null}
                    <label as="textarea" htmlFor="name">
                        Введите наименование организации
                    </label>
                    <Field
                        required={true}
                        as="textarea"
                        name="name"
                        placeholder="Введите наименование"
                    />
                    {errors.name && touched.name ? (
                        <div className={s.wrong}>{errors.name}</div>
                    ) : null}
                    <label htmlFor="description">Опишите свою проблему</label>
                    <Field
                        as="textarea"
                        required={true}
                        name="description"
                        placeholder="Введите текст"
                    />
                    {errors.description && touched.description ? (
                        <div className={s.wrong}>{errors.description}</div>
                    ) : null}

                    <button disabled={disabled} type="submit">Отправить</button>
                    <div className={s.message}>
                        {message}
                    </div>
                </Form>
            )}
        </Formik>
    );
};
