import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper/modules";
import React from "react";

import "./TopSlider.css";
import {ArrowRightOutlined} from "@ant-design/icons";

export const TopSlider = ({slides}) => {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            cssMode={true}
            slidesPerView={1}
            spaceBetween={0}
            navigation
        >
            <SwiperSlide key="1">
                <div className="firstSlide">
                    <div className="sliderFilter">
                        <div className="blog-slider__content">
                            <div className="blog-slider__title" style={window.innerWidth < 750 ? {fontSize: "35px"} : {}}>Федеральный ресурсный центр в сфере защиты прав
                                детей-сирот и детей, оставшихся без попечения родителей
                            </div>
                            <div className="blog-slider__text">ФРЦ в сфере защиты прав детей, оставшихся без попечения
                                родителей является структурным подразделением федерального государственного бюджетного
                                учреждения «Центр защиты прав и интересов детей» Министерства просвещения Российской
                                Федерации
                            </div>
                            <div className="blog-slider__button"><a href="/about">Подробнее о центре<ArrowRightOutlined
                                    style={{paddingLeft: "5px"}}/></a></div>
                        </div>
                    </div>

                </div>
            </SwiperSlide>


        </Swiper>
    )
}