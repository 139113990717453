import s from "./NewsPages.module.css"
import img from "../../assets/News/News020424.jpg"
import img2 from "../../assets/News/News020424-2.jpg"
import { AdviceBlock, ShareBlock } from "./News170621";

const News020424 = () => {

    return (
        <div className={s.main}>
            <h1>
                28-29 марта 2024 г. в Москве прошел XII Всероссийский Съезд руководителей организаций для детей-сирот
                и детей, оставшихся без попечения родителей
            </h1>
            <div className={s.date}>
                02 апреля 2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt="" />
                </div>
                <div>
                    28-29 марта 2024 г. Минпросвещения России проведен XII Всероссийский Съезд руководителей организаций
                    для детей-сирот и детей, оставшихся без попечения родителей.
                    <p></p>
                    Мероприятие прошло на площадке Международной выставки-форума «Россия», в главном лектории
                    для просветительских мероприятий Российского общества «Знание» — генерального партнера выставки-форума.
                    <p></p>
                    В Съезде приняли участие более 300 человек, включая в том числе делегатов из 82 субъектов Российской
                    Федерации (руководители и специалисты организаций для детей-сирот, представители
                    исполнительных органов субъектов Российской Федерации, осуществляющих государственное управление в сфере опеки
                    и попечительства в отношении несовершеннолетних граждан), представителей федеральных органов
                    исполнительной власти, высшей школы, Всероссийской общественной организации «Содружество выпускников
                    детских домов «Дети всей страны».
                    <p></p>
                    В Год семьи ключевой темой съезда стала популяризация государственной политики в сфере защиты семьи,
                    создание условий для восстановления родительских прав, сохранение родственных и дружеских связей
                    ребенка, находящегося в организации для детей-сирот.
                    <p></p>
                    В ходе работы Съезда обсуждены вопросы, касающиеся перспектив развития организаций для детей-сирот,
                    различных этапов нахождения детей в этих организациях, развития творческих способностей воспитанников,
                    организации воспитательной работы.
                    <p></p>
                    <a href="https://disk.yandex.ru/d/GG2_R5csNAOdkA">Материалы XII Всероссийского Съезда руководителей
                    организаций для детей-сирот и детей, оставшихся без попечения родителей.</a>
                    <p></p>
                    <div className={s.newsImg}>
                        <img src={img2} alt="" />
                    </div>
                    В рамках Съезда проведено торжественное открытие Всероссийского конкурса в сфере профессиональной
                    деятельности специалистов органов опеки и попечительства в отношении несовершеннолетних граждан
                    «На стороне ребенка».
                    <p></p>
                    Его организация предусмотрена Комплексом мер по подготовке и повышению квалификации специалистов
                    органов опеки и попечительства в отношении несовершеннолетних граждан на 2021-2024 годы,
                    утвержденным Заместителем Председателя Правительства Российской Федерации Т.А. Голиковой.
                    Впервые на федеральном уровне конкурс среди специалистов органов опеки и попечительства был
                    проведен в 2022 году.
                    <p></p>
                    На Съезде присутствовали члены экспертной комиссии конкурса. Галина Владимировна Семья, доктор
                    психологических наук, профессор Московского государственного психолого-педагогический университета
                    и Алла Зауровна Дзугаева, директор ГБУ города Москвы Ресурсный центр по вопросам опеки и
                    попечительства «Содействие» Департамента труда и социальной защиты населения города Москвы
                    рассказали об опыте проведения первого конкурса и ожиданиях конкурса 2024 года, который стартовал
                    в феврале текущего года
                    <p></p>
                </div>
                <ShareBlock />
            </div>
            <AdviceBlock />
        </div>
    )
}
export default News020424;
