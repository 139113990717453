import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs12.jpg"
import {WebShareBlock} from "./Webs1";


const Webs12 = () => {

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Переход к единой ведомственной подчиненности организаций для детей сирот и детей оставшихся без попечения родителей
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs12
