import s from "./Cabinet.module.css";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";

const AddFormPage = () => {
  return (
    <div className={s.main}>
      <h1>Создать новую форму опроса</h1>
      <ReportForm />
    </div>
  );
};
export default AddFormPage;

const ReportForm = () => {
  return (
    <div className={s.formDetails}>
      <Report1 />
    </div>
  );
};

const generateJSX = (data) => {
  // Сортируем данные по полю `number`
  const sortedData = data.sort((a, b) => a.number - b.number);

  return sortedData
    .map((item) => {
      if (item.type === "select") {
        return `<div key={${item.id}}>
                    <label htmlFor="${item.short}">${item.name}</label>
                    <Field as="select" id="${item.short}" name="${
          item.short
        }" ${item.required ? "required={true}" : ""}>
                        ${item.options
                          .map(
                            (opt) => `<option value="${opt}">${opt}</option>`
                          )
                          .join("\n")}
                    </Field>
                </div>`;
      } else if (item.type === "labelOnly") {
        return `<div key={${item.id}}>
                    <label>${item.name}</label>
                </div>`;
      }
      return `<div key={${item.id}}>
                <label htmlFor="${item.short}">${item.name}</label>
                <Field id="${item.short}" name="${item.short}" type="${
        item.type
      }" ${item.required ? "required={true}" : ""} />
            </div>`;
    })
    .join("\n");
};

const generateModelFields = (data) => {
  return data
    .map((item) => {
      switch (item.type) {
        case "text":
          return `    p${item.number} = models.CharField(max_length=255, verbose_name="${item.name}")\n`;
        case "number":
          return `    p${item.number} = models.IntegerField(verbose_name="${item.name})\n`;
        case "select":
          return `    ${
            item.number
          } = models.CharField(max_length=255, choices=[${item.options
            .map((opt) => `('${opt}', '${opt}')`)
            .join(", ")}])\n`;
        default:
          return "";
      }
    })
    .join("");
};

const generateSerializerFields = (data) => {
  return data
    .map((item) => `    ${item.name} = serializers.CharField()\n`)
    .join("");
};

const Report1 = () => {
  const [formData, setFormData] = useState([
    {
      id: 1,
      name: "quest1",
      question: "",
      type: "",
      options: [],
      required: false,
      number: 1,
    },
  ]);

  function refreshInfo() {
    let newId = formData[formData.length - 1].id + 1;
    let newEl = {
      id: newId,
      name: "quest" + newId,
      question: "",
      type: "",
      options: [],
      required: false,
      number: newId,
    };

    setFormData((formData) => [...formData, newEl]);
    console.log(formData);
  }

  function deleteField() {
    let form = [...formData];
    if (form.length > 1) {
      form.pop();
    }
    setFormData(form);
  }

  function handleCheckboxChange(index, value) {
    const newFormData = [...formData];
    newFormData[index].required = value;
    setFormData(newFormData);
  }
  const [previewData, setPreviewData] = useState(null);
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          quest1: "",
        }}
        onSubmit={(values) => {
          let data = [];
          for (let i = 1; i < formData.length + 1; i++) {
            let name1 = "quest" + i;
            let newEl = {
              id: i,
              name: values[name1],
              short: values[name1 + " short"],
              type: values[name1 + " type"],
              comm: values[name1 + " comm"],
              options:
                values[name1 + " type"] === "select"
                  ? values[name1 + " options"].split(";")
                  : [],
              required: formData[i - 1].required,
              number: values[name1 + " number"]
                ? parseInt(values[name1 + " number"], 10)
                : i,
            };
            data = [...data, newEl];
            setPreviewData(data);
          }
          const jsxCode = `
import s from "./Cabinet.module.css";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import React from "react";
import {CloseOutlined} from "@ant-design/icons";

const MyForm = () => (
  <Formik
    initialValues={{ ${data
      .map((item) => (item.type !== "labelOnly" ? `${item.name}: ''` : ""))
      .filter(Boolean)
      .join(", ")} }}
    onSubmit={values => {
      console.log(values);
    }}
  >
    {() => (
      <Form>
        ${generateJSX(data)}
        <button type="submit">Submit</button>
      </Form>
    )}
  </Formik>
);

export default MyForm;
`;

          const modelCode = `
from django.db import models

class MyModel(models.Model):
регион = models.CharField(verbose_name="регион", max_length=100, blank=True)
${generateModelFields(data)}
created_at = models.DateTimeField(auto_now_add=True)
updated_at = models.DateTimeField(auto_now=True)
file = models.FileField(upload_to='exports/', blank=True, null=True)  # Поле для хранения XLSX файлов

def __str__(self):
    return self.регион
`;

          const serializerCode = `
from rest_framework import serializers
from .models import MyModel

class MyModelSerializer(serializers.ModelSerializer):
${generateSerializerFields(data)}
class Meta:
    model = MyModel
    fields = '__all__'
`;

          const adminCode = `
from django.contrib import admin
from .models import MyModel

@admin.register(MyModel)
class MyModelAdmin(admin.ModelAdmin):
list_display = [${data.map((item) => `'${item.name}'`).join(", ")}]
search_fields = [${data.map((item) => `'${item.name}'`).join(", ")}]
`;

          const blobJSX = new Blob([jsxCode], { type: "text/plain" });
          const blobPY = new Blob(
            [modelCode, "\n\n", serializerCode, "\n\n", adminCode],
            { type: "text/plain" }
          );

          // Создание ссылки для скачивания
          const linkJSX = document.createElement("a");
          linkJSX.href = URL.createObjectURL(blobJSX);
          linkJSX.download = "MyForm.jsx";
          document.body.appendChild(linkJSX);

          const linkPY = document.createElement("a");
          linkPY.href = URL.createObjectURL(blobPY);
          linkPY.download = "django_files.py";
          document.body.appendChild(linkPY);

          // Программа скачивания
          linkJSX.click();
          linkPY.click();

          // Удаление ссылки после скачивания
          document.body.removeChild(linkJSX);
          document.body.removeChild(linkPY);

          const formsObject = data.reduce((acc, form, index) => {
            acc[`form${index + 1}`] = JSON.stringify(form);
            return acc;
          }, {});
          formsObject.page_name = values.page;

          const formData1 = new FormData();
          for (const key in formsObject) {
            if (formsObject.hasOwnProperty(key)) {
              formData1.append(key, formsObject[key]);
            }
          }
          let headers = new Headers();
          let requestOptions = {
            method: "POST",
            headers: headers,
            body: formData1,
            redirect: "follow",
            withCredentials: true,
          };
          fetch("https://frcopeca.ru/api/template", requestOptions).then(
            (response) => console.log(response)
          );
        }}
      >
        {({ setFieldValue, errors, values, touched, isSubmitting }) => (
          <Form className={s.reportForm}>
            <label htmlFor="page">Название новой формы опроса</label>
            <Field
              required={true}
              name="page"
              placeholder="Новое название..."
            />
            <label style={{ paddingTop: "20px" }} htmlFor="page">
              Краткое название страницы на английском
            </label>
            <Field
              required={true}
              name="en_page"
              placeholder="Краткое название"
            />
            <div style={{ marginTop: "20px" }}></div>
            {formData.map((el, index) => (
              <div className={s.addFormField} key={el.id}>
                <label htmlFor={el.name}>Введите поле {el.id}</label>
                <div className={s.addFormInfo}>
                  <Field
                    required={true}
                    name={el.name}
                    placeholder={"Введите поле " + el.id}
                  />
                  <Field
                    id="short"
                    required={true}
                    name={el.name + " short"}
                    placeholder="Краткое название поля"
                  />
                  <Field
                    id="number"
                    name={el.name + " number"}
                    placeholder="Номер вопроса"
                  />
                  <Field
                    component="select"
                    required={true}
                    name={el.name + " type"}
                    onChange={(e) => {
                      setFieldValue(el.name + " type", e.target.value);
                      if (
                        e.target.value !== "select" &&
                        e.target.value !== "labelOnly"
                      ) {
                        setFieldValue(el.name + " options", "");
                      }
                    }}
                  >
                    <option selected disabled value="">
                      Выберите
                    </option>
                    <option value="text">Текст</option>
                    <option value="number">Числовой</option>
                    <option value="file">Файл</option>
                    <option value="select">Выбор</option>
                    <option value="labelOnly">Вопрос без ответа</option>
                  </Field>
                  {values[el.name + " type"] === "select" && (
                    <Field
                      id="options"
                      required={true}
                      name={el.name + " options"}
                      placeholder="Опции, через ';' без пробелов"
                    />
                  )}
                  <Field
                    id="comm"
                    name={el.name + " comm"}
                    placeholder="Валидация для поля"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      deleteField();
                      setFieldValue(el.name, "");
                      setFieldValue(el.name + " short", "");
                      setFieldValue(el.name + " type", "");
                      setFieldValue(el.name + " comm", "");
                      setFieldValue(el.name + " options", "");
                    }}
                  >
                    <CloseOutlined />
                  </button>
                  <div className={s.check}>
                    <label htmlFor="scales">Обязательное поле?</label>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(index, e.target.checked)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}

            <button type="button" name="add" onClick={refreshInfo}>
              Добавить поле
            </button>

            <button type="submit">Сохранить</button>
          </Form>
        )}
      </Formik>
      <div>
        {previewData && (
          <div className={s.reportForm}>
            <h2>Предварительный просмотр</h2>
            <ul>
              {previewData.map((item) => {
                {
                  if (item.type === "select") {
                    return (
                      <div key={item.id}>
                        <label>{item.name}</label>
                        <select id={item.short} name={item.short}>
                          {item.options.map((opt) => (
                            <option value={opt}>{opt}</option>
                          ))}
                        </select>
                      </div>
                    );
                  } else if (item.type === "labelOnly") {
                    return (
                      <div key={item.id}>
                        <label>{item.name}</label>
                      </div>
                    );
                  }
                  return (
                    <div key={item.id}>
                      <label htmlFor={item.short}>{item.name}</label>
                      <input
                        id={item.short}
                        name={item.short}
                        type={item.type}
                      />
                    </div>
                  );
                }
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const View = ({ data }) => {
  const sortedData = data.sort((a, b) => a.number - b.number);
  console.log(data, "fa");
  return sortedData
    .map((item) => {
      if (item.type === "select") {
        return `<div key={${item.id}}>
                    <label htmlFor="${item.short}">${item.name}</label>
                    <Field as="select" id="${item.short}" name="${
          item.short
        }" ${item.required ? "required={true}" : ""}>
                        ${item.options
                          .map(
                            (opt) => `<option value="${opt}">${opt}</option>`
                          )
                          .join("\n")}
                    </Field>
                </div>`;
      } else if (item.type === "labelOnly") {
        return `<div key={${item.id}}>
                    <label>${item.name}</label>
                </div>`;
      }
      return `<div key={${item.id}}>
                <label htmlFor="${item.short}">${item.name}</label>
                <Field id="${item.short}" name="${item.short}" type="${
        item.type
      }" ${item.required ? "required={true}" : ""} />
            </div>`;
    })
    .join("\n");
};
