import s from "./Files.module.css";
import { FileRef, hrefArray, InfoPanel } from "./FZ";
import { useEffect, useState } from "react";
import axios from "axios";

const Ukazi = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios.get("https://frcopeca.ru/api/NPD").then((res) => {
      setData(res);
    });
  }, []);
  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <div className={s.leftSide}>
          <h2>Указы Президента Российской Федерации</h2>
          <FileRef
            text={respData ? respData.data[57].description : ""}
            url={respData ? respData.data[57].file : ""}
          />
          <FileRef
            text={respData ? respData.data[58].description : ""}
            url={respData ? respData.data[58].file : ""}
          />
          <FileRef
            text={respData ? respData.data[59].description : ""}
            url={respData ? respData.data[59].file : ""}
          />
          <FileRef
            text={respData ? respData.data[60].description : ""}
            url={respData ? respData.data[60].file : ""}
          />
          <FileRef
            text={respData ? respData.data[61].description : ""}
            url={respData ? respData.data[61].file : ""}
          />
        </div>
        <div className={s.rightSide}>
          <InfoPanel hrefArray={hrefArray} />
        </div>
      </div>
    </div>
  );
};
export default Ukazi;
