import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Webs18.jpg"
import YouTube from "react-youtube";
import {WebShareBlock} from "./Webs1";


const Webs18 = () => {

    const opts = {
        width: "100%",
        minHeight: "400"
    }

    return (
        <div className={s.main}>
            <h1>
                Вебинар на тему: Пленарное заседание малое
            </h1>
            <div className={s.date}>

            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div style={{paddingBottom: "15px"}}>
                    Ключевые темы:
                    <p></p>
                    1. Переход к единой модели подчиненности организаций для детей-сирот и детей, оставшихся без
                    попечения родителей, и органов опеки и попечительства в субъектах Российской Федерации: достигнутые
                    результаты и вопросы, требующие решения.
                    <p></p>
                    2. Организация воспитательной работы, подготовка и реализация воспитательных программ в организациях
                    для детей-сирот и детей, оставшихся без попечения родителей.
                    <p></p>
                    3. Достижение ключевых показателей комплекса мер по повышению эффективности функционирования
                    механизмов реализации, соблюдения и защиты прав и законных интересов детей, проживающих в детских
                    домах-интернатах, а также детей, помещенных под надзор в организации для детей-сирот и детей,
                    оставшихся без попечения родителей, в целях качественного улучшения их жизни.
                </div>
                <YouTube videoId="9OyiKHFfcDo" opts={opts}/>
                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs18
