import s from "./NewsPages.module.css"
import img from "../../assets/News/News131222.jpg"
import img2 from "../../assets/News/News131222_2.jpg"
import img3 from "../../assets/News/News131222_3.jpg"
import img4 from "../../assets/News/News131222_4.jpg"
import img5 from "../../assets/News/News131222_5.jpg"
import img6 from "../../assets/News/News131222_6.jpg"
import {AdviceBlock, NewsImage, ShareBlock} from "./News170621";

const News131222 = () => {

    return (
        <div className={s.main}>
            <h1>
                Всероссийское совещание руководителей органов опеки и попечительства. Награждение победителей
                Всероссийского конкурса в сфере профессиональной деятельности специалистов органов опеки и
                попечительства в отношении несовершеннолетних граждан «На стороне ребенка»
            </h1>
            <div className={s.date}>
                13 декабря 2022
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    <strong>8-9 декабря 2022 г.</strong> Министерством просвещения Российской Федерации проведено
                    Всероссийское совещание
                    руководителей органов опеки и попечительства.
                    <p></p>
                    Цель мероприятия — определение приоритетных направлений государственной политики и нормативного
                    правового регулирования в сфере опеки и попечительства в отношении несовершеннолетних граждан, а
                    также методическая поддержка органов опеки и попечительства.
                    <p></p>
                    В совещании приняли участие представители Уполномоченного при Президенте Российской Федерации по
                    правам ребенка, Министерства труда Российской Федерации, Министерства строительства Российской
                    Федерации, органов исполнительной власти субъектов Российской Федерации, осуществляющих
                    государственное управление в сфере опеки и попечительства в отношении несовершеннолетних граждан,
                    негосударственных некоммерческих организаций.
                    <p></p>
                    С приветствием к участникам Всероссийского совещания обратился <strong>В.В. Страхов</strong>,
                    проректор по развитию
                    Московского педагогического государственного университета, в стенах которого проходило мероприятие.
                    <p></p>
                    В ходе Всероссийского совещания на дискуссионных площадках обсуждались вопросы, касающиеся
                    совершенствования законодательства в части семейного устройства детей-сирот и детей, оставшихся без
                    попечения родителей, реализации жилищных прав детей-сирот и детей, оставшихся без попечения
                    родителей, лиц из их числа, профессионального развития специалистов органов опеки и попечительства,
                    отобрания ребенка при непосредственной угрозе его жизни или здоровья, организации системной
                    медиативной работы по урегулированию семейных споров.
                    <p></p>
                    В своем выступлении на панельной дискуссии <strong>Л.П. Фальковская</strong>, директор Департамента
                    государственной
                    политики в сфере защиты прав детей Министерства просвещения Российской Федерации, акцентировала
                    внимание на основных этапах концепции совершенствования деятельности органов опеки и попечительства
                    в отношении несовершеннолетних граждан на период до 2030 года, обозначила основные задачи на
                    очередной и последующие годы.
                </div>
                <NewsImage img={img2}/>
                <NewsImage img={img3}/>
                <div>
                    9 декабря 2022 г. в рамках проведения Всероссийского совещания состоялась торжественная церемония
                    закрытия Всероссийского конкурса в сфере профессиональной деятельности специалистов органов опеки и
                    попечительства в отношении несовершеннолетних граждан «На стороне ребенка» (далее – Конкурс) и
                    награждение победителей.
                    <p></p>
                    На церемонии закрытия Конкурса присутствовали почетные гости, члены организационного комитета,
                    экспертной комиссии и партнеры Конкурса.
                </div>
                <NewsImage img={img4}/>
                <div>
                    Также состоялось вручение наград специалистам, принимавших непосредственное участие в деятельности
                    по защите прав и законных интересов несовершеннолетних, прибывших в экстренном массовом порядке из
                    Донецкой Народной Республики, Луганской Народной Республики.
                    <p></p>
                    Награды вручали Первый заместитель Министра просвещения Российской Федерации А.В. Бугаев и директор
                    Департамента государственной политики в сфере защиты прав детей Минпросвещения России Л.П.
                    Фальковская. Специальный приз партнера Конкурса вручил председатель Общероссийской
                    общественно-государственной организации «Фонд защиты детей» <strong>Д.А. Лиханов</strong>.
                </div>
                <NewsImage img={img5}/>
                <NewsImage img={img6}/>
                <div>
                    <a href="https://disk.yandex.ru/d/Be_t2DtCKh3CUg">Материалы Всероссийского совещания руководителей
                        органов опеки и попечительства.</a>
                </div>
                <ShareBlock/>

            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News131222
