import s from "./NewsPages.module.css"
import img from "../../assets/News/News140721.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News140721 = () => {

    return (
        <div className={s.main}>
            <h1>
                Вебинар для специалистов органов опеки и попечительства по вопросам деятельности органов опеки и
                попечительства, связанной с рассмотрением споров о воспитании детей
            </h1>
            <div className={s.date}>
                14 июля 2021
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                    15 июля 2021 г. состоится вебинар для специалистов органов опеки и попечительства по вопросам
                    деятельности органов опеки и попечительства, связанной с рассмотрением споров о воспитании детей,
                    организованный АНО «Центр развития социальных проектов» по заказу ФГБУ «Центр защиты прав и
                    интересов детей» в соответствии с государственным заданием Минпросвещения России.
                    <br/>
                    Вебинар проводится с целью формирования у специалистов органов опеки и попечительства компетенций,
                    связанных с рассмотрением споров о воспитании детей.
                    <br/>
                    Для участия в вебинаре приглашаются специалисты органов опеки и попечительства, органов
                    исполнительной власти субъектов Российской Федерации в сфере опеки и попечительства в отношении
                    несовершеннолетних граждан.
                    <br/>
                    В рамках вебинара запланированы:
                    <br/>
                    — обсуждение и оценка текущей ситуации в области сопровождения специалистами органов опеки и
                    попечительства семейных споров о месте жительства ребенка и порядке общения с ребенком отдельно
                    проживающего родителя (близкого родственника), проблемы и возможности;
                    <br/>
                    — презентация для участников вебинара регионального опыта и практик;
                    <br/>
                    — презентация и экспертное обсуждение Методических рекомендаций для органов опеки и попечительства
                    по сопровождению семейных споров о месте жительства ребенка и порядке общения с ребенком отдельно
                    проживающего родителя (близкого родственника).
                    <br/>
                    Спикерами вебинара станут ведущие специалисты и эксперты в области защиты прав детей, разрешения
                    семейных споров и медиации.
                    <br/>
                    Вебинар состоится 15 июля 2021 г. в 9:00 по московскому времени на платформе Zoom.
                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News140721
