import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs32.jpg";
import YouTube from "react-youtube";
import { WebShareBlock } from "./Webs1";
import { FileUrl } from "./Webs26";

const Webs32 = () => {
  const opts = {
    width: "100%",
    minHeight: "400",
  };

  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Исполнение законодательства об опеке и попечительстве в
        отношении несовершеннолетних граждан
      </h1>
      <div className={s.date}>31.03.2023</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <YouTube videoId="WWVC-Isy2FA" opts={opts} />
        <FileUrl
          title="Презентация. Огурцова М.Л."
          url="https://frcopeca.ru/media/media/behavior/web32_1.pdf"
        />
        <p></p>
        <FileUrl
          title="Дополнительные ответы на вопросы участников вебинара Огурцовой. М."
          url="https://frcopeca.ru/media/media/behavior/web32_2.pdf"
        />
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs32;
